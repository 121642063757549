import React from "react";
import { Message, MessageProps } from "semantic-ui-react";
import { Response } from "../../models";

type Props = MessageProps & {
  response: Response<any>;
  closeToast?: () => void;
};

export default function ResponseMessage(props: Props) {
  return (
    <div>
      <Message list={props.response.errors.map(x => x.errorMessage)} />
    </div>
  );
}

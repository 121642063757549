import { SiteDetailsGetDto, UserGetDto } from "../../models";
import { Response } from "../../models";

import { Api } from "../../util/api/api";
import { toast } from "react-toastify";
import { getUserDisplayName } from "../me/helpers";

export const GET_SITE_DETAILS = "GET_SITE_DETAILS";

interface GetSiteDetails {
  type: typeof GET_SITE_DETAILS;
  siteDetails: SiteDetailsGetDto;
}

export const getSiteDetails = dispatch => {
  return async (api: Api, siteId: number) => {
    let apiResponse = (await api.get(
      `/sites/${siteId}`,
      null
    )) as Response<SiteDetailsGetDto>;

    if (!apiResponse.hasErrors) {
      dispatch({ type: GET_SITE_DETAILS, siteDetails: apiResponse.result });
    } else {
      return Promise.reject(apiResponse.errors.join(" "));
    }
  };
};

export const addSiteUser = dispatch => {
  return (api: Api, siteId: number, user: UserGetDto) => {
    return api
      .post(`/sites/${siteId}/user`, { userId: user.id, isAdmin: false })
      .then(async response => {
        if (!response.hasErrors) {
          await getSiteDetails(dispatch)(api, siteId);
          toast.info(`${getUserDisplayName(user)} has been added`);
        }
        return response;
      });
  };
};

export const removeSiteUser = dispatch => {
  return (api: Api, siteId: number, user: UserGetDto) => {
    return api
      .delete(`/sites/${siteId}/user/${user.id}`, null)
      .then(async response => {
        if (!response.hasErrors) {
          await getSiteDetails(dispatch)(api, siteId);
          toast.info(
            `${getUserDisplayName(
              user
            )} has been removed from the site. Any active plans have been copied to your plans list.`
          );
        }
        return response;
      });
  };
};

export const updateUserPreferredEmail = dispatch => {
  return (api: Api, siteId: number, user: UserGetDto, email: string) => {
    return api
      .patch(`/users/${user.id}/email`, {
        preferredEmail: email,
        siteId,
      })
      .then(async response => {
        if (!response.hasErrors) {
          await getSiteDetails(dispatch)(api, siteId);
          toast.info(`${user.name}'s email has been changed to ${email}`);
        }
        return response;
      });
  };
};

export const addTemplateToUser = dispatch => {
  return (api: Api, siteId: number, userId: number, templateId: number) => {
    return api
      .post(`/sites/${siteId}/templates/user`, {
        userId,
        factorTemplateId: templateId,
      })
      .then(async response => {
        if (!response.hasErrors) {
          await getSiteDetails(dispatch)(api, siteId);
        }
        return response;
      });
  };
};

export const deleteTemplateFromUser = dispatch => {
  return (api: Api, siteId: number, userId: number, templateId: number) => {
    return api
      .delete(`/sites/${siteId}/templates/${templateId}/user/${userId}`, null)
      .then(async response => {
        if (!response.hasErrors) {
          await getSiteDetails(dispatch)(api, siteId);
        }
        return response;
      });
  };
};

export type GetSiteDetailsDispatch = ReturnType<typeof getSiteDetails>;
export type AddSiteUserDispatch = ReturnType<typeof addSiteUser>;
export type RemoveSiteUserDispatch = ReturnType<typeof removeSiteUser>;

export type SiteAdminActions = GetSiteDetails;

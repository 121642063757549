import {
  FactorTemplate,
  Response,
  FactorTemplateWithRates,
  FactorTemplateResourceRateChange,
  ActivityNumberEntry,
} from "../../../models";
import { Api } from "../../../util/api/api";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../util";

export const GET_FACTOR_TEMPLATES = "GET_FACTOR_TEMPLATES";
export const ADD_FACTOR_TEMPLATE = "ADD_FACTOR_TEMPLATE";
export const UPDATE_FACTOR_TEMPLATE = "UPDATE_FACTOR_TEMPLATE";
export const SELECT_FACTOR_TEMPLATE = "SELECT_FACTOR_TEMPLATE";
export const DELETE_FACTOR_TEMPLATE = "DELETE_FACTOR_TEMPLATE";
export const UPDATE_FACTOR_RATE = "UDPATE_FACTOR_RATE";
export const SAVE_FACTOR_RATES = "SAVE_FACTOR_RATES";
export const SAVE_ACTNO_ENTRIES = "SAVE_ACTNO_ENTRIES";

export type State = {
  hasChanges: boolean;
  selectedTemplate?: FactorTemplateWithRates;
  factors: Array<FactorTemplateWithRates>;
};

interface GetFactorTemplates {
  type: typeof GET_FACTOR_TEMPLATES;
  payload: FactorTemplateWithRates[];
}

interface AddFactorTemplate {
  type: typeof ADD_FACTOR_TEMPLATE;
  payload: FactorTemplateWithRates;
}

interface UpdateFactorTemplate {
  type: typeof UPDATE_FACTOR_TEMPLATE;
  payload: FactorTemplateWithRates;
}

interface SelectFactorTemplate {
  type: typeof SELECT_FACTOR_TEMPLATE;
  payload: FactorTemplateWithRates;
}

interface DeleteFactorTemplate {
  type: typeof DELETE_FACTOR_TEMPLATE;
  payload: FactorTemplate;
}

interface UpdateFactorRate {
  type: typeof UPDATE_FACTOR_RATE;
  payload: FactorTemplateResourceRateChange;
}

interface SaveFactorRates {
  type: typeof SAVE_FACTOR_RATES;
  payload: FactorTemplateWithRates;
}

interface SaveActNoEntries {
  type: typeof SAVE_ACTNO_ENTRIES;
  payload: ActivityNumberEntry[];
}

export type ActionTypes =
  | AddFactorTemplate
  | GetFactorTemplates
  | UpdateFactorTemplate
  | SelectFactorTemplate
  | DeleteFactorTemplate
  | UpdateFactorRate
  | SaveFactorRates
  | SaveActNoEntries;

export type FactorTemplateDispatch = React.Dispatch<ActionTypes>;

export const getFactorTemplates = (dispatch: FactorTemplateDispatch) => {
  return (api: Api, siteId: number) => {
    return api
      .get<Response<FactorTemplateWithRates[]>>(
        `/sites/${siteId}/templates`,
        null
      )
      .then(response => {
        dispatch({ type: GET_FACTOR_TEMPLATES, payload: response.result });
      });
  };
};

export const addFactorTemplate = (dispatch: FactorTemplateDispatch) => {
  return (
    api: Api,
    tpl: FactorTemplate
  ): Promise<Response<FactorTemplateWithRates>> => {
    return api
      .post<Response<FactorTemplateWithRates>>(
        `/sites/${tpl.siteId}/templates`,
        tpl
      )
      .then(response => {
        if (!response.hasErrors) {
          dispatch({ type: ADD_FACTOR_TEMPLATE, payload: response.result });
          Promise.resolve(response);
        } else {
          return Promise.reject(response);
        }
      });
  };
};

export const updateFactorTemplate = (dispatch: FactorTemplateDispatch) => {
  return (
    api: Api,
    tpl: FactorTemplateWithRates
  ): Promise<Response<FactorTemplateWithRates>> => {
    return api
      .put<Response<FactorTemplateWithRates>>(
        `/sites/${tpl.siteId}/templates`,
        tpl
      )
      .then(response => {
        if (!response.hasErrors) {
          dispatch({ type: UPDATE_FACTOR_TEMPLATE, payload: response.result });
        } else {
          return Promise.reject(response);
        }
      });
  };
};

export const selectFactorTemplate = dispatch => {
  return (tpl: FactorTemplateWithRates) =>
    dispatch({ type: SELECT_FACTOR_TEMPLATE, payload: tpl });
};

export const deleteFactorTemplate = dispatch => {
  return (api: Api, tpl: FactorTemplate) => {
    return api
      .delete<Response<any>>(`/sites/${tpl.siteId}/templates/${tpl.id}`, null)
      .then(resp => {
        if (!resp.hasErrors) {
          dispatch({ type: DELETE_FACTOR_TEMPLATE, payload: tpl });
          return Promise.resolve();
        } else {
          toast.error(getErrorMessage(resp.errors));
          return Promise.reject();
        }
      });
  };
};

export const updateFactorRate = dispatch => {
  return (rate: FactorTemplateResourceRateChange) => {
    dispatch({ type: UPDATE_FACTOR_RATE, payload: rate });
  };
};

export const saveFactorRates = dispatch => {
  return (api: Api, tpl: FactorTemplateWithRates) => {
    const rates = tpl.resourceRates
      .filter(x => x.newVal != null)
      .map(x => {
        return { ...x, rate: x.newVal, newVal: null };
      });
    return api
      .put<Response<any>>(
        `/sites/${tpl.siteId}/templates/${tpl.id}/rates`,
        rates
      )
      .then(resp => {
        if (!resp.hasErrors) {
          dispatch({ type: SAVE_FACTOR_RATES, payload: tpl.resourceRates });
        } else {
          return Promise.reject(resp);
        }
      });
  };
};

export const saveActvityComponents = dispatch => {
  return (
    api: Api,
    actNoEntries: ActivityNumberEntry[],
    template: FactorTemplateWithRates
  ) => {
    return api
      .post<Response<any>>(
        `/sites/${template.siteId}/templates/${template.id}/actno-components`,
        actNoEntries
      )
      .then(resp => {
        if (!resp.hasErrors) {
          dispatch({ type: SAVE_ACTNO_ENTRIES, payload: actNoEntries });
        } else {
          return Promise.reject(resp);
        }
      });
  };
};

export type AddFactorTemplateDispatch = ReturnType<typeof addFactorTemplate>;

export type UpdateFactorTemplateDispatch = ReturnType<
  typeof updateFactorTemplate
>;

export type DeleteFactorTemplateDispatch = ReturnType<
  typeof deleteFactorTemplate
>;

export type UpdateFactorRateDispatch = ReturnType<typeof updateFactorRate>;

export type SaveActNoEntriesDispatch = ReturnType<typeof saveActvityComponents>;

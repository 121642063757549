import React, { useEffect, useState } from "react";
import { UserGetDto, Response } from "../../../models";
import { Modal, Button, Form, Message, Input } from "semantic-ui-react";
import { Api } from "../../../util/api/api";
import { updateUserPreferredEmail } from "../actions";
import { connect } from "react-redux";
import AppContext from "../../../app/AppContext";
import { getErrorMessage } from "../../../util";
import { toast } from "react-toastify";
import { getUserDisplayName } from "../../me/helpers";

type Props = {
  user: UserGetDto;
  open: boolean;
  onClose: () => void;
  siteId: number;
} & DispatchProps;

function ManagePreferredEmailModal(props: Props) {
  const { api } = React.useContext(AppContext);
  const [email, setEmail] = useState(props?.user?.preferredEmail ?? "");

  useEffect(() => {
    setEmail(props.user?.preferredEmail ?? "");
  }, [setEmail, props.user]);

  const _handleSave = async () => {
    let result = await props.updateUserPreferredEmail(
      api,
      props.siteId,
      props.user,
      email
    );

    if (result.hasErrors) {
      toast.error(getErrorMessage(result.errors));
    } else {
      setEmail("");
      props.onClose();
    }
  };

  return (
    <Modal
      title={`Manage Preferred Email for ${getUserDisplayName(props.user)}`}
      open={props.open}
      onClose={props.onClose}
      closeOnDimmerClick={false}
      size="tiny"
    >
      <Modal.Header>Preferred Email</Modal.Header>

      <Modal.Content>
        <Message warning>
          All email correspondence will be routed to the preferred email
          address.
        </Message>

        <Form onSubmit={_handleSave}>
          <Form.Field
            autoFocus
            control={Input}
            id="preferred-email-input"
            label="Preferred Email"
            onChange={(e, { value }) => setEmail(value)}
            required
            value={email}
          />
        </Form>
      </Modal.Content>

      <Modal.Actions>
        <Button secondary onClick={() => props.onClose()}>
          Cancel
        </Button>

        <Button
          content="Save Changes"
          primary
          icon="save"
          onClick={_handleSave}
        />
      </Modal.Actions>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    updateUserPreferredEmail: updateUserPreferredEmail(dispatch),
  };
};

interface DispatchProps {
  updateUserPreferredEmail: (
    api: Api,
    siteId: number,
    user: UserGetDto,
    email: string
  ) => Promise<Response<any>>;
}

export default connect(null, mapDispatchToProps)(ManagePreferredEmailModal);

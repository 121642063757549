import React from "react";
import { ensurePluginOrder, actions } from "react-table";

const pluginName = "useCellSelect";

export const useCellSelect = hooks => {
  hooks.getCellProps.push((props, { cell }) => [
    props,
    {
      onMouseDown: e => {
        cell.setCellSelected(true);
      },
      onFocus: e => {
        cell.setCellSelected(true);
      },
      style: {
        cursor: "pointer",
      },
    },
  ]);
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};

useCellSelect.pluginName = pluginName;

actions.selectCell = "selectCell";
actions.moveToCell = "moveToCell";

function reducer(state, action, previousState, instance) {
  if (action.type === actions.init) {
    return {
      ...state,
      selectedCell: null,
    };
  }

  if (action.type === actions.selectCell) {
    action.payload.columnIndex = instance.allColumns.findIndex(
      x => x.id === action.payload.column.id
    );
    action.payload.rowIndex = instance.filteredFlatRows.findIndex(
      x => x.id === action.payload.row.id
    );

    return {
      ...state,
      selectedCell: action.payload,
    };
  }

  if (action.type === actions.moveToCell) {
    const row = instance.filteredFlatRows[action.payload.rowIndex];
    const column = instance.allColumns[action.payload.columnIndex];

    return {
      ...state,
      selectedCell: {
        row,
        column,
        rowIndex: action.payload.rowIndex,
        columnIndex: action.payload.columnIndex,
      },
    };
  }

  return state;
}

function useInstance(instance) {
  const { plugins, dispatch } = instance;

  ensurePluginOrder(plugins, ["useRowSelect"], "useCellSelect");

  const moveToCell = React.useCallback(
    (row, col) =>
      dispatch({
        type: actions.moveToCell,
        payload: { rowIndex: row, columnIndex: col },
      }),
    [dispatch]
  );

  Object.assign(instance, {
    moveToCell,
  });
}

const isCellSelected = (cell, instance) => {
  const { state } = instance;
  const isSelected =
    state.selectedCell &&
    cell.row.id === state.selectedCell.row.id &&
    cell.column.id === state.selectedCell.column.id;

  return isSelected;
};

function prepareRow(row, { instance }) {
  const { dispatch } = instance;

  row.isActive = false;

  row.cells.forEach((cell, idx) => {
    cell.isSelected = isCellSelected(cell, instance);

    if (cell.isSelected) {
      row.isActive = true;
    }

    cell.setCellSelected = (value: boolean = true) => {
      dispatch({
        type: actions.selectCell,
        payload: {
          row,
          column: cell.column,
        },
      });
    };
  });
}

import React, { useState } from "react";
import { Input, Button, Modal, Form } from "semantic-ui-react";
import { toast } from "react-toastify";
import AppContext from "../../../app/AppContext";
import { Api } from "../../../util/api/api";
import { SiteGetDto, SiteDto } from "../../../models";

type Props = {
  open: boolean;
  onClose: () => void;
  sites: SiteGetDto[];
  createSite: (site: SiteDto, siteList: SiteGetDto[], api: Api) => Promise<any>;
};

function AddSiteModal(props: Props) {
  const { api } = React.useContext(AppContext);
  const [siteName, setSiteName] = useState("");

  const _handleSubmit = () => {
    props
      .createSite({ name: siteName }, props.sites, api)
      .then(x => {
        if (x.hasErrors) {
          toast(x.errors.map(x => x.errorMessage).join(" "));
        } else {
          props.onClose();
        }
      })
      .catch(_ => {})
      .finally(() => {
        setSiteName("");
      });
  };

  const _onClose = () => {
    setSiteName("");
    props.onClose();
  };

  return (
    <Modal
      onClose={props.onClose}
      size="tiny"
      open={props.open}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Add New Site</Modal.Header>
      <Modal.Content>
        <Form onSubmit={_handleSubmit}>
          <Form.Field
            control={Input}
            id="site-name"
            label="Site Name"
            onChange={(e, { value }) => setSiteName(value)}
            required
            value={siteName}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={_onClose} secondary />
        <Button content="Save" icon="save" onClick={_handleSubmit} primary />
      </Modal.Actions>
    </Modal>
  );
}

export default AddSiteModal;

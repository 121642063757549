import { toast } from "react-toastify";
import { ReleaseNoteDto, ReleaseNoteGetDto } from "../../models";
import { getErrorMessage } from "../../util";
import { Api } from "../../util/api/api";

export const GET_RELEASE_NOTES = "GET_RELEASE_NOTES";
export const ADD_RELEASE_NOTES = "ADD_RELEASE_NOTES";
export const DELETE_RELEASE_NOTES = "DELETE_RELEASE_NOTES";
export const EDIT_RELEASE_NOTES = "EDIT_RELEASE_NOTES";

interface GetReleaseNotes {
  type: typeof GET_RELEASE_NOTES;
  payload: ReleaseNoteGetDto[];
}

interface AddReleaseNotes {
  type: typeof ADD_RELEASE_NOTES;
  payload: ReleaseNoteGetDto[];
}

interface DeleteReleaseNotes {
  type: typeof DELETE_RELEASE_NOTES;
  payload: number[];
}

interface EditReleaseNotes {
  type: typeof EDIT_RELEASE_NOTES;
  payload: ReleaseNoteGetDto[];
}

export const getReleaseNotes = dispatch => {
  return (api: Api) => {
    return api.get("release-notes").then(response => {
      if (!response.hasErrors) {
        dispatch({ type: GET_RELEASE_NOTES, payload: response.result });
      } else {
        toast.error(getErrorMessage(response.errors));
      }
    });
  };
};

export type GetReleaseNotesDispatch = ReturnType<typeof getReleaseNotes>;

export const addReleaseNotes = dispatch => {
  return (notes: ReleaseNoteDto[], api: Api) => {
    return api.post("release-notes", notes).then(response => {
      if (!response.hasErrors) {
        dispatch({ type: ADD_RELEASE_NOTES, payload: response.result });
      } else {
        toast.error(getErrorMessage(response.errors));
      }
    });
  };
};

export type AddReleaseNotesDispatch = ReturnType<typeof addReleaseNotes>;

export const deleteReleaseNotes = dispatch => {
  return (idsToDelete: number[], api: Api) => {
    return api.put("/release-notes/delete", idsToDelete).then(response => {
      if (!response.hasErrors) {
        dispatch({ type: DELETE_RELEASE_NOTES, payload: idsToDelete });
      } else {
        toast.error(getErrorMessage(response.errors));
      }
    });
  };
};

export type DeleteReleaseNotesDispatch = ReturnType<typeof deleteReleaseNotes>;

export const editReleaseNotes = dispatch => {
  return (notes: ReleaseNoteGetDto[], api: Api) => {
    return api.put("release-notes", notes).then(response => {
      if (!response.hasErrors) {
        dispatch({ type: EDIT_RELEASE_NOTES, payload: notes });
      } else {
        toast.error(getErrorMessage(response.errors));
      }
    });
  };
};

export type EditReleaseNotesDispatch = ReturnType<typeof editReleaseNotes>;

export type ActionTypes =
  | GetReleaseNotes
  | AddReleaseNotes
  | DeleteReleaseNotes
  | EditReleaseNotes;

import React, { useState } from "react";
import styled from "@emotion/styled";
import { UserGetDto, GetSiteDto, FactorTemplate } from "../../../models";
import {
  Table,
  Button,
  Modal,
  TableRow,
  TableCell,
  TableBody,
  DropdownProps,
  Select,
} from "semantic-ui-react";
import { AppState } from "../../../app/AppState";
import { connect } from "react-redux";
import { addTemplateToUser, deleteTemplateFromUser } from "../actions";
import { Api } from "../../../util/api/api";
import AppContext from "../../../app/AppContext";
import { toast } from "react-toastify";
import { Response } from "../../../models/index";
import { getErrorMessage } from "../../../util";
import { getUserDisplayName } from "../../me/helpers";

const ManageTemplateModalRoot = styled(Modal)`
  .select-template-container {
    display: flex;
    align-items: center;

    .ui.button {
      margin-left: 12px;
    }
  }
`;

type OwnProps = {
  site: GetSiteDto;
  factorTemplates: FactorTemplate[];
};

type ParamProps = {
  user: UserGetDto;
  open: boolean;
  onClose: () => void;
};

type Props = OwnProps & DispatchProps & ParamProps;

function ManageSiteTemplatesModal(props: Props) {
  const { api } = React.useContext(AppContext);

  const [selection, setSelection] = useState(0);

  const _dropdownOptions = props.factorTemplates?.map(x => {
    return { text: x.name, value: x.id };
  });

  const _handleDelete = async (template: FactorTemplate) => {
    let result = await props.deleteTemplateFromUser(
      api,
      props.site.id,
      props.user.id,
      template.id
    );

    if (result.hasErrors) {
      toast.error(getErrorMessage(result.errors));
    }
  };

  const _handleChange = (e: any, data: DropdownProps) => {
    setSelection(data.value as number);
  };

  const _handleAddTemplate = async () => {
    let result = await props.addTemplateToUser(
      api,
      props.site.id,
      props.user.id,
      selection
    );

    if (result.hasErrors) {
      toast.error(result.errors.map(x => x.errorMessage).join(". "));
    }
  };

  return (
    <ManageTemplateModalRoot
      closeOnDimmerClick={false}
      open={props.open}
      onClose={props.onClose}
      size="small"
    >
      <Modal.Header>{`Manage Site Templates for ${getUserDisplayName(
        props.user
      )}`}</Modal.Header>

      <Modal.Content>
        <div className="select-template-container">
          <Select
            placeholder="Select a template"
            search
            selection
            options={_dropdownOptions}
            onChange={_handleChange}
            size="mini"
          />

          <Button
            disabled={!selection}
            icon="plus"
            primary
            onClick={_handleAddTemplate}
            size="tiny"
            content="Add Template"
          />
        </div>

        <Table padded striped unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="1">Template</Table.HeaderCell>

              <Table.HeaderCell colSpan="1">Default Rate</Table.HeaderCell>

              <Table.HeaderCell colSpan="1" />
            </Table.Row>
          </Table.Header>
          <TableBody>
            {props.user?.factorTemplates?.map(x => (
              <TableRow key={x.name}>
                <TableCell>{x?.name}</TableCell>

                <TableCell>{x?.defaultRate}</TableCell>

                <TableCell collapsing>
                  <Button
                    negative
                    icon="delete"
                    onClick={() => _handleDelete(x)}
                    title="Remove Template"
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Modal.Content>

      <Modal.Actions>
        <Button content="Close" onClick={() => props.onClose()} secondary />
      </Modal.Actions>
    </ManageTemplateModalRoot>
  );
}

const mapStateToProps = (state: AppState): OwnProps => ({
  site: state.me.selectedSite,
  factorTemplates: state.siteAdmin.siteDetails?.factorTemplates,
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
  addTemplateToUser: addTemplateToUser(dispatch),
  deleteTemplateFromUser: deleteTemplateFromUser(dispatch),
});

type DispatchProps = {
  addTemplateToUser: (
    api: Api,
    siteId: number,
    userId: number,
    templateId: number
  ) => Promise<Response<any>>;

  deleteTemplateFromUser: (
    api: Api,
    siteId: number,
    userId: number,
    templateId: number
  ) => Promise<Response<any>>;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageSiteTemplatesModal);

import _ from "lodash";

type BaseChord = {
  ctrlKey: boolean;
  altKey: boolean;
  shiftKey: boolean;
  metaKey: boolean;
};

type KeyboardChord = {
  key: string;
} & BaseChord;

export const defaultChord: KeyboardChord = {
  ctrlKey: false,
  altKey: false,
  shiftKey: false,
  metaKey: false,
  key: "",
};

const keyMap = {
  alt: "altKey",
  ctrl: "ctrlKey",
  meta: "metaKey",
  shift: "shiftKey",
  "<up>": "ArrowUp",
  "<down>": "ArrowDown",
  "<left>": "ArrowLeft",
  "<right>": "ArrowRight",
  "<enter>": "Enter",
  "<click>": "Click",
};

export function kbd(cmd: string) {
  const keys = cmd.split(/[-]/g).map(k => {
    const kl = k.toLowerCase();
    return keyMap.hasOwnProperty(kl) ? keyMap[kl] : k;
  });

  let chord = { ...defaultChord };

  if (keys.length === 2) {
    if (!chord.hasOwnProperty(keys[0])) {
      throw Error("Invalid chord combo");
    }
    chord[keys[0]] = true;
    chord.key = keys[1];
  } else {
    chord.key = keys[0];
  }

  return chord;
}

type KeyRegister<T> = { chord: T; cmd: () => any };

function match(evt: KeyboardChord, chord: KeyboardChord) {
  const result =
    chord.key === evt.key &&
    chord.altKey === evt.altKey &&
    chord.shiftKey === evt.shiftKey &&
    chord.ctrlKey === evt.ctrlKey &&
    chord.metaKey === evt.metaKey;
  return result;
}

function mouseMatch(evt: BaseChord, chord: BaseChord) {
  const result =
    chord.altKey === evt.altKey &&
    chord.shiftKey === evt.shiftKey &&
    chord.ctrlKey === evt.ctrlKey &&
    chord.metaKey === evt.metaKey;
  return result;
}

type AddRemoveEventListenerType = (
  type: string,
  cb: EventListenerOrEventListenerObject
) => void;

export function makeKeyListener(
  root: {
    addEventListener: AddRemoveEventListenerType;
    removeEventListener: AddRemoveEventListenerType;
  } = document,
  preventDefault: boolean = true,
  stopPropagation: boolean = true
): [
  (chord: KeyboardChord, cmd: () => any) => void,
  (chord: KeyboardChord) => void,
  (chord: BaseChord, cmd: () => any) => void,
  (chord: BaseChord) => void
] {
  let registeredKeys: KeyRegister<KeyboardChord>[] = [];
  let registeredMouseKeys: KeyRegister<BaseChord>[] = [];

  function keyListener(evt: KeyboardEvent) {
    const matched = registeredKeys.find(x => match(evt, x.chord));
    if (matched) {
      matched.cmd();
      preventDefault && evt.preventDefault();
      stopPropagation && evt.stopPropagation();
      return false;
    }
  }

  function mouseListener(evt: MouseEvent) {
    const matched = registeredMouseKeys.find(x => mouseMatch(evt, x.chord));
    if (matched) {
      matched.cmd();
      preventDefault && evt.preventDefault();
      stopPropagation && evt.stopPropagation();
      return false;
    }
  }

  function setKey(chord: KeyboardChord, cmd: () => any) {
    if (_.isEmpty(registeredKeys)) {
      root.addEventListener("keydown", keyListener);
    }

    registeredKeys.push({ chord, cmd });
  }

  function setMouse(chord: BaseChord, cmd: () => any) {
    if (_.isEmpty(registeredMouseKeys)) {
      root.addEventListener("click", mouseListener);
    }

    registeredMouseKeys.push({ chord, cmd });
  }

  function removeKey(chord: KeyboardChord) {
    const idx = registeredKeys.findIndex(x => match(chord, x.chord));
    if (idx >= 0) registeredKeys.splice(idx, 1);

    if (_.isEmpty(registeredKeys)) {
      root.removeEventListener("keydown", keyListener);
    }
  }

  function removeMouse(chord: BaseChord) {
    const idx = registeredMouseKeys.findIndex(x => mouseMatch(chord, x.chord));
    if (idx >= 0) registeredMouseKeys.splice(idx, 1);

    if (_.isEmpty(registeredMouseKeys)) {
      root.removeEventListener("click", mouseListener);
    }
  }

  return [setKey, removeKey, setMouse, removeMouse];
}

import _ from "lodash";
import React from "react";
import { actions, Hooks, Row } from "react-table";
import { SelectedRowDescriptor } from "../../../../models";

const pluginName = "useRowSelection";

export const useRowSelection = (hooks: Hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};

useRowSelection.pluginName = pluginName;

const prepareRow = (row: Row, { instance }) => {
  const { selectRow, state, dispatch } = instance;

  if (row.original) {
    row.select = (selectionConfig?: any) => {
      selectRow(row.id, row.canDelete, row.canDemote);

      if (!selectionConfig?.ignoreSettingLastSelectedRow) {
        dispatch({
          type: actions.setLastSelectedRow,
          payload: { rowId: row.id },
        });
      }
    };
    const isSelected = (state.selectedRows as Array<SelectedRowDescriptor>)
      .map(x => x.rowId)
      .includes(row.id);

    row.isSelected = isSelected;
  }
};

actions.setLastSelectedRow = "setLastSelectedRow";

function reducer(state, action, previousState, instance) {
  if (action.type === actions.init) {
    return {
      ...state,
      lastSelectedRow: [],
    };
  }

  if (action.type === actions.setLastSelectedRow) {
    let currentState = state.lastSelectedRow as any[];
    if (currentState.length > 5) {
      let newState = currentState.slice();
      newState.splice(0, 1);
      newState.push(action.payload.rowId);
      return { ...state, lastSelectedRow: newState };
    }
    return {
      ...state,
      lastSelectedRow: [...state.lastSelectedRow, action.payload.rowId],
    };
  }

  return state;
}

function parseRowId(rowId: string) {
  let indexParsed = rowId.includes(".") ? rowId.split(".") : [rowId];
  let parentIndex = Number(indexParsed[0]);
  let childIndex = indexParsed.length > 1 ? Number(indexParsed[1]) : null;

  return { parentIndex, childIndex };
}

//x > y returns 1
//x < y returns -1
//x == y returns 0
function compareRowId(rowIdX: string, rowIdY: string) {
  let x = parseRowId(rowIdX);
  let y = parseRowId(rowIdY);

  if (x.parentIndex === y.parentIndex) {
    if (_.isNumber(x.childIndex)) {
      if (_.isNumber(y.childIndex)) {
        return x.childIndex - y.childIndex;
      } else {
        return 1;
      }
    } else {
      if (_.isNumber(y.childIndex)) {
        return -1;
      }
      return 0;
    }
  } else {
    return x.parentIndex - y.parentIndex;
  }
}

function selectRowsLocal(
  rows: Row[],
  startingIndex: string,
  endingIndex: string
) {
  startingIndex = _.isUndefined(startingIndex) ? "0" : startingIndex;
  let rowsToSelect = _.filter(rows, x => {
    //check lower bound
    let isGreaterThanStart = compareRowId(x.id, startingIndex) > 0;

    //check upper bound
    let isLessThanEnd = compareRowId(x.id, endingIndex) < 0;

    return isGreaterThanStart && isLessThanEnd;
  });

  let endingRow = _.find(rows, x => x.id === endingIndex);
  let startingRow = _.find(rows, x => x.id === startingIndex);

  //deselect ending row edge case
  if (
    _.some(rowsToSelect, x => x.isSelected) &&
    endingRow &&
    endingRow.isSelected
  ) {
    endingRow?.select({ ignoreSettingLastSelectedRow: true });
  }

  //select starting row edge case
  if (startingRow && !startingRow.isSelected) {
    startingRow.select({ ignoreSettingLastSelectedRow: true });
  }

  _.forEach(rowsToSelect, x => {
    if (x.select) x.select({ ignoreSettingLastSelectedRow: true });
  });
}

function useInstance(instance) {
  const selectRows = React.useCallback(() => {
    const lastSelectedRow = instance.state.lastSelectedRow;

    if (_.isUndefined(lastSelectedRow) || _.isNull(lastSelectedRow)) {
      return;
    }

    let selectedRows = _.filter(
      instance.filteredFlatRows,
      x => x.isSelected
    ) as Row[];

    if (selectedRows.length === 0) {
      return;
    }

    let length = lastSelectedRow.length;
    let ending = lastSelectedRow[length - 1];
    let starting = lastSelectedRow[length - 2];

    //get all the rows in-between
    if (Number(starting) > Number(ending)) {
      selectRowsLocal(instance.filteredFlatRows, ending, starting);
    } else {
      selectRowsLocal(instance.filteredFlatRows, starting, ending);
    }
  }, [instance.filteredFlatRows, instance.state]);

  Object.assign(instance, { selectRows });
}

import React, { useEffect, useState } from "react";
import { Modal, Table, Button } from "semantic-ui-react";
import { SiteGetDto, PlanGetDto } from "../../../models";
import AppContext from "../../../app/AppContext";
import { toast } from "react-toastify";
import moment from "moment";
import { Api } from "../../../util/api/api";
import { DeleteModal } from "../../components/DeleteModal";
import { CopyPlanDispatch, DeletePlanDispatch } from "../actions";

type Props = {
  open: boolean;
  onClose: () => void;
  site: SiteGetDto;
  sites: SiteGetDto[];
  deletePlan: DeletePlanDispatch;
  copyPlan: CopyPlanDispatch;
};

function handleDeletePlan(
  planId: number,
  siteId: number,
  siteList: SiteGetDto[],
  api: Api,
  deletePlan: DeletePlanDispatch
) {
  return async () => {
    let result = await deletePlan(planId, siteId, siteList, api);
    if (result.hasErrors) {
      toast(result.errors.map(x => x.errorMessage).join(" "), {
        autoClose: false,
        position: "top-center",
      });
    } else {
      toast.success("plan deleted!");
    }
  };
}

function ManagePlansModal(props: Props) {
  const { api } = React.useContext(AppContext);
  const { site } = props;

  const [sitePlans, setSitePlans] = useState<PlanGetDto[]>(null);
  const [btnDebounce, setbtnDebounce] = useState<boolean>(false);
  const [deleteModalShow, setDeleteModalShow] = useState<{
    plan: PlanGetDto;
    open: boolean;
  }>({
    plan: null,
    open: false,
  });

  const _handleDelete = async (plan: PlanGetDto) => {
    setDeleteModalShow({ plan: plan, open: true });
  };

  const _handleCopy = async (plan: PlanGetDto) => {
    setbtnDebounce(true);
    let result = await props.copyPlan(plan.id, api, true);
    if (result.hasErrors) {
      toast(result.errors.map(x => x.errorMessage).join(" "), {
        autoClose: false,
        position: "top-center",
      });
    } else {
      toast.success("plan copied!");
    }

    setbtnDebounce(false);
  };

  const sitePlans_ = site?.plans;
  useEffect(() => {
    setSitePlans(site?.plans);
  }, [site, sitePlans_]);

  const TableRows = sitePlans?.map(x => (
    <Table.Row key={x.id}>
      <Table.Cell>
        {x.jobNumber} - {x.title}
      </Table.Cell>
      <Table.Cell>{x.createdBy}</Table.Cell>
      <Table.Cell>{moment(x.createdDate).format("YYYY-MM-DD")}</Table.Cell>
      <Table.Cell collapsing>
        <Button
          icon="copy"
          content="Copy Plan"
          onClick={() => _handleCopy(x)}
          primary
          disabled={btnDebounce}
        />
        <Button
          icon="trash alternate outline"
          content="Delete"
          negative
          onClick={() => _handleDelete(x)}
          disabled={btnDebounce}
        />
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <Modal open={props.open} onClose={props.onClose} closeOnDimmerClick={false}>
      <Modal.Header content={`Manage Plans for ${props.site?.name}`} />
      <Modal.Content>
        <Table padded striped unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell colSpan="1">Name</Table.HeaderCell>
              <Table.HeaderCell colSpan="1">Owner</Table.HeaderCell>
              <Table.HeaderCell colSpan="1">Date Created</Table.HeaderCell>
              <Table.HeaderCell colSpan="1"></Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>{TableRows}</Table.Body>
        </Table>

        <DeleteModal
          open={deleteModalShow.open}
          onClose={() => setDeleteModalShow({ plan: null, open: false })}
          deleteAction={handleDeletePlan(
            deleteModalShow?.plan?.id,
            deleteModalShow?.plan?.siteId,
            props.sites,
            api,
            props.deletePlan
          )}
          modalContent={`Are you sure you want to permanently delete ${deleteModalShow?.plan?.title}?`}
          modalTitle={`Delete Plan created by ${deleteModalShow?.plan?.createdBy}`}
        ></DeleteModal>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Close" onClick={props.onClose} secondary />
      </Modal.Actions>
    </Modal>
  );
}

export default ManagePlansModal;

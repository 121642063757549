import { Auth } from "../../util/auth/client";
import { User } from "../../models";
import { CLEAR_HARD_FACTORS } from "../me/plans/actions";

export const LOGGED_IN_USER = "LOGGED_IN_USER";
export const LOGGED_OUT_USER = "LOGGED_OUT_USER";
export const LOGGING_IN_USER = "LOGGING_IN_USER";
export const LOG_IN_FAILURE = "LOG_IN_FAILURE";
export const SET_MSAL_AUTH = "SET_MSAL_AUTH";

export interface State {
  loggingIn: boolean;
  user?: User;
  error?: any;
}

interface LoggedInUserAction {
  type: typeof LOGGED_IN_USER;
  user: User;
}

interface LoggedOutUserAction {
  type: typeof LOGGED_OUT_USER;
}

interface LoggingInUserAction {
  type: typeof LOGGING_IN_USER;
  payload?: boolean;
}

interface SetMsalAuth {
  type: typeof SET_MSAL_AUTH;
  payload: { token: string; loggedIn: boolean };
}

interface LogInFailure {
  type: typeof LOG_IN_FAILURE;
  error: string;
}

export const login = dispatch => {
  return (auth: Auth) => {
    dispatch({ type: LOGGING_IN_USER });
    auth
      .login()
      .then(user => {
        dispatch({ type: LOGGED_IN_USER, user });
        dispatch({ type: CLEAR_HARD_FACTORS });
      })
      .catch(err => {
        dispatch({ type: LOG_IN_FAILURE, error: err.message });
      });
  };
};

export const loginSilent = dispatch => {
  return (auth: Auth) => {
    dispatch({ type: LOGGING_IN_USER });
    auth
      .loginSilent()
      .then(user => {
        dispatch({ type: LOGGED_IN_USER, user });
        dispatch({ type: CLEAR_HARD_FACTORS });
      })
      .catch(err => {
        console.log(`Silent login failed: ${err.message}`);
        dispatch({ type: LOGGING_IN_USER, payload: false });
      });
  };
};

export const logout = dispatch => {
  return (auth: Auth) => {
    auth.logout();
  };
};

export const logoutComplete = dispatch => {
  return () => {
    dispatch({ type: LOGGED_OUT_USER, user: null });
  };
};

export type AuthActionTypes =
  | LoggingInUserAction
  | LoggedOutUserAction
  | LoggedInUserAction
  | LogInFailure
  | SetMsalAuth;

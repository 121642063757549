import React, { useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { connect } from "react-redux";
import { Table, Button } from "semantic-ui-react";
import { AppState } from "../../app/AppState";
import {
  getSites,
  deleteSite,
  createSite,
  editSite,
  addSiteAdmin,
  removeSiteAdmin,
  copyPlan,
  deletePlan,
  GetSitesDispatch,
  CreateSiteDispatch,
  EditSiteDispatch,
  DeleteSiteDispatch,
  AddSiteAdminDispatch,
  RemoveSiteAdminDispatch,
  DeletePlanDispatch,
  CopyPlanDispatch,
} from "./actions";
import PageContent from "../components/PageContent";
import AddSiteModal from "./sites/AddSiteModal";
import EditSiteModal from "./sites/EditSiteModal";
import ManagePlansModal from "./sites/ManagePlansModal";
import { Api } from "../../util/api/api";
import AppContext from "../../app/AppContext";
import { SiteGetDto } from "../../models";
import { toast } from "react-toastify";
import { DeleteModal } from "../components/DeleteModal";

const StyledRoot = styled(PageContent)`
  .hoverable {
    cursor: pointer;

    :hover {
      background-color: #d6d6d6;
    }
  }
`;

interface OwnProps {
  sites: SiteGetDto[];
}

interface DispatchProps {
  getSites: GetSitesDispatch;
  createSite: CreateSiteDispatch;
  editSite: EditSiteDispatch;
  deleteSite: DeleteSiteDispatch;
  addSiteAdmin: AddSiteAdminDispatch;
  removeSiteAdmin: RemoveSiteAdminDispatch;
  deletePlan: DeletePlanDispatch;
  copyPlan: CopyPlanDispatch;
}

type Props = OwnProps & DispatchProps;

function _handleDeleteSite(
  deleteSite: DeleteSiteDispatch,
  siteId: number,
  siteList: SiteGetDto[],
  api: Api
) {
  return async () => {
    let result = await deleteSite(siteId, siteList, api);
    if (result.hasErrors) {
      toast(result.errors.map(x => x.errorMessage).join(" "), {
        autoClose: false,
        position: "top-center",
      });
    }
  };
}

function ManageSites(props: Props) {
  //api
  const { api } = React.useContext(AppContext);

  const { getSites } = props;

  //state
  const [selectedSite, setSelectedSite] = useState<SiteGetDto>(null);
  const [newSiteModalOpen, setNewSiteModalOpen] = useState(false);
  const [editSiteModalOpen, setEditSiteModalOpen] = useState(false);
  const [managePlansModalOpen, setManagePlansModalOpen] = useState(false);
  const [deleteSiteConfirmationOpen, setDeleteSiteConfirmationOpen] = useState(
    false
  );

  useEffect(() => {
    getSites(api);
  }, [api, getSites]);

  const _showModal = (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    selectedSite: SiteGetDto
  ) => {
    setSelectedSite(selectedSite);
    setState(true);
  };

  const _closeModal = (
    setState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setSelectedSite(null);
    setState(false);
  };

  const TableRows = props.sites?.map(x => (
    <Table.Row key={x?.name}>
      <Table.Cell>{x?.name}</Table.Cell>
      <Table.Cell>
        {x?.users.length + x?.siteAdmins.length} active users
      </Table.Cell>
      <Table.Cell>{`${x?.siteAdmins.length} active admins   `} </Table.Cell>
      <Table.Cell>{`${x?.plans.length} plans   `}</Table.Cell>
      <Table.Cell collapsing>
        <Button
          icon="setting"
          onClick={() => _showModal(setEditSiteModalOpen, x)}
          title="Configure Site Settings"
          content="Configure Site"
          primary
        />
        <Button
          icon="edit"
          onClick={() => _showModal(setManagePlansModalOpen, x)}
          title="Manage Site Plans"
          content="Edit Plans"
          secondary
        />
        <Button
          icon="trash alternate outline"
          negative
          onClick={() => _showModal(setDeleteSiteConfirmationOpen, x)}
          title="Permanently delete site"
          content="Delete"
        />
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <StyledRoot
      actions={
        <Button
          primary
          icon="add"
          onClick={() => _showModal(setNewSiteModalOpen, null)}
          content="Add New Site"
        />
      }
      title="Manage Sites"
    >
      <Table padded singleLine striped unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="1">Manage Sites</Table.HeaderCell>
            <Table.HeaderCell colSpan="1">Active Users</Table.HeaderCell>
            <Table.HeaderCell colSpan="1">Site Admins</Table.HeaderCell>
            <Table.HeaderCell colSpan="1">Plans</Table.HeaderCell>
            <Table.HeaderCell colSpan="1"></Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{TableRows}</Table.Body>
      </Table>

      <AddSiteModal
        open={newSiteModalOpen}
        onClose={() => _closeModal(setNewSiteModalOpen)}
        sites={props.sites}
        createSite={props.createSite}
      />

      <DeleteModal
        open={deleteSiteConfirmationOpen}
        onClose={() => _closeModal(setDeleteSiteConfirmationOpen)}
        deleteAction={_handleDeleteSite(
          props.deleteSite,
          selectedSite?.id,
          props.sites,
          api
        )}
        modalContent={`Are you sure you want to permanently delete ${
          selectedSite?.name || "this site"
        }?`}
        modalTitle="Delete Site"
      />

      <EditSiteModal
        open={editSiteModalOpen}
        onClose={() => _closeModal(setEditSiteModalOpen)}
        site={selectedSite}
        sites={props.sites}
        editSite={props.editSite}
        onSuccess={() => getSites(api)}
      />

      <ManagePlansModal
        open={managePlansModalOpen}
        onClose={() => _closeModal(setManagePlansModalOpen)}
        site={selectedSite}
        sites={props.sites}
        deletePlan={props.deletePlan}
        copyPlan={props.copyPlan}
      />
    </StyledRoot>
  );
}

const mapStateToProps = (state: AppState): OwnProps => ({
  sites: state.admin.sites,
});

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    getSites: getSites(dispatch),
    createSite: createSite(dispatch),
    editSite: editSite(dispatch),
    deleteSite: deleteSite(dispatch),
    addSiteAdmin: addSiteAdmin(dispatch),
    removeSiteAdmin: removeSiteAdmin(dispatch),
    deletePlan: deletePlan(dispatch),
    copyPlan: copyPlan(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSites);

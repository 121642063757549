import {
  PlanLineItemState,
  FactorTemplateDataDto,
  AbbreviationGetDto,
  PlanLineItemDto,
  ActivityNumberComponentDto,
  NewPlanLineItemState,
  ErrorMap,
  Issue,
  HardFactorDto,
  FactorTemplate,
} from "../../../models";
import { Fields, ActNoProps } from "../../../constants";
import { generateUUID } from "../../../util";
import columns from "../../../features/me/plans/planGridColumns";
import _ from "lodash";
import { store } from "../../../app/store";
import { PreviousValue, DefaultValue } from "../../../models/grid";
import { canDefault } from "../../components/grid";

export const addCalculatedFields = (
  line: PlanLineItemState,
  factors: FactorTemplateDataDto,
  abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>
) => {
  line.rate =
    factors.resourceRates.find(x => x.abbreviation === line.resource)?.rate ??
    factors.defaultRate;
  line.baseFactor = getBaseFactor(line, abbreviations, factors);
  line.bumped = getBumpedFactor(line);
  line.baseHours = line.quantity * line.baseFactor;
  line.bumpedHr = line.quantity * line.bumped;
  line.dollars = line.rate * line.bumpedHr;

  return line;
};

const getBaseFactor = (
  line: PlanLineItemState,
  abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>,
  factorTemplate: FactorTemplate
) => {
  if (line.abbr && line.abbr !== "") {
    const abbreviation = abbreviations?.find(x => x.abbr === line.abbr);
    if (!_.isNil(abbreviation)) {
      if (!_.isNil(factorTemplate)) {
        return abbreviation.factor * factorTemplate.factorGlobalAdjustment;
      }

      return abbreviation.factor;
    }
  }

  return !line.isHeader ? line.user : 0;
};

const getBumpedFactor = (line: PlanLineItemState) => {
  var factor = line.baseFactor;

  const sipaFactors = [] as HardFactorDto[];

  if (!line.isHeader && factor !== 0) {
    let hardFactor = 0;

    line.hardFactors?.forEach(hf => {
      if (hf.category === "SIPA") {
        sipaFactors.push(hf);
      } else if (hf.factor > 1) {
        hardFactor = hardFactor + (hf.factor - 1);
      } else if (hf.factor < 1 && hf.factor > 0) {
        hardFactor = hardFactor + (1 - hf.factor) * -1;
      }
    });

    if (hardFactor !== 0) {
      factor = factor + factor * hardFactor;
    }

    sipaFactors.forEach(f => (factor = factor * f.factor));

    return factor;
  }

  return 0;
};

export const addHeaderCalculatedFields = (
  line: PlanLineItemState,
  inPlace: boolean = false
) => {
  if (line.subRows) {
    const baseHours = line.subRows
      .map(x => x.baseHours)
      .reduce((partial_sum, a) => partial_sum + a, 0);

    const bumpedHr = line.subRows
      .map(x => x.bumpedHr)
      .reduce((partial_sum, a) => partial_sum + a, 0);

    const dollars = line.subRows
      .map(x => x.dollars)
      .reduce((partial_sum, a) => partial_sum + a, 0);

    if (
      line.baseHours !== baseHours ||
      line.bumpedHr !== bumpedHr ||
      line.dollars !== dollars
    ) {
      if (inPlace) {
        line.baseHours = baseHours;
        line.bumpedHr = bumpedHr;
        line.dollars = dollars;
      } else {
        return { ...line, baseHours, bumpedHr, dollars };
      }
    }
  }

  return line;
};

export const calculateTotals = (
  data: ReadonlyArray<Readonly<PlanLineItemState>>
) => {
  let prevRow = null as PlanLineItemState;

  return data.map(header => {
    let hasChangedSubrows = false;

    const subRows = header.subRows.map(row => {
      const runningTotal = (prevRow?.runningTotal ?? 0) + row.dollars;
      const runningHrTotal = (prevRow?.runningHrTotal ?? 0) + row.baseHours;
      const bumpedRunningHrTotal =
        (prevRow?.bumpedRunningHrTotal ?? 0) + row.bumpedHr;

      prevRow = row;

      if (
        row.runningTotal !== runningTotal ||
        row.runningHrTotal !== runningHrTotal ||
        row.bumpedRunningHrTotal !== bumpedRunningHrTotal
      ) {
        hasChangedSubrows = true;
        const updatedRow = {
          ...row,
          runningTotal,
          runningHrTotal,
          bumpedRunningHrTotal,
        };

        prevRow = updatedRow;

        return updatedRow;
      }

      return row;
    });

    if (hasChangedSubrows) {
      return { ...header, subRows };
    }

    return header;
  });
};

export const setActivityNumbers = (
  lines: PlanLineItemState[],
  factors: FactorTemplateDataDto,
  jobNumber: string,
  system: string,
  unit: string
) => {
  const actNoComps = factors.activityNumberComponents.sort(function (a, b) {
    return a.order - b.order;
  });
  lines?.forEach(line => {
    setActivityNumber(line, actNoComps, jobNumber, system, unit);
    if (line.subRows.length > 0) {
      setActivityNumbers(line.subRows, factors, jobNumber, system, unit);
    }
  });
};

export const setActivityNumber = (
  line: PlanLineItemState,
  actNoComps: ActivityNumberComponentDto[],
  jobNumber: string,
  system: string,
  unit: string
) => {
  const actNo = actNoComps
    .map(
      e =>
        e.prefix +
        getProperty(e.property, line, jobNumber, system, unit) +
        e.suffix
    )
    .join("");
  if (line.actNo !== actNo) {
    line.actNo = actNo;
    line.modified = true;
    line.lastModified = Date.now();
  }
};

const getProperty = (
  property: string,
  line: PlanLineItemDto,
  jobNumber: string,
  system: string,
  unit: string
): string => {
  switch (property) {
    case ActNoProps.WorkItemNumber:
      return jobNumber;
    case ActNoProps.Sequence:
      return line.sequence;
    case ActNoProps.SystemValue:
      return system;
    case ActNoProps.UnitValue:
      return unit;
    default:
      return;
  }
};

export const setMiscFieldValues = (
  column: string,
  value: string,
  lines: PlanLineItemState[]
) => {
  const map = (column): keyof PlanLineItemState => {
    switch (column) {
      case Fields.MiscField1:
        return "miscellaneousField1";
      case Fields.MiscField2:
        return "miscellaneousField2";
      case Fields.MiscField3:
        return "miscellaneousField3";
      case Fields.MiscField4:
        return "miscellaneousField4";
      case Fields.MiscField5:
        return "miscellaneousField5";
      case Fields.MiscField6:
        return "miscellaneousField6";
      default:
        break;
    }
  };

  const apply = (accessor: keyof Extract<PlanLineItemState, string>) => {
    lines.forEach(header =>
      header.subRows.forEach(row => {
        if (row[accessor] !== value) {
          row[accessor] = value;
          row.modified = true;
          row.lastModified = Date.now();
        }
      })
    );
  };

  return apply(map(column));
};

export const setMiscFieldValue = (
  column: string,
  value: string,
  line: PlanLineItemDto
) => {
  switch (column) {
    case Fields.MiscField1:
      line.miscellaneousField1 = value;
      break;
    case Fields.MiscField2:
      line.miscellaneousField2 = value;
      break;
    case Fields.MiscField3:
      line.miscellaneousField3 = value;
      break;
    case Fields.MiscField4:
      line.miscellaneousField4 = value;
      break;
    case Fields.MiscField5:
      line.miscellaneousField5 = value;
      break;
    case Fields.MiscField6:
      line.miscellaneousField6 = value;
      break;
    default:
      break;
  }
};

//this will set the isInSequence flag for a header and any subrows on it
export const calculateSequence = (
  planData: PlanLineItemState[],
  rowIndex: number
) => {
  const header = planData[rowIndex];
  const headerSequence = Number(header.sequence);
  const prevRowSequence =
    rowIndex === 0
      ? headerSequence - 1
      : Number(planData[rowIndex - 1].sequence);
  const nextRowSequence =
    rowIndex === planData.length - 1
      ? headerSequence + 1
      : Number(planData[rowIndex + 1].sequence);
  const isHeaderInSequenceWithHeaders =
    prevRowSequence < headerSequence && headerSequence < nextRowSequence;

  if (header.subRows.length > 0) {
    header.subRows.forEach(task => {
      const isInSequence = task.sequence === header.sequence;
      task.isInSequence = isInSequence;
    });

    header.isInSequence =
      !_.some(header.subRows, x => !x.isInSequence) &&
      isHeaderInSequenceWithHeaders;
  } else {
    header.isInSequence = isHeaderInSequenceWithHeaders;
  }

  if (!header.isInSequence) {
    let errors: ErrorMap<PlanLineItemState> = {};
    errors["sequence"] = {
      type: "warning",
      description:
        "This header is out of sequence or contains tasks out of sequence",
    };
    header.errors = { ...errors, ...header.errors };
  } else {
    delete header.errors["sequence"];
  }
};

export const generateNewLine = (
  isHeader: boolean,
  sequence: string,
  defaultValues: DefaultValue[],
  previousValues: PreviousValue[]
): NewPlanLineItemState => {
  const planState = store.getState().planData.plan;

  const newLineItem = {
    id: 0,
    clientId: generateUUID(),
    planId: planState.id,
    description: "",
    actNo: "",
    isHeader: isHeader,
    user: 0,
    numberOfMen: 0,
    quantity: 0,
    duration: 0,
    orderInExport: -1,
    isInSequence: true,
    abbreviationId: 0,
    modified: true,
    lastModified: Date.now(),
    errors: {},
    sequence,
    subRows: [],
    abbr: "",
    area: "",
    notes: "",
    drawingNumber: "",
    equipmentNumber: "",
    typeOfWork: "",
    resource: "",
    time: "",
    company: "",
    tpNumber: "",
    size1: "",
    tag: "",
    miscellaneousField1: "",
    miscellaneousField2: "",
    miscellaneousField3: "",
    miscellaneousField4: "",
    miscellaneousField5: "",
    miscellaneousField6: "",
    miscellaneousNumeric1: null,
    miscellaneousNumeric2: null,
    miscellaneousNumeric3: null,
    miscellaneousNumeric4: null,
    miscellaneousNumeric5: null,
    miscellaneousNumeric6: null,
  };

  if (planState.factorTemplate.actNoIsAutoCalculated) {
    setActivityNumber(
      newLineItem,
      planState.factorTemplate.activityNumberComponents,
      planState.jobNumber,
      planState.system,
      planState.unit
    );
  }

  if (planState.miscFieldColumn && !isHeader) {
    setMiscFieldValue(
      planState.miscFieldColumn,
      planState.miscFieldValue,
      newLineItem
    );
  }

  addDefaultValues(
    defaultValues,
    newLineItem,
    planState.factorTemplate.actNoIsAutoCalculated
  );

  return { ...newLineItem, previousValues: previousValues };
};

export function makeHeader(row: PlanLineItemState) {
  const headerLine = {
    ...row,
    duration: 0,
    numberOfMen: 0,
    drawingNumber: "",
    isHeader: true,
    quantity: 0,
    abbreviationId: 0,
    equipmentNumber: "",
    area: "",
    abbr: "",
    resource: "",
    modified: true,
    lastModified: Date.now(),
  } as PlanLineItemState;

  return headerLine;
}

export function nextSequence(planData?: PlanLineItemState[]): string {
  const data =
    planData ??
    (store.getState().planData.planData as Readonly<PlanLineItemState[]>);
  const maxSequence = _.maxBy(data, x => Number(x.sequence)); // need to use regex to trim preceeding 0's
  const sequenceAsNumber = _.isNil(maxSequence)
    ? 0
    : Number(maxSequence.sequence); // need to use regex to trim preceeding 0's
  return String(sequenceAsNumber + 10).padStart(4, "0");
}

export function isIssue(err: Issue | string[]): err is Issue {
  return (err as Issue).type !== undefined;
}

export const addDefaultValues = (
  defaultValues: DefaultValue[],
  row,
  actNoIsAutoCalculated: boolean
) => {
  _.forEach(defaultValues, x => {
    if (
      canDefault(
        columns.find(y => y.accessor === x.id),
        row.isHeader,
        actNoIsAutoCalculated
      )
    ) {
      row[x.id] = x.value;
    }
  });
};

export const addHardFactorCalculations = (
  hardFactors: HardFactorDto[],
  planData: ReadonlyArray<Readonly<PlanLineItemState>>
) => {
  const rows = planData.flatMap(x => x.subRows);
  _.forEach(hardFactors, x => {
    const matches = getMatchingRows(x, rows);
    x.rows = matches.length;
    const hours = matches
      .map(x => x.baseHours)
      .reduce((partial_sum, a) => partial_sum + a, 0);
    x.hoursBeforeBump = Number(hours.toFixed(2));
    x.hoursAfterBump = Number((hours * x.factor).toFixed(2));
  });

  return hardFactors;
};

export const setHardFactors = (
  line: PlanLineItemState,
  hardFactors: HardFactorDto[]
): PlanLineItemState => {
  line.hardFactors = [];
  _.forEach(hardFactors, hf => {
    if (isAMatch(line, hf)) {
      line.hardFactors.push(hf);
    }
  });

  return line;
};

const getMatchingRows = (
  hardFactor: HardFactorDto,
  rows: PlanLineItemState[]
): PlanLineItemState[] => {
  return rows.filter(x => isAMatch(x, hardFactor));
};

const isAMatch = (
  line: PlanLineItemState,
  hardFactor: HardFactorDto
): boolean => {
  if (
    _.isEmpty(hardFactor.abbr) &&
    _.isEmpty(hardFactor.actNo) &&
    _.isEmpty(hardFactor.company) &&
    _.isEmpty(hardFactor.resource) &&
    _.isEmpty(hardFactor.sequence) &&
    _.isEmpty(hardFactor.time) &&
    _.isEmpty(hardFactor.miscellaneousField1) &&
    _.isEmpty(hardFactor.miscellaneousField2) &&
    _.isEmpty(hardFactor.miscellaneousField3)
  ) {
    return false;
  } else {
    return (
      (!_.isEmpty(hardFactor.abbr) ? line.abbr === hardFactor.abbr : true) &&
      (!_.isEmpty(hardFactor.actNo) ? line.actNo === hardFactor.actNo : true) &&
      (!_.isEmpty(hardFactor.company)
        ? line.company === hardFactor.company
        : true) &&
      (!_.isEmpty(hardFactor.resource)
        ? line.resource === hardFactor.resource
        : true) &&
      (!_.isEmpty(hardFactor.sequence)
        ? line.sequence === hardFactor.sequence
        : true) &&
      (!_.isEmpty(hardFactor.time) ? line.time === hardFactor.time : true) &&
      (!_.isEmpty(hardFactor.miscellaneousField1)
        ? line.miscellaneousField1 === hardFactor.miscellaneousField1
        : true) &&
      (!_.isEmpty(hardFactor.miscellaneousField2)
        ? line.miscellaneousField2 === hardFactor.miscellaneousField2
        : true) &&
      (!_.isEmpty(hardFactor.miscellaneousField3)
        ? line.miscellaneousField3 === hardFactor.miscellaneousField3
        : true)
    );
  }
};

export const mapLinkIndex = (field: string) =>
  field === Fields.MiscField1
    ? 0
    : field === Fields.MiscField2
    ? 1
    : field === Fields.MiscField3
    ? 2
    : field === Fields.MiscField4
    ? 3
    : field === Fields.MiscField5
    ? 4
    : field === Fields.MiscField6
    ? 5
    : -1;

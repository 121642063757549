import React, { useState, useEffect } from "react";
import { Input, Button, Modal, Form, Select } from "semantic-ui-react";
import AppContext from "../../../app/AppContext";
import { SiteGetDto } from "../../../models";
import { toast } from "react-toastify";
import { EditSiteDispatch, getUsers } from "../actions";
import _ from "lodash";

type Props = {
  open: boolean;
  onClose: () => void;
  site: SiteGetDto;
  sites: SiteGetDto[];
  editSite: EditSiteDispatch;
  onSuccess: () => void;
};

const convertToDropdownValue = (items: any[], valueKey: string | number) => {
  return items.map(x => x[valueKey]);
};

function EditSiteModal(props: Props) {
  const { api } = React.useContext(AppContext);
  const currentSiteAdmins = convertToDropdownValue(
    props.site?.siteAdmins ?? [],
    "id"
  ) as number[];

  const [siteName, setSiteName] = useState(null);
  const [users, setUsers] = useState<any[]>();
  const [selectedAdmins, setSelectedAdmins] = useState<number[]>(
    currentSiteAdmins
  );

  useEffect(() => {
    const admins = convertToDropdownValue(props.site?.siteAdmins ?? [], "id");
    setSelectedAdmins(admins);
  }, [props.site]);

  useEffect(() => {
    setSiteName(props.site?.name);
  }, [props.site]);

  useEffect(() => {
    getUsers(api)()
      .then(x =>
        setUsers(
          x.result.map(x => {
            return { text: `${x.name} (${x.emailAddress})`, value: x.id };
          })
        )
      )
      .catch();
  }, [api]);

  const hasChanges =
    (siteName && props.site?.name !== siteName) ||
    !_.isEqual(selectedAdmins, currentSiteAdmins);

  const _handleSubmit = () => {
    if (hasChanges) {
      props
        .editSite(
          { name: siteName, siteAdmins: selectedAdmins },
          props.site.id,
          props.sites,
          api
        )
        .then(resp => {
          if (resp.hasErrors) {
            toast(resp.errors.map(x => x.errorMessage).join(" "), {
              autoClose: false,
              position: "top-center",
            });
          } else {
            toast.success("Site changes applied");
            props.onSuccess();
          }

          props.onClose();
        })
        .catch(x => props.onClose()); //TODO: do stuff with promise;
    }
  };

  return (
    <Modal
      onClose={props.onClose}
      size="tiny"
      open={props.open}
      closeOnDimmerClick={false}
    >
      <Modal.Header content={`Edit Site ${props.site?.name}`} />
      <Modal.Content>
        <Form onSubmit={() => _handleSubmit()}>
          <Form.Field
            control={Input}
            id="site-name"
            label="Site Name"
            onChange={(e, { value }) => setSiteName(value)}
            value={siteName}
          />
          <Form.Field
            multiple
            clearable
            control={Select}
            label={{ children: "Site Administrators", htmlFor: "add-admin" }}
            onChange={(e, { value }) => setSelectedAdmins(value)}
            options={users}
            placeholder="Select User"
            search
            value={selectedAdmins}
            searchInput={{ id: "add-admin" }}
            selection
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={props.onClose} secondary />
        <Button
          content="Save"
          disabled={!hasChanges}
          icon="save"
          primary
          onClick={_handleSubmit}
        />
      </Modal.Actions>
    </Modal>
  );
}

export default EditSiteModal;

import { PropertyError } from "../models";
import { id } from "./util";
import _ from "lodash";

type Token = { type: string; value: any };

type ParseResult = { accessorName: string; tokens: Token[] };

const _errorPropDelims = /([[\].])/g;

export function parseErrorProp(propName: string): ParseResult {
  const lexemes = propName.split(_errorPropDelims);
  const stack = [];
  const result = [];

  for (const lex of lexemes) {
    if (lex === "") continue;
    switch (lex) {
      case "[":
        stack.push("[");
        break;
      case "]":
        stack.pop();
        break;
      case ".":
        stack.push(".");
        break;
      default:
        const last = stack.length > 0 ? stack[stack.length - 1] : null;
        if (last === "[") {
          const idx = Number(lex);
          if (_.isNaN(idx)) throw Error("Unsupported indexer");
          result.push({ type: "[]", value: idx });
        } else if (last === ".") {
          const lastProp = _.findLast(result, x => x.type === "prop");
          lastProp.type = ".";
          result.push({ type: "prop", value: lex });
        } else {
          result.push({ type: "prop", value: lex });
        }
        break;
    }
  }

  let accessorName = "";
  for (const tok of result) {
    switch (tok.type) {
      case ".":
        accessorName += tok.value;
        break;
      case "[]":
        accessorName += `[${tok.value}]`;
        break;
      default:
        break;
    }
  }

  return { accessorName, tokens: result };
}

function makeAccessor<T>(
  tokens: Token[],
  data: T | T[]
): (data: T | T[]) => any {
  const [tok, ...rest] = tokens;
  switch (tok?.type) {
    case "[]":
      return (data: T[]) => {
        const dataA = data[tok.value as number];
        return makeAccessor(rest, dataA)(dataA);
      };
    case ".":
      const dataKey = Object.keys(data).find(
        x =>
          x.localeCompare(tok.value, undefined, { sensitivity: "base" }) === 0
      );
      return (data: T) => {
        const dataA = data[dataKey];
        return makeAccessor(rest, dataA)(dataA);
      };
    default:
      return id;
  }
}

/*
 * Given a list of api errors, construct an `ErrorMap` for all errors sharing a common key and
 * return an accessor function that returns the source object of error.
 *
 * For example: given a structure like `foo = { lines: { name, ...}[] }` and a error property `Lines[0].Name`,
 * the accessor would return `foo.lines[0]` and the errors would be all the errors with the key `Lines[0]`.
 */
export function makeErrorMaps<T>(errors: PropertyError[], data: T) {
  return _.chain(errors)
    .map(err => {
      if (!_.isEmpty(err.propertyName)) {
        const result = parseErrorProp(err.propertyName);
        return { error: err, tokens: result.tokens, name: result.accessorName };
      } else {
        return { error: err, tokens: [], name: "*null*" };
      }
    })
    .groupBy(x => x.name)
    .map((vals, key) => {
      if (key === "*null*") {
        return {
          type: "array",
          errors: vals.map(x => x.error.errorMessage),
          key: null,
          accessor: id,
        };
      }

      const accessor = makeAccessor(vals[0].tokens, data);
      const errorMap = vals.reduce((accum, curr) => {
        const propName = curr.tokens.pop().value;
        accum[propName] = curr.error.errorMessage;
        return accum;
      }, {});
      return { type: "map", errors: errorMap, accessor, key };
    })
    .value();
}

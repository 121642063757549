import {
  ActionTypes,
  GET_DATA_TEMPLATES,
  DATA_TEMPLATES_DROPDOWN,
} from "./actions";
import { DataTemplate, DataTemplateDropdownOption } from "../../models";

type InitialState = {
  templates: DataTemplate;
  dropdownOptions: Array<DataTemplateDropdownOption>;
};

const initialState: InitialState = {
  templates: null as DataTemplate,
  dropdownOptions: [],
};

const reducer = (state = initialState, action: ActionTypes): InitialState => {
  switch (action.type) {
    case GET_DATA_TEMPLATES:
      return {
        ...state,
        templates: action.templates,
      };
    case DATA_TEMPLATES_DROPDOWN:
      return {
        ...state,
        dropdownOptions: action.options,
      };
    default:
      return state;
  }
};

export default reducer;

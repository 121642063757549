import React, { useState, useEffect } from "react";
import { Table, Button, Popup, Label } from "semantic-ui-react";
import UserDropdown from "../components/UserDropdown";
import {
  UserGetDto,
  GetSiteDto,
  UserGetDtoWithPlans,
  User,
} from "../../models";
import styled from "@emotion/styled";
import PageContent from "../components/PageContent";
import { AppState } from "../../app/AppState";
import { connect } from "react-redux";
import {
  getSiteDetails,
  addSiteUser,
  removeSiteUser,
  AddSiteUserDispatch,
  GetSiteDetailsDispatch,
  RemoveSiteUserDispatch,
} from "./actions";
import AppContext from "../../app/AppContext";
import ManageUserPlansModal from "./modals/ManageUserPlansModal";
import ManagePreferredEmailModal from "./modals/ManagePreferredEmailModal";
import ManageSiteTemplatesModal from "./modals/ManageSiteTemplatesModal";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../util";
import _ from "lodash";
import { Claims, UserHasClaim } from "../../util/security/security";

const StyledRoot = styled(PageContent)`
  .editable-field {
    margin-right: 6px;
  }

  .user-dropdown-container {
    width: 450px;
    height: 30px;
    padding: 0px 15px;

    .ui.dropdown {
      height: 20px;
      line-height: 13px;
      font-size: smaller;
    }
  }

  .hoverable {
    cursor: pointer;

    :hover {
      background-color: #d6d6d6;
    }
  }
`;

type Props = {
  selectedSite: GetSiteDto;
  users: UserGetDtoWithPlans[];
  user: User;
} & DispatchProps;

function SiteAdmin(props: Props) {
  const { api } = React.useContext(AppContext);
  const { getSiteDetails, selectedSite, users } = props;

  const [selectedUser, setSelectedUser] = useState<UserGetDto>(null);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);
  const [plansModalOpen, setPlansModalOpen] = useState(false);
  const [manageEmailModalOpen, setManageEmailModalOpen] = useState(false);
  const [selectedTableUser, setSelectedTableUser] = useState(null);

  const selectedTableUserId = selectedTableUser?.id;

  useEffect(() => {
    if (!!props.users && !!selectedTableUserId) {
      let user = props.users.find(x => x.id === selectedTableUserId);
      setSelectedTableUser(user);
    }
  }, [props.users, selectedTableUserId]);

  useEffect(() => {
    getSiteDetails(api, selectedSite.id);
  }, [api, getSiteDetails, selectedSite.id]);

  const _handleUserSelection = (user: UserGetDto) => {
    let userOrNull = !user ? null : user;
    setSelectedUser(userOrNull);
  };

  const _showModal = (
    setState: React.Dispatch<React.SetStateAction<boolean>>,
    tableUser: any
  ) => {
    setSelectedTableUser(tableUser);
    setState(true);
  };

  const _closeModal = (
    setState: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setSelectedTableUser(null);
    setState(false);
  };

  const _handleRemoveUser = async (user: UserGetDtoWithPlans) => {
    let result = await props.removeSiteUser(api, props.selectedSite.id, user);

    if (result.hasErrors) {
      toast.error(getErrorMessage(result.errors));
    }
  };

  const _handleAddUser = async (user: UserGetDto) => {
    let result = await props.addSiteUser(api, props.selectedSite.id, user);

    if (result.hasErrors) {
      toast.error(getErrorMessage(result.errors));
    }

    setSelectedUser(null);
  };

  const _employeeTableFilter = (user: UserGetDto) =>
    !props.users?.some(x => x.id === user.id);

  const transformedUsers = users?.map(x => ({
    ...x,
    isAdmin: x.sites[0].isAdmin,
  }));

  return (
    <StyledRoot
      actions={
        <>
          <div className="user-dropdown-container">
            <UserDropdown
              filter={_employeeTableFilter}
              onChange={_handleUserSelection}
            />
          </div>

          <Button
            disabled={!selectedUser}
            content="Add User to Site"
            onClick={() => _handleAddUser(selectedUser)}
            primary
            size="tiny"
            icon="add"
          />
        </>
      }
      title="Site Administration"
    >
      <Table padded singleLine striped unstackable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="1">User</Table.HeaderCell>

            <Table.HeaderCell colSpan="1">Preferred Email</Table.HeaderCell>

            <Table.HeaderCell colSpan="1">Templates</Table.HeaderCell>

            <Table.HeaderCell colSpan="1">Plans</Table.HeaderCell>

            <Table.HeaderCell collapsing />
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {_.orderBy(
            transformedUsers,
            ["isAdmin", "name"],
            ["desc", "asc"]
          )?.map(x => (
            <Table.Row key={x?.id}>
              <Table.Cell>
                {x.isAdmin ? (
                  <Label ribbon color="green" size="medium">
                    Admin
                  </Label>
                ) : null}
                <span>{x?.name}</span>
              </Table.Cell>

              <Table.Cell>
                <span className="editable-field">
                  {!!x?.preferredEmail ? x?.preferredEmail : x?.emailAddress}
                </span>

                <Button
                  className="edit-button"
                  basic
                  secondary
                  icon="edit"
                  onClick={() => _showModal(setManageEmailModalOpen, x)}
                  size="mini"
                  title="Edit user preferred email"
                />
              </Table.Cell>

              <Table.Cell>
                <span className="editable-field">
                  {x?.factorTemplates?.length}
                </span>

                <Button
                  className="edit-button"
                  basic
                  secondary
                  onClick={() => _showModal(setTemplateModalOpen, x)}
                  size="mini"
                  icon="edit"
                  title="Edit Templates"
                />
              </Table.Cell>

              <Table.Cell>
                <span className="editable-field">{x?.plans.length}</span>

                <Button
                  className="edit-button"
                  basic
                  secondary
                  onClick={() => _showModal(setPlansModalOpen, x)}
                  size="mini"
                  icon="edit"
                  title="Edit Plans"
                />
              </Table.Cell>

              <Table.Cell collapsing>
                <Popup
                  basic
                  secondary
                  content={
                    x.isAdmin &&
                    !UserHasClaim(props.user, Claims.CorporateAdminClaim)
                      ? "You may not remove site admins"
                      : "Remove user from site"
                  }
                  trigger={
                    <span>
                      <Button
                        negative
                        icon="remove"
                        onClick={() => _handleRemoveUser(x)}
                        disabled={
                          x.isAdmin &&
                          !UserHasClaim(props.user, Claims.CorporateAdminClaim)
                        }
                      />
                    </span>
                  }
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {!!props.users ? (
        <>
          <ManageUserPlansModal
            open={plansModalOpen}
            onClose={() => _closeModal(setPlansModalOpen)}
            user={selectedTableUser}
          />

          <ManagePreferredEmailModal
            open={manageEmailModalOpen}
            siteId={props.selectedSite.id}
            onClose={() => _closeModal(setManageEmailModalOpen)}
            user={selectedTableUser}
          />

          <ManageSiteTemplatesModal
            open={templateModalOpen}
            onClose={() => _closeModal(setTemplateModalOpen)}
            user={selectedTableUser}
          />
        </>
      ) : null}
    </StyledRoot>
  );
}

const mapStateToProps = (state: AppState) => ({
  selectedSite: state.me.selectedSite,
  users: state.siteAdmin.siteDetails?.users.map(x => {
    return {
      ...x,
      plans: state.siteAdmin.siteDetails?.plans.filter(y => y.userId === x.id),
    };
  }),
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    getSiteDetails: getSiteDetails(dispatch),
    addSiteUser: addSiteUser(dispatch),
    removeSiteUser: removeSiteUser(dispatch),
  };
};

interface DispatchProps {
  getSiteDetails: GetSiteDetailsDispatch;
  addSiteUser: AddSiteUserDispatch;
  removeSiteUser: RemoveSiteUserDispatch;
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteAdmin);

import { lighten } from "polished";

export const colors = {
  basicBoxShadow: "rgba(34, 36, 38, 0.15)",
  black: "#1B1C1D",
  contentBackground: "#ebebeb",
  secondaryContentBackground: "#dfe7ed",
  errorText: "#9F3A38",
  greenDark: "#005841",
  greenLight: lighten(0.1, "#005841"),
  lightBlack: lighten(0.2, "#1B1C1D"),
  lightWarning: lighten(0.5, "#712928"),
  secondaryBackground: "#dfece9",
  secondaryBackgroundActive: "#66998c",
  secondaryBackgroundFocus: "#a3c2ba",
  secondaryBackgroundHover: "#c2d6d1",
  secondaryColor: "#004d38",
  secondaryColorFocus: "#141f1c",
  secondaryColorHover: "#141f1c",
  tableBackground: "#444",
  textLabel: "#444",
  transparent: "rgba(0,0,0,0)",
  transparentHover: "#efefef",
  warning: "#712928",
  warningBackground: "#fff6f6",
  warningBoxShadow: "#9F3A38",
  warningIconColor: "#FFBB33",
  white: "#fff",
};

import React, { useState, useEffect } from "react";
import { Input, Button, Form, Modal } from "semantic-ui-react";
import { Api } from "../../../../util/api/api";
import { saveColumnDefault } from "../../../me/plans/actions";

type Props = {
  onClose: any;
  open: any;
  column: any;
  api: Api;
  planId: number;
};

export function DefaultValueModal(props: Props) {
  const [proposedValue, setProposedValue] = useState(
    props.column?.defaultValue ?? ""
  );
  const [usePreviousValue, setUsePreviousValue] = useState(
    props.column?.copyPrevValueEnabled
  );

  //update based on props passed in
  useEffect(() => setProposedValue(props.column?.defaultValue), [props.column]);
  useEffect(() => setUsePreviousValue(props.column?.copyPrevValueEnabled), [
    props.column,
  ]);

  const _handleUpdate = React.useCallback(() => {
    props.column?.setDefaultValue(proposedValue);
    props.column?.addCopyPreviousValue({
      colAccessor: props.column?.id,
      prevValue: props.column?.prevValue,
      enabled: usePreviousValue,
    });

    saveColumnDefault(
      {
        columnName: props.column?.id,
        defaultValue: proposedValue,
        isDuplicatingPrevious: usePreviousValue,
        planId: props.planId,
      },
      props.api
    );

    props.onClose();
  }, [proposedValue, props, usePreviousValue]);

  return (
    <Modal
      size="tiny"
      open={props.open}
      onClose={props.onClose}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Set Default Column Value</Modal.Header>
      <Modal.Content>
        <Form>
          <Form.Field
            id="default-value-input"
            label="Column Default Value"
            placeholder="Enter a value"
            control={Input}
            value={proposedValue}
            onChange={(e, { value }) => setProposedValue(value)}
          ></Form.Field>
          <Form.Checkbox
            onClick={() => setUsePreviousValue(!usePreviousValue)}
            checked={usePreviousValue}
            label="Copy down previous value?"
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={props.onClose} secondary />
        <Button
          content="Apply"
          icon="checkmark"
          onClick={_handleUpdate}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

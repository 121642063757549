import { applyMiddleware, compose, createStore } from "redux";
//import { setup } from "actionware";
import allReducers from "./rootReducer";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["me"],
};

const persistedReducer = persistReducer(persistConfig, allReducers);

function finalCreateStore() {
  const composeEnhancers =
    typeof window === "object" &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(applyMiddleware());

  const store = createStore(persistedReducer, enhancer);

  const persistor = persistStore(store);

  //setup({ store });
  return { store, persistor };
}

export const { store, persistor } = finalCreateStore();

import { UserClaim } from "../../models";

type UserWithClaim = {
  claims: UserClaim[];
};

export const UserHasClaims = (
  user: UserWithClaim,
  requiredClaims: UserClaim[]
) => {
  if (!!user.claims) {
    let claimValues = user.claims.map((x) => x.value);
    return requiredClaims.every((x) => claimValues.includes(x.value));
  }

  return false;
};

export const UserHasClaim = (user: UserWithClaim, requiredClaim: UserClaim) =>
  UserHasClaims(user, [requiredClaim]);

export const Claims = {
  UserClaim: {
    type: "Claim.Role",
    value: "QuickPlan_User",
  },
  AdminClaim: {
    type: "Claim.Role",
    value: "QuickPlan_SuperAdmin",
  },
  CorporateAdminClaim: {
    type: "Claim.Role",
    value: "QuickPlan_Admin",
  },
};

import { HardFactorDto } from "../../../models";

export interface HardFactorColumn {
  Header: string;
  accessor?: keyof HardFactorDto;
  order: number;
  pinned: boolean;
  readonly: boolean;
}

export const hardFactorColumns: HardFactorColumn[] = [
  {
    Header: "Reason For Hard Factor",
    accessor: "description",
    order: 0,
    pinned: true,
    readonly: false,
  },
  {
    Header: "Abbr",
    accessor: "abbr",
    order: 1,
    pinned: false,
    readonly: false,
  },
  {
    Header: "ActNo",
    accessor: "actNo",
    order: 2,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Company",
    accessor: "company",
    order: 3,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Resource",
    accessor: "resource",
    order: 4,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Sequence",
    accessor: "sequence",
    order: 5,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Phase",
    accessor: "time",
    order: 6,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Misc1",
    accessor: "miscellaneousField1",
    order: 7,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Misc2",
    accessor: "miscellaneousField2",
    order: 8,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Misc3",
    accessor: "miscellaneousField3",
    order: 9,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Factor",
    accessor: "factor",
    order: 10,
    pinned: false,
    readonly: false,
  },
  {
    Header: "Rows",
    accessor: "rows",
    order: 11,
    pinned: false,
    readonly: true,
  },
  {
    Header: "Hours Before Bump",
    accessor: "hoursBeforeBump",
    order: 12,
    pinned: false,
    readonly: true,
  },
  {
    Header: "After Bump",
    accessor: "hoursAfterBump",
    order: 13,
    pinned: false,
    readonly: true,
  },
  {
    Header: "Difference",
    order: 14,
    pinned: false,
    readonly: true,
  },
];

import React from "react";
import _ from "lodash";
import {
  FactorTemplateWithRates,
  Resource,
  KeyedLookup,
  FactorTemplateResourceRate,
  FactorTemplateResourceRateChange,
} from "../../../models";
import { Input, Table } from "semantic-ui-react";
import { isEmpty, getByKey, parseFloatP } from "../../../util";
import { UpdateFactorRateDispatch } from "./actions";

type Props = {
  resources: KeyedLookup<Resource>;
  factorTemplate: FactorTemplateWithRates;
  updateRate: UpdateFactorRateDispatch;
};

type ColumnKeys = keyof FactorTemplateResourceRate | keyof Resource | "";
type SortInfo = {
  column: ColumnKeys;
  direction: "ascending" | "descending";
};

export default function TemplateFactorGrid(props: Props) {
  const [{ column, direction }, setSortInfo] = React.useState<SortInfo>({
    column: "",
    direction: "ascending",
  });

  const _sort = (col: ColumnKeys) => (
    item: FactorTemplateResourceRateChange
  ) => {
    if (["abbreviation", "description"].includes(col)) {
      return getByKey(props.resources, item.resourceId)[col];
    } else {
      return item[col];
    }
  };

  const _handleSort = (clickedColumn: ColumnKeys) => () => {
    if (clickedColumn !== column) {
      setSortInfo({
        column: clickedColumn,
        direction: "ascending",
      });
    } else {
      setSortInfo({
        column,
        direction: direction === "ascending" ? "descending" : "ascending",
      });
    }
  };

  let data = _.sortBy(props.factorTemplate?.resourceRates, _sort(column));
  data = direction === "descending" ? data.reverse() : data;

  return (
    <Table fixed singleLine striped sortable unstackable>
      <Table.Header className="sticky">
        <Table.Row>
          <Table.HeaderCell className="sticky">Factor</Table.HeaderCell>
          <Table.HeaderCell
            className="sticky"
            onClick={_handleSort("description")}
            sorted={column === "description" ? direction : null}
          >
            Resource Description
          </Table.HeaderCell>
          <Table.HeaderCell
            className="sticky"
            onClick={_handleSort("abbreviation")}
            sorted={column === "abbreviation" ? direction : null}
          >
            Resource Abbr
          </Table.HeaderCell>
          <Table.HeaderCell
            className="sticky"
            onClick={_handleSort("rate")}
            sorted={column === "rate" ? direction : null}
          >
            Rate
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {!isEmpty(props.resources) &&
          data?.map(x => (
            <Table.Row key={`${x.factorTemplateId}_${x.resourceId}`}>
              <Table.Cell>
                {props.factorTemplate.factorGlobalAdjustment}
              </Table.Cell>
              <Table.Cell>
                {getByKey(props.resources, x.resourceId).description}
              </Table.Cell>
              <Table.Cell>
                {getByKey(props.resources, x.resourceId).abbreviation}
              </Table.Cell>
              <Table.Cell>
                <Input
                  type="number"
                  value={x.newVal ?? x.rate}
                  onChange={(e, { value }) => {
                    let rate = parseFloatP(value, 4) ?? x.rate;
                    if (rate < 0) rate = 0;
                    props.updateRate({
                      ...x,
                      newVal: rate,
                    });
                  }}
                  onBlur={evt => {
                    let rate = parseFloatP(evt.target.value, 4) ?? x.rate;
                    if (rate < 0) rate = 0;
                    props.updateRate({
                      ...x,
                      newVal: rate,
                    });
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
      </Table.Body>
    </Table>
  );
}

React.memo(function What() {
  return <div></div>;
});

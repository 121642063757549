import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

import Login from "./Login";

const Routes = () => (
  <Switch>
    <Route exact path={"/auth/login"} component={Login} />
    <Redirect to={{ pathname: "/auth/login", state: { from: "/" } }} />
  </Switch>
);

export default Routes;

import React from "react";
import { Button, Form, Message, Modal } from "semantic-ui-react";
import { FactorTemplateWithRates, GetSiteDto } from "../../../models";
import { Api } from "../../../util/api/api";
import {
  UpdateFactorTemplateDispatch,
  AddFactorTemplateDispatch,
} from "./actions";
import { parseFloatP } from "../../../util";

export type ModalProps = {
  title: string;
  api: Api;
  open: boolean;
  site: GetSiteDto;
  mustBeDefault: boolean;
  template: FactorTemplateWithRates;
  onClose: () => void;
  saveFactorTemplate: AddFactorTemplateDispatch | UpdateFactorTemplateDispatch;
};

export function TemplateModal(props: ModalProps) {
  const [errors, setErrors] = React.useState<string[]>([]);
  const [factorTpl, setFactorTpl] = React.useState<FactorTemplateWithRates>(
    props.template
  );

  const _handleCancel = () => {
    props.onClose();
    setErrors([]);
    setFactorTpl(props.template);
  };

  React.useEffect(() => {
    setFactorTpl(props.template);
  }, [props.template]);

  const _handleSubmit = () => {
    props
      .saveFactorTemplate(props.api, factorTpl)
      .then(_ => props.onClose())
      .catch(response => {
        setErrors(
          response.errors?.map(x => x.errorMessage) || [response.message]
        );
      });
  };
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header content={props.title} />
      <Modal.Content>
        <Message hidden={errors.length === 0} error list={errors} />
        <Form>
          <Form.Field required>
            <label htmlFor="tpl-name">Template Name</label>
            <Form.Input
              id="tpl-name"
              required
              onChange={(e, { value }) =>
                setFactorTpl({ ...factorTpl, name: value })
              }
              value={factorTpl.name}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="rate">Default Recharge Rate (in dollars $)</label>
            <Form.Input
              id="rate"
              type="number"
              value={factorTpl.defaultRate}
              onChange={(_, data) => {
                const num = parseFloatP(data.value);
                setFactorTpl({
                  ...factorTpl,
                  defaultRate: num,
                });
              }}
              onBlur={evt => {
                let num =
                  parseFloatP(evt.target.value, 4) ??
                  props.template.defaultRate;
                if (num < 0) num = 0;
                setFactorTpl({
                  ...factorTpl,
                  defaultRate: num,
                });
              }}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="factor">
              Multiply All Factors By (1.00 = Page)
            </label>
            <Form.Input
              id="factor"
              type="number"
              onChange={(_, data) => {
                const num = parseFloatP(data.value);
                setFactorTpl({
                  ...factorTpl,
                  factorGlobalAdjustment: num,
                });
              }}
              onBlur={evt => {
                let num =
                  parseFloatP(evt.target.value, 4) ??
                  props.template.factorGlobalAdjustment;
                if (num < 0) num = 0;
                setFactorTpl({
                  ...factorTpl,
                  factorGlobalAdjustment: num,
                });
              }}
              value={factorTpl.factorGlobalAdjustment}
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="auto-calc">Auto Calculate Activity Number</label>
            <Form.Checkbox
              id="auto-calc"
              defaultChecked={factorTpl.actNoIsAutoCalculated}
              onChange={(_, data) =>
                setFactorTpl({
                  ...factorTpl,
                  actNoIsAutoCalculated: data.checked,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label htmlFor="default-tpl">Default Template</label>
            <Form.Checkbox
              id="default-tpl"
              checked={factorTpl.isDefaultTemplate}
              disabled={props.mustBeDefault}
              onChange={(_, data) =>
                setFactorTpl({
                  ...factorTpl,
                  isDefaultTemplate: data.checked,
                })
              }
            />
          </Form.Field>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={_handleCancel} secondary />
        <Button
          content="Save"
          icon="save"
          onClick={_ => _handleSubmit()}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

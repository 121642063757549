import { PlanLineItemState, ErrorMap } from "../../models";
import _ from "lodash";

export const invalidUserAbbr = (item: PlanLineItemState) => {
  return !item.isHeader && _.isEmpty(item.abbr) && item.user === 0;
};

export const invalidQuantity = (item: PlanLineItemState) => {
  return !item.isHeader && item.quantity === 0;
};

export const invalidResource = (item: PlanLineItemState) => {
  return !item.isHeader && _.isEmpty(item.resource);
};

type ItemValidator = (item: PlanLineItemState) => boolean;

type PlanLineItemStateError = PlanLineItemState & {
  parent: PlanLineItemState;
};

const validators: ItemValidator[] = [
  invalidUserAbbr,
  invalidQuantity,
  invalidResource,
];

export const validatePlan = (
  rows: ReadonlyArray<Readonly<PlanLineItemState>>
): PlanLineItemStateError[] => {
  const invalidLines = rows
    .flatMap(x => {
      return x.subRows.map(y => {
        return { ...y, parent: x };
      });
    })
    .filter(x => validators.some(v => v(x)))
    .map(item => {
      let errorMap: ErrorMap<PlanLineItemState> = {};
      if (invalidUserAbbr(item)) {
        errorMap.user = errorMap.abbr = {
          type: "export",
          description: "You must enter either User's Factor or Abbreviation",
        };
      }

      if (invalidQuantity(item)) {
        errorMap.quantity = {
          type: "export",
          description: "Quantity cannot be 0",
        };
      }

      if (invalidResource(item)) {
        errorMap.resource = {
          type: "export",
          description: "Resource cannot be blank",
        };
      }

      return { ...item, errors: errorMap };
    });

  return invalidLines;
};

import { LOGGED_OUT_SITE, SELECT_SITE } from "../me/actions";
import {
  ActionTypes,
  GET_PLANNING_PREMISE,
  LOADING_PLANNING_PREMISE,
  LOADING_PLANNING_PREMISE_FINISHED,
  PlanningPremiseDto,
  UPDATE_PLANNING_PREMISE,
} from "./actions";

type InitialState = {
  premise: PlanningPremiseDto;
  isLoading: boolean;
};

const initialState: InitialState = {
  premise: null as PlanningPremiseDto,
  isLoading: false,
};

const reducer = (state = initialState, action: ActionTypes): InitialState => {
  switch (action.type) {
    case GET_PLANNING_PREMISE:
      return {
        ...state,
        premise: action.payload,
        isLoading: false,
      };
    case UPDATE_PLANNING_PREMISE:
      return {
        ...state,
        premise: action.payload,
        isLoading: false,
      };
    case LOADING_PLANNING_PREMISE:
      return {
        ...state,
        isLoading: true,
      };
    case LOADING_PLANNING_PREMISE_FINISHED:
      return {
        ...state,
        isLoading: false,
      };
    case SELECT_SITE:
      return initialState;
    case LOGGED_OUT_SITE:
      return initialState;
    default:
      return state;
  }
};

export default reducer;

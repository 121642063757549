import React, { useEffect, useState } from "react";
import { ActivityNumberEntry, FactorTemplateWithRates } from "../../models";
import { SaveActNoEntriesDispatch } from "../sites/templates/actions";
import { Button, Form, Modal } from "semantic-ui-react";
import { activityNumberProperties } from "../../constants";
import { ActivityNumberTable } from "./ActivityNumberTable";
import AppContext from "../../app/AppContext";
import ResponseMessage from "../components/ResponseMessage";
import { toast } from "react-toastify";

type Props = {
  open: boolean;
  onClose: () => void;
  actNoEntries: ActivityNumberEntry[];
  saveAction: SaveActNoEntriesDispatch;
  selectedTemplate: FactorTemplateWithRates;
};

export function ActivityNumberModal(props: Props) {
  const { api } = React.useContext(AppContext);

  const [entries, setEntries] = useState<ActivityNumberEntry[]>(
    props.actNoEntries
  );
  const [dropdownSelection, setDropdownSelection] = useState("");

  useEffect(() => {
    setEntries(props.actNoEntries);
  }, [props.actNoEntries]);

  const _handleAddSelection = () => {
    if (dropdownSelection === "" || !dropdownSelection) {
      return;
    }

    const newArray = !entries ? new Array<ActivityNumberEntry>() : [...entries];

    newArray.push({
      id:
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15),
      order: newArray.length,
      prefix: "",
      suffix: "",
      property: dropdownSelection,
    });
    setEntries(newArray);
    setDropdownSelection("");
  };

  const _handlePropertyChange = (entry: ActivityNumberEntry) => {
    let index = entries.findIndex(e => e.id === entry.id);
    let arr = [...entries];
    arr.splice(index, 1, entry);
    setEntries(arr);
  };

  const _handleDelete = (entry: ActivityNumberEntry) => {
    let index = entries.findIndex(e => e.id === entry.id);
    let arr = [...entries];
    arr.splice(index, 1);
    setEntries(arr);
  };

  const _handleClose = () => {
    setEntries(props.actNoEntries);
    props.onClose();
  };

  const _handleSave = async () => {
    props
      .saveAction(api, entries, props.selectedTemplate)
      .then(_ => props.onClose())
      .catch(response => {
        toast.error(<ResponseMessage response={response} />);
      });
  };

  return (
    <Modal open={props.open} onClose={props.onClose} closeOnDimmerClick={false}>
      <Modal.Header content="Configure Activity Number" />
      <Modal.Content>
        <Form>
          <Form.Group inline>
            <Form.Select
              options={activityNumberProperties}
              placeholder="Select a property"
              selection
              onChange={(e, data) => setDropdownSelection(data.value as string)}
              value={dropdownSelection}
            />
            <Button
              disabled={!dropdownSelection}
              content="Add Selection"
              icon="add"
              onClick={_handleAddSelection}
              primary
            />
          </Form.Group>
        </Form>

        <ActivityNumberTable
          selections={entries}
          onChange={_handlePropertyChange}
          onDelete={_handleDelete}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={_handleClose} secondary />
        <Button
          content="Save Changes"
          icon="save"
          onClick={_handleSave}
          primary
        />
      </Modal.Actions>
    </Modal>
  );
}

import { Api } from "../../util/api/api";
import { Response } from "../../models";
import { LoggedOutSite, SelectSite } from "../me/actions";
import { updateColumnAliasPlugin } from "../me/plans/planGridColumns";

export const GET_PLANNING_PREMISE = "GET_PLANNING_PREMISE";
export const UPDATE_PLANNING_PREMISE = "UPDATE_PLANNING_PREMISE";
export const LOADING_PLANNING_PREMISE = "LOADING_PLANNING_PREMISE";
export const LOADING_PLANNING_PREMISE_FINISHED =
  "LOADING_PLANNING_PREMISE_FINISHED";
export const DOWNLOAD_PLANNING_PREMISE = "DOWNLOAD_PLANNING_PREMISE";

interface GetPlanningPremise {
  type: typeof GET_PLANNING_PREMISE;
  payload: PlanningPremiseDto; //update this
}

interface UpdatePlanningPremise {
  type: typeof UPDATE_PLANNING_PREMISE;
  payload: PlanningPremiseDto; //update this
}

interface DownloadPremise {
  type: typeof DOWNLOAD_PLANNING_PREMISE;
}

interface LoadingPlanningPremise {
  type: typeof LOADING_PLANNING_PREMISE;
}

interface LoadingPlanningPremiseFinished {
  type: typeof LOADING_PLANNING_PREMISE_FINISHED;
}

export const getPlanningPremise = dispatch => {
  return (siteId: number, api: Api) => {
    dispatch({ type: LOADING_PLANNING_PREMISE });
    return api
      .get<Response<PlanningPremiseDto>>(`sites/${siteId}/premise`)
      .then(response => {
        if (response.hasErrors) {
          return Promise.reject(getErrors(response));
        } else {
          dispatch({ type: GET_PLANNING_PREMISE, payload: response.result });
          updateColumnAliasPlugin(dispatch, response.result);
          return Promise.resolve(response);
        }
      })
      .finally(() => {
        dispatch({ type: LOADING_PLANNING_PREMISE_FINISHED });
      });
  };
};

export type GetPlanningPremiseDispatch = ReturnType<typeof getPlanningPremise>;

export const updatePlanningPremise = dispatch => {
  return (premise: PlanningPremiseDto, api: Api) => {
    dispatch({ type: LOADING_PLANNING_PREMISE });
    return api
      .put<Response<PlanningPremiseDto>>(
        `sites/${premise.siteId}/premise`,
        premise
      )
      .then(response => {
        if (response.hasErrors) {
          return Promise.reject(getErrors(response));
        } else {
          dispatch({ type: UPDATE_PLANNING_PREMISE, payload: response.result });
          updateColumnAliasPlugin(dispatch, response.result);
          return Promise.resolve(response);
        }
      })
      .finally(() => {
        dispatch({ type: LOADING_PLANNING_PREMISE_FINISHED });
      });
  };
};

export type UpdatePlanningPremiseDispatch = ReturnType<
  typeof updatePlanningPremise
>;

export const downloadPlanningPremise = dispatch => {
  return (siteId: number, api: Api) => {
    dispatch({ type: LOADING_PLANNING_PREMISE });
    return api.getStream(`sites/${siteId}/premise/download`, {}).finally(() => {
      dispatch({ type: LOADING_PLANNING_PREMISE_FINISHED });
    });
  };
};

export type DownloadPlanningPremiseDispatch = ReturnType<
  typeof downloadPlanningPremise
>;

const getErrors = (response): string => {
  return response.errors.map(x => x.errorMessage).join();
};

export type PlanningPremiseDto = {
  siteId: number;
  planningPremiseEntries: PlanningPremiseEntryDto[];
};

export type MiscFieldConfig = {
  id: number;
  alias: string;
  acceptedValues: string[];
  fieldType: number;
};

export type PlanningPremiseEntryDto = {
  id: number;
  category: string;
  abbreviation: string;
  value: string;
  description?: string;
  linkedField?: number;
  clientId?: string;
};

export type ActionTypes =
  | GetPlanningPremise
  | UpdatePlanningPremise
  | LoadingPlanningPremise
  | LoadingPlanningPremiseFinished
  | SelectSite
  | LoggedOutSite;

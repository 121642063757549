import React, { lazy, Suspense } from "react";
import "./App.css";
import { Switch, Route, Redirect, useLocation } from "react-router";
import ContentLoader from "../layout/ContentLoader";
import PrivateRoute from "../layout/PrivateRoute";
import { AppContextProvider } from "./AppContext";
import AuthRoutes from "../features/auth/Routes";
import { AdminRoutes, SiteAdminRoutes } from "../features/admin/Routes";
import Forbidden from "../util/security/Forbidden";
import { ToastContainer } from "react-toastify";
import { useDispatch } from "react-redux";
import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";


const MainRoutes = lazy(() => import("../layout/MainRoutes"));

function App() {
  const dispatch = useDispatch();

  const location = useLocation();
  console.log("Serving location", location.pathname);

  return (
    <React.Fragment>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH_DOMAIN}
        clientId={process.env.REACT_APP_AUTH_CLIENTID}
        authorizationParams={{
          redirect_uri: process.env.REACT_APP_AUTH_REDIRECT,
          audience: process.env.REACT_APP_AUTH_AUDIENCE,
          scope: process.env.REACT_APP_AUTH_SCOPE,
        }}
      >
        <AppContextProvider dispatch={dispatch}>
          <Suspense
            fallback={<ContentLoader error={false} pastDelay={false} />}
          >
            <Switch>
              <Route path="/auth" component={AuthRoutes} />
              <Route exact path="/forbidden" component={Forbidden} />
              <PrivateRoute
                path="/admin"
                component={AdminRoutes}
                requiredClaims={[]}
              />
              <PrivateRoute
                path="/sites/:siteId/admin"
                component={SiteAdminRoutes}
                requiredSiteAdmin={true}
              />
              <PrivateRoute path="/" component={MainRoutes} />
              <Redirect
                to={{
                  pathname: "/auth/login",
                  state: { from: location.pathname },
                }}
              />
            </Switch>
          </Suspense>
        </AppContextProvider>
      </Auth0Provider>
      <ToastContainer />
    </React.Fragment>
  );
}

export default App;

import { KeyedLookup, PropertyError } from "../models";
import _ from "lodash";
import { ColumnInfo } from "../features/components/grid";

export { makeErrorMaps } from "./errors";
export { id } from "./util";

export function getByKey<T>(map: KeyedLookup<T>, key: number): T {
  if (map instanceof Map) {
    return map.get(key);
  } else {
    return map[key];
  }
}

/* Check if an object is null, undefined, or {} */
export function isEmpty(obj) {
  return (
    obj === null ||
    obj === undefined ||
    (Object.keys(obj).length === 0 && obj.constructor === Object)
  );
}

/* Parse a float up to a given decimal precision */
export function parseFloatP(input: string, precision?: number): number | null {
  let result = Number.parseFloat(input);
  if (precision) {
    const n = Math.pow(10, precision);
    result = Math.floor(result * n) / n;
  }

  if (_.isNaN(result)) return null;

  return result;
}

export function getErrorMessage(
  errors: PropertyError[],
  joinStr: string = ". "
): string {
  return errors.map(x => x.errorMessage).join(joinStr);
}

export const columnSort = (x: ColumnInfo, y: ColumnInfo) =>
  !((x.pinned || y.pinned) && !(x.pinned && y.pinned)) // Logical XOR, when same default to normal sorting
    ? x.order - y.order
    : x.pinned // order the pinned column first
    ? -1
    : 1;

//https://stackoverflow.com/questions/105034/how-to-create-guid-uuid - stolen from here as a quick id generator
export const generateUUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

import React from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Form, Icon, Message } from "semantic-ui-react";

import { login, logout, loginSilent, State } from "./actions";
import AuthContainer from "./AuthContainer";
import { Auth } from "../../util/auth/client";
import { AppState } from "../../app/AppState";
import AppContext from "../../app/AppContext";
import _ from "lodash";

type OwnProps = State;

interface DispatchProps {
  login: (client: Auth) => void;
  loginSilent: (client: Auth) => void;
  logout: (client: Auth) => void;
}

type Props = OwnProps & DispatchProps;

type FromLocation = { from: string };

const redirect = (location, history) => {
  const redir = location?.from ?? "/";
  history.replace(redir.endsWith("/auth/login") ? "/" : redir);
};

export function Login(props: Props) {
  const { user, error, loggingIn, loginSilent } = props;

  const { auth } = React.useContext(AppContext);
  const history = useHistory();
  const location = useLocation<FromLocation>();

  React.useEffect(() => {
    if (!_.isEmpty(user)) {
      if (auth.validateAuthentication()) {
        redirect(location.state, history);
      } else {
        console.log(`Authentication failed for ${user}`);
      }
    } else if (auth.validateAuthentication()) {
      redirect(location.state, history);
    } else {
      loginSilent(auth);
    }
  }, [history, user, auth, loginSilent, location.state]);

  if (error) {
    return (
      <AuthContainer loading={loggingIn}>
        <Message negative>{error}</Message>
        <Button onClick={() => props.logout(auth)}>Log Out</Button>
      </AuthContainer>
    );
  }

  return (
    <AuthContainer loading={loggingIn}>
      <Form onSubmit={() => props.login(auth)}>
        <Button type="submit" primary fluid>
          <Icon name="external" />
          Log In
        </Button>
      </Form>
    </AuthContainer>
  );
}

const mapStateToProps = (state: AppState): OwnProps => ({
  user: state.auth.user,
  loggingIn: state.auth.loggingIn,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    login: login(dispatch),
    logout: logout(dispatch),
    loginSilent: loginSilent(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);

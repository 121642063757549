import React from "react";
import styled from "@emotion/styled/macro";
import { Header } from "semantic-ui-react";

const PageRoot = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 90%;
  padding: 2px 15%;

  .body {
    flex: 1;
  }

  .header {
    display: flex;
    width: 100%;
  }

  .actions {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .ui.header {
    padding: 10px 0px;
  }
`;

type Props = {
  actions?: any;
  className?: string;
  children: any;
  title: string;
};

function PageContent({
  actions = null,
  className = "",
  children,
  title,
}: Props) {
  return (
    <PageRoot className={className}>
      {(title || actions) && (
        <div className="header">
          {title && (
            <div>
              <Header as="h1">{title}</Header>
            </div>
          )}
          {actions && <div className="actions">{actions}</div>}
        </div>
      )}
      <div className="body">
        <div>{children}</div>
      </div>
    </PageRoot>
  );
}

export default PageContent;

import { Resource, Response } from "../../models";
import { Api } from "../../util/api/api";

export const LOAD_RESOURCES = "LOAD_RESOURCES";

interface LoadResources {
  type: typeof LOAD_RESOURCES;
  payload: Resource[];
}

export type Actions = LoadResources;

export const loadResources = dispatch => {
  return (api: Api) => {
    return api.get<Response<Resource[]>>("/resources", null).then(resp => {
      dispatch({ type: LOAD_RESOURCES, payload: resp.result });
    });
  };
};

import throttle from "lodash/throttle";

import _ from "lodash";

/*
 * Adapted from https://github.com/react-dnd/react-dnd/issues/553#issuecomment-553005670
 */

const OFFSET = 50;
const PX_DIFF = 3;

interface DragScrollOptions {
  offsetLeft?: number;
  offsetRight?: number;
  offsetTop?: number;
  offsetBottom?: number;
  disableVertical?: boolean;
  disableHorizontal?: boolean;
}

const defaultOpts: DragScrollOptions = {
  offsetTop: OFFSET,
  offsetBottom: OFFSET,
  offsetLeft: OFFSET,
  offsetRight: OFFSET,
  disableVertical: false,
  disableHorizontal: false,
};

export const useDragScroll = _.memoize(scrollAreaEl => {
  let scrollIncrementVert = 0;
  let scrollIncrementHoriz = 0;

  let ctx: any = {
    opts: {},
    isScrollingVert: false,
    isScrollingHoriz: false,
  };

  /**
   * Scroll up in the sidebar.
   */
  const goUp = () => {
    scrollIncrementVert -= PX_DIFF;
    scrollAreaEl.scrollTop = scrollIncrementVert;

    if (ctx.isScrollingVert && scrollIncrementVert >= 0) {
      window.requestAnimationFrame(goUp);
    }
  };

  /**
   * Scroll down in the sidebar.
   */
  const goDown = () => {
    scrollIncrementVert += PX_DIFF;
    scrollAreaEl.scrollTop = scrollIncrementVert;

    if (
      ctx.isScrollingVert &&
      scrollIncrementVert <= scrollAreaEl.scrollHeight
    ) {
      window.requestAnimationFrame(goDown);
    }
  };

  const goLeft = () => {
    scrollIncrementHoriz -= PX_DIFF;
    scrollAreaEl.scrollLeft = scrollIncrementHoriz;

    if (ctx.isScrollingHoriz && scrollIncrementHoriz >= 0) {
      window.requestAnimationFrame(goLeft);
    }
  };

  const goRight = () => {
    scrollIncrementHoriz += PX_DIFF;
    scrollAreaEl.scrollLeft = scrollIncrementHoriz;

    if (
      ctx.isScrollingHoriz &&
      scrollIncrementHoriz <= scrollAreaEl.scrollWidth
    ) {
      window.requestAnimationFrame(goRight);
    }
  };

  const onDragOver = event => {
    const clientRect = scrollAreaEl.getBoundingClientRect();

    const isMouseOnTop =
      scrollIncrementVert >= 0 &&
      event.clientY > clientRect.top &&
      event.clientY < clientRect.top + ctx.opts.offsetTop;

    const isMouseOnLeft =
      scrollIncrementHoriz >= 0 &&
      event.clientX > clientRect.left &&
      event.clientX < clientRect.left + ctx.opts.offsetLeft;

    const isMouseOnBottom =
      scrollIncrementVert <= scrollAreaEl.scrollHeight &&
      event.clientY > window.innerHeight - ctx.opts.offsetBottom &&
      event.clientY <= window.innerHeight;

    const isMouseOnRight =
      scrollIncrementHoriz <= scrollAreaEl.scrollWidth &&
      event.clientX > window.innerWidth - ctx.opts.offsetRight &&
      event.clientX <= window.innerWidth;

    if (
      !ctx.opts.disableVertical &&
      !ctx.isScrollingVert &&
      (isMouseOnTop || isMouseOnBottom)
    ) {
      ctx.isScrollingVert = true;
      scrollIncrementVert = scrollAreaEl.scrollTop;

      if (isMouseOnTop) {
        window.requestAnimationFrame(goUp);
      } else {
        window.requestAnimationFrame(goDown);
      }
    } else if (!isMouseOnTop && !isMouseOnBottom) {
      ctx.isScrollingVert = false;
    }

    if (
      !ctx.opts.disableHorizontal &&
      !ctx.isScrollingHoriz &&
      (isMouseOnLeft || isMouseOnRight)
    ) {
      ctx.isScrollingHoriz = true;
      scrollIncrementHoriz = scrollAreaEl.scrollLeft;

      if (isMouseOnLeft) {
        window.requestAnimationFrame(goLeft);
      } else {
        window.requestAnimationFrame(goRight);
      }
    } else if (!isMouseOnLeft && !isMouseOnRight) {
      ctx.isScrollingHoriz = false;
    }
  };

  /**
   * The "throttle" method prevents executing the same function SO MANY times.
   */
  const throttleOnDragOver = throttle(onDragOver, 300);

  // IMPORTANT: CALL THIS METHOD IN: beginDrag!!!
  const onBeginDrag = (options?: DragScrollOptions) => {
    ctx.opts = { ...defaultOpts, ...options };

    scrollAreaEl.addEventListener("dragover", throttleOnDragOver);
  };

  // IMPORTANT: CALL THIS METHOD IN: endDrag!!!
  const onEndDrag = () => {
    scrollAreaEl.removeEventListener("dragover", throttleOnDragOver);
    throttleOnDragOver.cancel();
    ctx.isScrollingVert = false;
    ctx.isScrollingHoriz = false;
  };

  return {
    onBeginDrag,
    onEndDrag,
  };
});

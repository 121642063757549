import { filterTypes } from "./filters";
import { PlanLineItemDto, PlanLineItemState } from "../../../models";
import { FilterOperations } from "./types";
import {
  SetColumnFilterTypeDispatch,
  SetDefaultValueColumnsDispatch,
  SetPreviousValueColumnsDispatch,
} from "../../me/plans/actions";
import { DefaultValue, PreviousValue } from "../../../models/grid";

export * from "react-table";

declare module "react-table" {
  export interface TableOptions<D extends object = {}> {
    //defaultColumn: typeof defaultColumn;
    filterTypes: typeof filterTypes;
    data: readonly PlanLineItemState[];
    updateData: (
      headerIndex: number,
      subrowIndex: number,
      accessor,
      value
    ) => void;
    autoResetExpanded: boolean;
    autoResetFilters: boolean;
    autoResetPage?: boolean;
    setColumnFilterType?: SetColumnFilterTypeDispatch;
    getSubRows?: (row: any) => any[];
    setDefaultValueColumns: SetDefaultValueColumnsDispatch;
    setPreviousValueColumns?: SetPreviousValueColumnsDispatch;
    defaultValues?: DefaultValue[];
    previousValues?: PreviousValue[];
    selectRow: (rowId: string, canDelete: boolean, canDemote: boolean) => void;
    useControlledState: (state: any) => any;
  }

  export interface HeaderGroup<D extends object = {}> {
    isResizing: boolean;
    isSorted: boolean;
    isSortedDesc: boolean;
    getResizerProps: () => any;
    getSortByToggleProps: () => any;
  }

  export interface TableInstance<D extends object = {}> {
    isAllRowsExpanded: boolean;
    getToggleAllRowsExpandedProps: () => any;
    closeDefaultModal: () => any;
    showDefaultModal: () => any;
    moveToCell: (row: number, col: number) => void;
    toggleRowExpanded: (rowId: string, isExpanded?: boolean) => void;
    filteredFlatRows: Array<any>;
    page?: any;
    canPreviousPage?: boolean;
    canNextPage?: boolean;
    pageOptions?: any;
    pageCount?: number;
    gotoPage?: any;
    nextPage?: any;
    previousPage?: any;
    setPageSize?: any;
    selectRows?: () => void;
  }

  export interface TableState<D extends object = {}> {
    contextMenuState: {
      isVisible: boolean;
      pageX: number;
      pageY: number;
      row: Row;
      column: Column;
    };
    selectedCell: {
      rowIndex: number;
      columnIndex: number;
      row: any;
      column: any;
    };
    showDefaultValueModal: boolean;
    editingColumn: any;
    defaultValues: [];
    copyPreviousValue: [];
    columnResizing: {
      columnWidth: number;
      columnWidths: Record<string, number>;
    };
    pageIndex?: number;
    pageSize?: number;
  }

  export interface TableRowProps<D extends object = {}> {
    onContextMenu: React.ReactEventHandler;
  }

  export interface Row<D extends object = {}> {
    canExpand?: boolean;
    isExpanded?: boolean;
    subRows?: Row<D>[];
    depth?: number;
    original?: PlanLineItemDto;
    isSelected: boolean;
    canDelete: boolean;
    canDemote: boolean;
    select: (selectionConfig?: any) => void;
    getToggleRowExpandedProps: (...any) => any;
  }

  export interface ColumnInterface<D extends object = {}> {
    left: number;
    pinned: boolean;
    width?: number;
    preFilteredRows?: any;
    filter?: FilterOperations;
    filterValue?: string;
    setFilter?: (value: string) => void;
  }
}

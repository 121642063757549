import {
  LOGGED_IN_USER,
  LOGGED_OUT_USER,
  LOGGING_IN_USER,
  LOG_IN_FAILURE,
  AuthActionTypes,
  State,
  SET_MSAL_AUTH,
} from "./actions";

const initialState: State = {
  loggingIn: false,
};

const authReducer = (state = initialState, action: AuthActionTypes): State => {
  switch (action.type) {
    case LOGGED_IN_USER:
      return {
        ...state,
        user: action.user,
        loggingIn: false,
      };
    case LOGGED_OUT_USER:
      return {
        ...state,
        user: null,
        loggingIn: false,
      };
    case LOGGING_IN_USER:
      return {
        ...state,
        loggingIn: action.payload ?? true,
      };
    case LOG_IN_FAILURE:
      return {
        ...state,
        loggingIn: false,
        error: action.error,
      };
    case SET_MSAL_AUTH:
      return {
        ...state,
      };
    default:
      return state;
  }
};

export default authReducer;

import React from "react";
import styled from "@emotion/styled";
import PageContent from "../../components/PageContent";
import {
  Dropdown,
  Button,
  Divider,
  DropdownProps,
  Message,
} from "semantic-ui-react";
import { connect } from "react-redux";
import { AppState } from "../../../app/AppState";
import HeaderSubHeader from "semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader";
import {
  GetSiteDto,
  FactorTemplateWithRates,
  Resource,
  KeyedLookup,
} from "../../../models";
import {
  State,
  getFactorTemplates,
  addFactorTemplate,
  updateFactorTemplate,
  selectFactorTemplate,
  deleteFactorTemplate,
  updateFactorRate,
  saveFactorRates,
  saveActvityComponents,
} from "./actions";
import AppContext from "../../../app/AppContext";
import { TemplateModal } from "./TemplateModal";
import TemplateFactorGrid from "./TemplateFactorGrid";
import { isEmpty } from "../../../util";
import { ActivityNumberModal } from "../../templates/ActivityNumberModal";
import { toast } from "react-toastify";
import { loadResources } from "../../resources/actions";
import { DeleteModal } from "../../components/DeleteModal";

const StyledRoot = styled(PageContent)`
  .actions-container {
    display: flex;

    .left-actions-container .ui.selection.dropdown {
      margin-right: 12px;
    }

    .right-actions-container {
      display: flex;
      flex: 1;
      justify-content: flex-end;
    }
  }
  td {
    max-width: 250px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.overflow-initial {
      overflow: initial;
    }
  }

  .body {
    flex: 1;
    overflow: hidden;
  }

  .template-container {
    height: 70vh;
    overflow: auto;
    margin-top: 20px;
    position: relative;
  }

  .template-container th {
    opacity: 1;
    z-index: 10;
  }

  .template-container .ui.input {
    position: static;
  }

  th.sticky {
    top: 0;
  }
`;

const defaultFactorTemplate = (
  site: GetSiteDto,
  isDefault: boolean
): FactorTemplateWithRates => {
  return {
    name: "",
    siteId: site.id,
    defaultRate: 0,
    factorGlobalAdjustment: 1,
    actNoIsAutoCalculated: true,
    isDefaultTemplate: isDefault,
    resourceRates: [],
    activityNumberComponents: [],
  };
};

type OwnProps = State & { site: GetSiteDto; resources: KeyedLookup<Resource> };

type Props = OwnProps & DispatchProps;

function Template(props: Props) {
  const { api } = React.useContext(AppContext);
  const { loadResources, getFactorTemplates, resources, site } = props;

  const [showingNewTplModal, setShowingNewTplModal] = React.useState(false);
  const [showingEditTplModal, setShowingEditTplModal] = React.useState(false);
  const [
    showingActivityNumberModal,
    setShowingActivityNumberModal,
  ] = React.useState(false);
  const [showingDeleteTplModal, setShowingDeleteTplModal] = React.useState(
    false
  );
  const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);

  const dropdownItems = props.factors?.map(x => {
    return {
      key: x.id,
      value: x.id,
      text: `${x.isDefaultTemplate ? "*" : ""}${x.name}`,
    };
  });

  const _handleSelection = (_, data: DropdownProps) => {
    let selection = props.factors.find(x => x.id === data.value);
    props.selectFactorTemplate(selection);
  };

  React.useEffect(() => {
    getFactorTemplates(api, site.id);
    if (isEmpty(resources)) {
      loadResources(api);
    }
  }, [api, site.id, resources, getFactorTemplates, loadResources]);

  const resourceRates = props.selectedTemplate?.resourceRates;
  React.useEffect(() => {
    const changed = resourceRates?.some(x => x.newVal != null);
    setHasUnsavedChanges(changed);
  }, [resourceRates]);

  return (
    <StyledRoot
      title="Man Hour Factor Templates"
      actions={
        <Button
          content="New Factor Template"
          icon="add"
          onClick={_ => setShowingNewTplModal(true)}
          primary
        />
      }
    >
      <HeaderSubHeader>
        Quickplan factor templates for currently loaded site. Control rates per
        resource type, default rate and bump factors.
      </HeaderSubHeader>
      <Divider section />
      <div className="actions-container">
        <div className="left-actions-container">
          <Dropdown
            placeholder="Select template"
            selection
            value={props.selectedTemplate?.id}
            options={dropdownItems}
            onChange={_handleSelection}
          ></Dropdown>
          <Button
            content="Edit Factor Template"
            title="Edit Man Hour Factor Template"
            icon="edit outline"
            onClick={_ => setShowingEditTplModal(true)}
            disabled={isEmpty(props.selectedTemplate)}
            primary={!isEmpty(props.selectedTemplate)}
          />
          <Button
            content="Configure Activity Numbers"
            disabled={isEmpty(props.selectedTemplate)}
            icon="configure"
            onClick={_ => setShowingActivityNumberModal(true)}
            secondary
          />
          <Button
            content="Delete Factor Template"
            disabled={
              isEmpty(props.selectedTemplate) ||
              props.selectedTemplate.isDefaultTemplate
            }
            icon="trash alternate outline"
            negative
            onClick={_ => setShowingDeleteTplModal(true)}
          />
        </div>
        <div className="right-actions-container">
          <Button
            content="Save Rate Changes"
            disabled={!hasUnsavedChanges}
            icon="save"
            primary
            onClick={_ => props.saveFactorRates(api, props.selectedTemplate)}
          />
        </div>
      </div>
      <TemplateModal
        title="New Man Hour Factor Template"
        api={api}
        open={showingNewTplModal}
        onClose={() => setShowingNewTplModal(false)}
        site={props.site}
        template={defaultFactorTemplate(props.site, props.factors.length === 0)}
        saveFactorTemplate={props.addFactorTemplate}
        mustBeDefault={props.factors.length === 0}
      />
      <ActivityNumberModal
        open={showingActivityNumberModal}
        onClose={() => setShowingActivityNumberModal(false)}
        actNoEntries={props.selectedTemplate?.activityNumberComponents}
        saveAction={props.saveActvityComponents}
        selectedTemplate={props.selectedTemplate}
      />
      {!isEmpty(props.selectedTemplate) ? (
        <TemplateModal
          title="Edit Man Hour Factor Template"
          api={api}
          open={showingEditTplModal}
          onClose={() => setShowingEditTplModal(false)}
          site={props.site}
          template={props.selectedTemplate}
          saveFactorTemplate={props.updateFactorTemplate}
          mustBeDefault={props.selectedTemplate.isDefaultTemplate}
        />
      ) : null}
      <DeleteModal
        deleteAction={() =>
          props.deleteFactorTemplate(api, props.selectedTemplate).then(resp => {
            setShowingDeleteTplModal(false);
            toast.info(`${props.selectedTemplate.name} was deleted`);
          })
        }
        modalContent={
          <>
            <Message warning>
              Any plans using this template will be moved to the default
              template.
            </Message>
            <div>Are you sure you want to delete this template?</div>
          </>
        }
        modalTitle="Delete Template"
        onClose={() => setShowingDeleteTplModal(false)}
        open={showingDeleteTplModal}
      />
      {props.selectedTemplate ? (
        <div className="template-container">
          <TemplateFactorGrid
            resources={props.resources}
            factorTemplate={props.selectedTemplate}
            updateRate={props.updateFactorRate}
          />
        </div>
      ) : null}
    </StyledRoot>
  );
}

const mapStateToProps = (state: AppState, props: OwnProps): OwnProps => {
  return {
    site: state.me.selectedSite,
    hasChanges: props.hasChanges,
    factors: state.templates.factors,
    selectedTemplate: state.templates.selectedTemplate,
    resources: state.resources.byId,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getFactorTemplates: getFactorTemplates(dispatch),
    addFactorTemplate: addFactorTemplate(dispatch),
    updateFactorTemplate: updateFactorTemplate(dispatch),
    selectFactorTemplate: selectFactorTemplate(dispatch),
    deleteFactorTemplate: deleteFactorTemplate(dispatch),
    updateFactorRate: updateFactorRate(dispatch),
    saveFactorRates: saveFactorRates(dispatch),
    loadResources: loadResources(dispatch),
    saveActvityComponents: saveActvityComponents(dispatch),
  };
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(Template);

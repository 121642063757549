import React from "react";
import { Loader, Message } from "semantic-ui-react";

type Props = {
  error: boolean;
  pastDelay: boolean;
};

const ContentLoader = ({ error = false, pastDelay = false }: Props) =>
  error ? (
    <Message content="Failed to load component" header="Error" negative />
  ) : pastDelay ? (
    <Loader active inline="centered" />
  ) : null;

export default ContentLoader;

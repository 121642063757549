import {
  Actions,
  UPDATE_ROW_DATA,
  INIT_PLAN_DATA,
  SAVE_PLAN_DATA,
  UPDATE_COLUMN,
  UPDATE_COLUMN_ORDER,
  TOGGLE_PINNED_COLUMN,
  SET_COLUMN_FILTER_TYPE,
  REMOVE_ROW,
  MOVE_ROWS,
  UPDATE_ROW,
  SELECT_ROW,
  PROMOTE_HEADER,
  DEMOTE_HEADER,
  COPY_DOWN_VALUE,
  SET_DEFAULT_VALUE_COLUMNS,
  SET_PREVIOUS_VALUE_COLUMNS,
  TOGGLE_HEADER,
  DUPLICATE_ROWS,
  RESEQUENCE_PLAN,
  REMOVE_SELECTED_ROWS,
  ADD_HEADER,
  ADD_BLANK,
  ADD_BLANK_BELOW,
  ADD_ABBR,
  GET_COL_PREFERENCES,
  UPDATE_COLUMN_WIDTH,
  ADD_HARD_FACTOR,
  DELETE_HARD_FACTOR,
  RESET_HARD_FACTORS,
  SAVE_HARD_FACTORS,
  UPDATE_HARD_FACTOR,
  CLEAR_HARD_FACTORS,
  ADD_ABBR_EXPLICIT,
  GET_COL_DEFAULTS,
  UPDATE_COLUMN_ALIAS,
} from "./actions";
import columns from "./planGridColumns";
import { ColumnInfo } from "../../components/grid";
import {
  PlanLineItemState,
  RowIndex,
  PlanDataGetDto,
  AbbreviationGetDto,
  SelectedRowDescriptor,
  PlanLineItemDto,
  NewPlanLineItemState,
  HardFactorDto,
} from "../../../models";
import _ from "lodash";
import {
  addCalculatedFields,
  addHeaderCalculatedFields,
  calculateTotals,
  setActivityNumber,
  setMiscFieldValues,
  setActivityNumbers,
  setMiscFieldValue,
  makeHeader,
  nextSequence,
  calculateSequence,
  addDefaultValues,
  addHardFactorCalculations,
  setHardFactors,
} from "./helpers";
import { PreviousValue, DefaultValue } from "../../../models/grid";
import { generateUUID } from "../../../util";
import { EXPORT_VALIDATION_ERRORS } from "../actions";

export type State = Readonly<{
  plan: Readonly<PlanDataGetDto>;
  columns: ReadonlyArray<Readonly<ColumnInfo>>;
  planData?: ReadonlyArray<Readonly<PlanLineItemState>>;
  rowIdsToDelete: ReadonlyArray<number>;
  lastSaved?: Date;
  hasChanges: boolean;
  selectedRows: SelectedRowDescriptor[];
  defaultValueColumns: DefaultValue[];
  previousValueColumns: PreviousValue[];
  abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>;
  hardFactors: HardFactorDto[];
}>;

const updateRow =
  (
    updates: { [P in keyof PlanLineItemState]?: any },
    planData?: PlanDataGetDto
  ) =>
  (row: PlanLineItemState): PlanLineItemState => {
    let updatedRow = {
      ...row,
      ...updates,
      lastModified: Date.now(),
      modified: true,
    };
    if (
      Object.keys(updates).some(x => x === "sequence") &&
      planData &&
      planData.factorTemplate.actNoIsAutoCalculated
    ) {
      setActivityNumber(
        updatedRow,
        planData.factorTemplate.activityNumberComponents,
        planData.jobNumber,
        planData.system,
        planData.unit
      );
    }

    return updatedRow;
  };

const updatePlanData = (
  planData: Readonly<PlanLineItemState>[],
  rowIndex: number,
  subrowIndex: number,
  updates: { [P in keyof PlanLineItemState]?: any },
  process?: (item: PlanLineItemState) => void
) => {
  if (
    _.isNaN(subrowIndex) ||
    _.isNull(subrowIndex) ||
    _.isUndefined(subrowIndex)
  ) {
    let item = {
      ...planData[rowIndex],
      ...updates,
      modified: true,
      lastModified: Date.now(),
    };
    process(item);
    planData[rowIndex] = item;
    return planData;
  } else {
    let item = {
      ...planData[rowIndex].subRows[subrowIndex],
      ...updates,
      modified: true,
      lastModified: Date.now(),
    };
    process(item);
    const subRows = planData[rowIndex].subRows.slice();
    subRows[subrowIndex] = item;
    planData[rowIndex] = { ...planData[rowIndex], subRows: subRows };
    return planData;
  }
};

function updateColumn(
  state: State,
  accessor,
  update: (column: ColumnInfo) => ColumnInfo
): State {
  const columns = state.columns.slice();
  const columnIndex = columns.findIndex(x => x.accessor === accessor);
  const column = columns[columnIndex];
  columns[columnIndex] = update({ ...column });
  return { ...state, columns };
}

// Update all row orders starting at `parentIndex`
// It is expected the claler has already made a copy of planData
// so it does not make a new copy before modifying the array.
const updateRowOrders = (
  planData: Readonly<PlanLineItemState>[],
  parentIndex: number,
  startOrder: number
) => {
  const mapRow = sub => {
    return {
      ...sub,
      orderInExport: startOrder++,
      modified: true,
      lastModified: Date.now(),
    };
  };

  for (let i = parentIndex; i < planData.length; i++) {
    const curr = { ...planData[i] };
    curr.orderInExport = startOrder++;
    curr.modified = true;
    curr.lastModified = Date.now();
    curr.subRows = curr.subRows.map(mapRow);

    planData[i] = curr;
  }

  return _.sortBy(planData, x => x.orderInExport);
};

function findMaxOrder(
  planData: readonly PlanLineItemState[],
  parentIndex?: number
) {
  if (!_.isUndefined(parentIndex)) {
    return (
      _.maxBy(planData[parentIndex].subRows, x => x.orderInExport)
        ?.orderInExport ?? planData[parentIndex].orderInExport
    );
  } else {
    return planData.length === 0
      ? 0
      : findMaxOrder(planData, planData.length - 1);
  }
}

// Moves a row to where you tell it and fix up the indexes and sequence/actno
// If destRowIndex.subrowIndex isNil, it will splice the items in planData
// Else, it puts them in the subrow.
function moveRows(
  plan: Readonly<PlanDataGetDto>,
  planData: ReadonlyArray<Readonly<PlanLineItemState>>,
  destRowIndex: RowIndex,
  movedRowIndexes: RowIndex[],
  abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>
): PlanLineItemState[] {
  const isParenting = !_.isNil(destRowIndex.subrowIndex);

  // The next 2 constructs are used for retrieving the item
  // at the given index and memoize any object creations so
  // subsequent requests to the same header will return the same
  // object
  let parentMap = new Map<number, PlanLineItemState>();
  const getParent = (index: number, makeCopy: boolean): PlanLineItemState => {
    return parentMap.has(index)
      ? parentMap.get(index)
      : makeCopy
      ? parentMap
          .set(index, {
            ...planData[index],
            subRows: planData[index].subRows.slice(),
          })
          .get(index)
      : planData[index];
  };

  const getTask = (parent: PlanLineItemState, subrowIndex?: number) => {
    return parent.subRows[subrowIndex] || null;
  };

  // collect all the items that are moving before any move ops
  const items = movedRowIndexes.map(indexer => {
    const parent = getParent(indexer.index, !_.isNil(indexer.subrowIndex));

    return { indexer, parent, item: getTask(parent, indexer.subrowIndex) };
  });

  const addToResults = (
    parent: PlanLineItemState,
    order: number,
    index: number
  ): [PlanLineItemState, number] => {
    if (parent.orderInExport === order) {
      result.push(parent);
      order++;
      for (let sri = 0; sri < parent.subRows.length; sri++) {
        const sr = parent.subRows[sri];
        if (sr.orderInExport !== order) {
          parent.subRows.splice(sri, 1, {
            ...sr,
            orderInExport: order++,
            modified: true,
            lastModified: Date.now(),
          });
        } else {
          order++;
        }
      }
      return [parent, order];
    } else {
      const copy = {
        ...parent,
        orderInExport: order++,
        modified: true,
        lastModified: Date.now(),
        subRows: parent.subRows.map(y => {
          return {
            ...y,
            orderInExport: order++,
            modified: true,
            lastModified: Date.now(),
          };
        }),
      };
      result.push(copy);
      parentMap.set(index, copy);
      return [copy, order];
    }
  };

  const movedHeaders = new Map<number, boolean>();

  // remove the subrows from any parents
  items.forEach(({ indexer, parent, item }) => {
    if (!_.isNull(item)) {
      parent.subRows.splice(
        parent.subRows.findIndex(x => x.clientId === item.clientId),
        1
      );
    } else {
      movedHeaders.set(indexer.index, true);
    }
  });

  // Collect all items being moved from the original plan data
  let destSubrowIndex = destRowIndex.subrowIndex;
  let result: PlanLineItemState[] = [];
  let order = 1;

  planData.forEach((__, i) => {
    const isMoved = movedHeaders.has(i);

    if (i === destRowIndex.index) {
      let after = [];
      let parentDest = getParent(destRowIndex.index, true);
      if (isParenting) {
        after = parentDest.subRows.splice(destRowIndex.subrowIndex).map(a => {
          return { indexer: destRowIndex, parent: parentDest, item: a };
        });
        [parentDest, order] = addToResults(parentDest, order, i);
      } else {
        after = [{ indexer: destRowIndex, parent: parentDest }];
      }

      items.concat(after).forEach(({ indexer, parent, item }) => {
        const movingItem = item || parent;

        const itemCopy = {
          ...movingItem,
          sequence: parentDest.sequence,
          orderInExport: order++,
          modified: true,
          lastModified: Date.now(),
          subRows: movingItem.subRows?.map(y => {
            return {
              ...y,
              sequence: parentDest.sequence,
              orderInExport: order++,
              modified: true,
              lastModified: Date.now(),
            };
          }),
        };

        if (
          movingItem.sequence !== itemCopy.sequence &&
          plan.hardFactors !== null &&
          plan.hardFactors.length > 0
        ) {
          setHardFactors(itemCopy, plan.hardFactors);
          addCalculatedFields(itemCopy, plan.factorTemplate, abbreviations);
        }

        if (plan.factorTemplate.actNoIsAutoCalculated) {
          setActivityNumber(
            itemCopy,
            plan.factorTemplate.activityNumberComponents,
            plan.jobNumber,
            plan.system,
            plan.unit
          );
        }

        addHeaderCalculatedFields(parent, true);

        if (isParenting) {
          itemCopy.time = parentDest.time;
          itemCopy.company = parentDest.company;
          itemCopy.typeOfWork = parentDest.typeOfWork;
          parentDest.subRows.splice(destSubrowIndex, 0, itemCopy);
          destSubrowIndex++;
        } else {
          result.push(itemCopy);
        }
      });
    } else if (!isMoved) {
      const parent = getParent(i, i === destRowIndex.index && isParenting);
      [, order] = addToResults(parent, order, i);
    }
  });

  const parent = getParent(destRowIndex.index, true);
  if (parent.isHeader) addHeaderCalculatedFields(parent, true);

  return result;
}

const promoteHeader = (
  parentIndex: number,
  subrowIndex: number,
  plan: Readonly<PlanDataGetDto>,
  data: ReadonlyArray<Readonly<PlanLineItemState>>
): PlanLineItemState[] => {
  let planData = data.slice();

  const isSubrow = !_.isNil(subrowIndex);

  let rowToPromote: PlanLineItemState = isSubrow
    ? { ...planData[parentIndex].subRows[subrowIndex] }
    : { ...planData[parentIndex] };

  if (rowToPromote === undefined || parentIndex === undefined) {
    throw new Error("An unexpected error occurred in the grid");
  }

  if (!isSubrow) {
    //this indicates it was an orphaned row being promoted
    let numToRemove = 0;
    for (
      let i = parentIndex + 1;
      i < planData.length && !planData[i].isHeader;
      i++
    ) {
      if (planData[i].sequence !== rowToPromote.sequence) {
        planData[i] = {
          ...planData[i],
          sequence: rowToPromote.sequence,
          modified: true,
          lastModified: Date.now(),
        };

        if (plan.factorTemplate.actNoIsAutoCalculated) {
          setActivityNumber(
            planData[i],
            plan.factorTemplate.activityNumberComponents,
            plan.jobNumber,
            plan.system,
            plan.unit
          );
        }
      }
      rowToPromote.subRows.push(planData[i]);
      numToRemove++;
    }

    rowToPromote = makeHeader(rowToPromote);
    addHeaderCalculatedFields(rowToPromote, true);

    planData.splice(parentIndex, 1, rowToPromote);
    planData.splice(parentIndex + 1, numToRemove);

    planData = calculateTotals(planData);
  } else {
    // Move subrows after the selected item to the item being promoted

    planData.splice(parentIndex, 1, {
      ...planData[parentIndex],
      subRows: planData[parentIndex].subRows.slice(),
    });

    rowToPromote = makeHeader(rowToPromote);
    planData[parentIndex].subRows.splice(subrowIndex, 1, rowToPromote);

    let numToRemove = 0;
    for (
      let i = subrowIndex + 1;
      i < planData[parentIndex].subRows.length;
      i++
    ) {
      if (planData[parentIndex].subRows[i].sequence !== rowToPromote.sequence) {
        planData[parentIndex].subRows[i] = {
          ...planData[parentIndex].subRows[i],
          sequence: rowToPromote.sequence,
          modified: true,
          lastModified: Date.now(),
        };
        if (plan.factorTemplate.actNoIsAutoCalculated) {
          setActivityNumber(
            planData[parentIndex].subRows[i],
            plan.factorTemplate.activityNumberComponents,
            plan.jobNumber,
            plan.system,
            plan.unit
          );
        }
      }
      rowToPromote.subRows.push(planData[parentIndex].subRows[i]);
      //TODO: At some point, the child subrows are being assigned subrows (creating a self-ref loop).
      //This clears the value, but does not address the core issue.
      planData[parentIndex].subRows[i].subRows = [];
      numToRemove++;
    }

    planData[parentIndex].subRows.splice(subrowIndex, numToRemove + 1);
    addHeaderCalculatedFields(planData[parentIndex], true);
    addHeaderCalculatedFields(rowToPromote, true);

    planData.splice(parentIndex + 1, 0, rowToPromote);

    planData = calculateTotals(planData);
  }

  return planData;
};

const demoteHeader = (
  index: number,
  plan: Readonly<PlanDataGetDto>,
  data: ReadonlyArray<Readonly<PlanLineItemState>>,
  abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>
): PlanLineItemState[] => {
  const planData = data.slice();

  let rowToDemote = {
    ...planData[index],
    isHeader: false,
    subRows: planData[index].subRows.slice(),
    modified: true,
    lastModified: Date.now(),
  };

  if (plan.miscFieldColumn) {
    setMiscFieldValue(plan.miscFieldColumn, plan.miscFieldValue, rowToDemote);
  }

  addCalculatedFields(rowToDemote, plan.factorTemplate, abbreviations);

  const destIndex =
    index - 1 < 0
      ? { index: 0 }
      : planData[index - 1].isHeader
      ? { index: index - 1, subrowIndex: planData[index - 1].subRows.length }
      : { index: index };

  const moving = [rowToDemote, ...rowToDemote.subRows.splice(0)];

  if (_.isNil(destIndex.subrowIndex)) {
    planData.splice(destIndex.index, 1, ...moving);
  } else {
    planData.splice(index, 1);

    planData[destIndex.index] = {
      ...planData[destIndex.index],
      subRows: planData[destIndex.index].subRows.slice(),
    };

    planData[destIndex.index].subRows.splice(
      destIndex.subrowIndex,
      0,
      ...moving.map(x => {
        let copy = {
          ...x,
          sequence: planData[destIndex.index].sequence,
          modified: true,
          lastModified: Date.now(),
          typeOfWork: planData[destIndex.index].typeOfWork,
          company: planData[destIndex.index].company,
          time: planData[destIndex.index].time,
        };

        if (plan.factorTemplate.actNoIsAutoCalculated) {
          setActivityNumber(
            copy,
            plan.factorTemplate.activityNumberComponents,
            plan.jobNumber,
            plan.system,
            plan.unit
          );
        }

        if (plan.hardFactors?.length > 0) {
          setHardFactors(copy, plan.hardFactors);
          addCalculatedFields(copy, plan.factorTemplate, abbreviations);
        }

        return copy;
      })
    );
  }

  addHeaderCalculatedFields(planData[destIndex.index], true);

  return calculateTotals(planData);
};

function updateChildSequences(
  planData: Readonly<PlanLineItemState>[],
  plan: Readonly<PlanDataGetDto>,
  rowIndex: number,
  abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>
) {
  const header = {
    ...planData[rowIndex],
    subRows: planData[rowIndex].subRows.slice(),
  };

  header.subRows = header.subRows.map(
    updateRow({ sequence: header.sequence }, plan)
  );

  if (plan.hardFactors?.length > 0) {
    header.subRows = header.subRows.map(x => {
      setHardFactors(x, plan.hardFactors);
      addCalculatedFields(x, plan.factorTemplate, abbreviations);
      return x;
    });
  }

  return header;
}

function updateChildByParentKey(
  planData: Readonly<PlanLineItemState>[],
  plan: Readonly<PlanDataGetDto>,
  rowIndex: number,
  key: string
) {
  const header = {
    ...planData[rowIndex],
    subRows: planData[rowIndex].subRows.slice(),
  };

  header.subRows = header.subRows.map(updateRow({ [key]: header[key] }, plan));

  return header;
}

const findLastSubRow = (
  planData: Readonly<PlanLineItemState[]>,
  index: number
): PlanLineItemState => {
  if (index < 0 || index >= planData.length) return undefined;
  if (planData[index].subRows.length > 0) {
    return _.last(planData[index].subRows);
  } else {
    return findLastSubRow(planData, index - 1);
  }
};

const duplicateRow = (
  planData: PlanLineItemState[],
  rowIndex: RowIndex,
  plan: PlanDataGetDto,
  maxOrder: number,
  defaultValues: DefaultValue[],
  previousValues: PreviousValue[]
): PlanLineItemState[] => {
  const { index, subrowIndex } = rowIndex;

  if (_.isNil(subrowIndex)) {
    let dataCopy: PlanLineItemState = { ...planData[index] };

    dataCopy.id = 0;
    dataCopy.clientId = generateUUID();
    dataCopy.sequence = nextSequence(planData);
    dataCopy.orderInExport = maxOrder;
    dataCopy.subRows = [];
    dataCopy.modified = true;
    dataCopy.lastModified = Date.now();

    if (plan.factorTemplate.actNoIsAutoCalculated) {
      setActivityNumber(
        dataCopy,
        plan.factorTemplate.activityNumberComponents,
        plan.jobNumber,
        plan.system,
        plan.unit
      );
    }

    addHeaderCalculatedFields(dataCopy, true);

    planData = planData.concat(dataCopy);
  } else {
    let dataCopy: PlanLineItemState = {
      ...planData[index].subRows[subrowIndex],
    };

    const lastSubrow = findLastSubRow(planData, planData.length - 1);
    const newParent = _.last(planData);

    dataCopy.id = 0;
    dataCopy.clientId = generateUUID();
    dataCopy.sequence = newParent.sequence;
    dataCopy.typeOfWork = newParent.typeOfWork;
    dataCopy.company = newParent.company;
    dataCopy.time = newParent.time;
    dataCopy.orderInExport = maxOrder;
    dataCopy.modified = true;
    dataCopy.lastModified = Date.now();
    dataCopy.runningTotal = lastSubrow.runningTotal + dataCopy.dollars;
    dataCopy.runningHrTotal = lastSubrow.runningHrTotal + dataCopy.baseHours;
    dataCopy.bumpedRunningHrTotal =
      lastSubrow.bumpedRunningHrTotal + dataCopy.bumpedHr;

    if (plan.factorTemplate.actNoIsAutoCalculated) {
      setActivityNumber(
        dataCopy,
        plan.factorTemplate.activityNumberComponents,
        plan.jobNumber,
        plan.system,
        plan.unit
      );
    }

    addDefaultValues(
      defaultValues,
      dataCopy,
      plan.factorTemplate.actNoIsAutoCalculated
    );

    const previousLineObject = findLastSubRow(planData, planData.length - 1);
    if (previousLineObject && previousLineObject !== undefined) {
      if (previousValues !== undefined && previousValues.length) {
        _.forEach(previousValues, y => {
          if (y.enabled)
            dataCopy[y.colAccessor] = previousLineObject[y.colAccessor];
        });
      }
    }

    planData[planData.length - 1] = {
      ...planData[planData.length - 1],
      subRows: [...planData[planData.length - 1].subRows, { ...dataCopy }],
    };
  }

  addHeaderCalculatedFields(_.last(planData), true); //TODO: mutation ok

  return planData;
};

const removeRow = (state: State, index: number, subrowIndex: number): State => {
  let planData = state.planData.slice();
  const rowIsTask = !_.isNaN(subrowIndex) && subrowIndex !== undefined;
  const rowsToDelete = state.rowIdsToDelete.slice();

  if (rowIsTask) {
    planData[index] = {
      ...planData[index],
      subRows: [...planData[index].subRows.slice()],
    };

    const [deletedItem] = planData[index].subRows.splice(subrowIndex, 1);
    let order = planData[index].orderInExport;

    for (let i = subrowIndex; i < planData[index].subRows.length; i++) {
      planData[index].subRows[i] = {
        ...planData[index].subRows[i],
        modified: true,
        lastModified: Date.now(),
      };
    }
    updateRowOrders(planData, index, order);
    addHeaderCalculatedFields(planData[index], true);
    planData = calculateTotals(planData);

    rowsToDelete.push(deletedItem.id);
    return { ...state, planData, rowIdsToDelete: rowsToDelete };
  } else {
    const canDelete = index !== 0 || planData[index].subRows.length === 0;
    if (canDelete) {
      const previousIndex = index - 1;
      const newSequence = planData[previousIndex]?.sequence ?? "0010";
      const plan = state.plan;

      const [deletedItem] = planData.splice(index, 1);
      const subRowsToMove = _.map(deletedItem.subRows, x => {
        return {
          ...x,
          sequence: newSequence,
          modified: true,
          lastModified: Date.now(),
          typeOfWork: planData[previousIndex].typeOfWork,
          time: planData[previousIndex].time,
          company: planData[previousIndex].company,
          errors: {},
        };
      });
      let order = deletedItem.orderInExport;

      if (previousIndex > -1 && subRowsToMove.length > 0) {
        planData[previousIndex] = {
          ...planData[previousIndex],
          subRows: [
            ...planData[previousIndex].subRows.slice(),
            ...subRowsToMove,
          ],
        };
      }

      if (plan.factorTemplate.actNoIsAutoCalculated) {
        setActivityNumbers(
          planData,
          plan.factorTemplate,
          plan.jobNumber,
          plan.system,
          plan.unit
        );
      }

      if (previousIndex > -1) {
        updateRowOrders(
          planData,
          previousIndex,
          planData[previousIndex].orderInExport
        );

        addHeaderCalculatedFields(planData[previousIndex]);
        calculateSequence(planData, previousIndex);
      } else {
        updateRowOrders(planData, index, order);
      }

      planData = calculateTotals(planData);

      rowsToDelete.push(deletedItem.id);

      return { ...state, planData, rowIdsToDelete: rowsToDelete };
    }

    throw new Error("You may not delete the first header if it has tasks");
  }
};

const addNewSubRow = (
  row: NewPlanLineItemState,
  planData: PlanLineItemState[],
  previousTask: Readonly<PlanLineItemDto>,
  plan: Readonly<PlanDataGetDto>,
  abbreviations: Readonly<AbbreviationGetDto[]>,
  parentIndex: number,
  subrowIndex?: number,
  selectedRow?: Readonly<PlanLineItemDto>
): PlanLineItemState[] => {
  if (previousTask && previousTask !== undefined) {
    if (row.previousValues !== undefined && row.previousValues.length) {
      _.forEach(row.previousValues, y => {
        if (y.enabled) row[y.colAccessor] = previousTask[y.colAccessor];
      });
    }
  }

  let startOrder = 0;
  //adding subrow below selected row
  if (!_.isUndefined(selectedRow)) {
    let nextOrder = selectedRow.orderInExport + 1;
    row.orderInExport = nextOrder++;
    row.typeOfWork = planData[parentIndex].typeOfWork;
    row.time = planData[parentIndex].time;
    row.company = planData[parentIndex].company;
    addCalculatedFields(row, plan.factorTemplate, abbreviations);

    planData[parentIndex] = {
      ...planData[parentIndex],
      subRows: [...planData[parentIndex].subRows, row],
    };

    const startIndex =
      _.isNil(subrowIndex) || _.isNaN(subrowIndex) ? 0 : subrowIndex + 1;
    for (
      let i = startIndex;
      i < planData[parentIndex].subRows.length - 1;
      i++
    ) {
      planData[parentIndex].subRows[i] = {
        ...planData[parentIndex].subRows[i],
        modified: true,
        lastModified: Date.now(),
        orderInExport: nextOrder++,
      };
    }

    planData[parentIndex] = {
      ...planData[parentIndex],
      subRows: [
        ...planData[parentIndex].subRows.sort((a, b) => {
          return a.orderInExport - b.orderInExport;
        }),
      ],
    };

    startOrder = nextOrder;
  }
  //adding subrow to header
  else {
    let maxSub = findMaxOrder(planData, parentIndex) + 1;
    row.orderInExport = maxSub++;
    row.typeOfWork = planData[parentIndex].typeOfWork;
    row.time = planData[parentIndex].time;
    row.company = planData[parentIndex].company;
    addCalculatedFields(row, plan.factorTemplate, abbreviations);
    startOrder = maxSub;

    planData[parentIndex] = {
      ...planData[parentIndex],
      subRows: [...planData[parentIndex].subRows, row],
    };
  }

  addHeaderCalculatedFields(planData[parentIndex], true);
  planData = calculateTotals(planData);

  const sortedPlanData = updateRowOrders(planData, parentIndex + 1, startOrder);

  return sortedPlanData;
};

const _applyAbbr =
  (rowId: string, abbr: AbbreviationGetDto, quantity: number, state: State) =>
  (planDataCopy: Readonly<PlanLineItemState>[]) => {
    const [index, subrowIndex] = rowId.split(".");
    if (!_.isNil(subrowIndex)) {
      planDataCopy[index].subRows[subrowIndex] =
        _.isNil(quantity) || _.isNaN(quantity)
          ? updateRow(
              { abbr: abbr.abbr, description: abbr.description },
              state.plan
            )(planDataCopy[index].subRows[subrowIndex])
          : updateRow(
              {
                abbr: abbr.abbr,
                description: abbr.description,
                quantity: quantity,
              },
              state.plan
            )(planDataCopy[index].subRows[subrowIndex]);

      addCalculatedFields(
        planDataCopy[index].subRows[subrowIndex],
        state.plan.factorTemplate,
        state.abbreviations
      );
      addHeaderCalculatedFields(planDataCopy[index], true);
    }
  };

const reduce = (
  state: State = {
    columns,
    plan: null,
    rowIdsToDelete: [],
    hasChanges: false,
    selectedRows: [],
    defaultValueColumns: [],
    previousValueColumns: [],
    abbreviations: [],
    hardFactors: null,
  },
  action: Actions
): State => {
  if (action.type === INIT_PLAN_DATA) {
    const { plan, abbreviations } = action.payload;

    const planData: PlanLineItemState[] = _.chain(plan.lines)
      .map(x => {
        return {
          ...x,
          clientId: `${x.id}`,
          errors: {},
          modified: false,
        } as PlanLineItemState;
      })
      .orderBy(x => x.orderInExport)
      .reduce(
        (accum, curr) => {
          curr.subRows = [];
          if (curr.isHeader) {
            accum.lastHeader = curr;
            accum.lines.push(curr);
          } else {
            if (plan.hardFactors) {
              plan.hardFactors.forEach(x => (x.clientId = generateUUID()));
              setHardFactors(curr, plan.hardFactors);
            }
            addCalculatedFields(curr, plan.factorTemplate, abbreviations);
            if (accum.lastHeader) {
              accum.lastHeader.subRows.push(curr);
            } else {
              accum.lines.push(curr);
            }
          }

          return accum;
        },
        { lastHeader: null, lines: [] }
      )
      .value().lines;

    planData.forEach((header, i) => {
      addHeaderCalculatedFields(header, true);
      calculateSequence(planData, i);
    });

    if (plan.miscFieldColumn) {
      setMiscFieldValues(plan.miscFieldColumn, plan.miscFieldValue, planData);
    }
    if (plan.factorTemplate.actNoIsAutoCalculated) {
      setActivityNumbers(
        planData,
        plan.factorTemplate,
        plan.jobNumber,
        plan.system,
        plan.unit
      );
    }

    return {
      ...state,
      plan,
      planData: calculateTotals(planData),
      lastSaved: null,
      selectedRows: [],
      abbreviations: abbreviations,
      hardFactors: plan.hardFactors,
    };
  }

  if (action.type === UPDATE_ROW_DATA) {
    const { rowIndex, subrowIndex, valid, errors, updates } = action.payload;
    let planData = state.planData.slice();
    const hfColumns = [
      "abbr",
      "actNo",
      "company",
      "resource",
      "sequence",
      "time",
      "miscellaneousField1",
      "miscellaneousField2",
      "miscellaneousField3",
    ];
    if (valid) {
      updatePlanData(planData, rowIndex, subrowIndex, updates, item => {
        Object.keys(updates).forEach(columnId => delete item.errors[columnId]);

        if (!item.isHeader) {
          if (
            state.plan.hardFactors &&
            Object.keys(updates).some(x => hfColumns.indexOf(x) !== -1)
          ) {
            setHardFactors(item, state.plan.hardFactors);
          }
          addCalculatedFields(
            item,
            state.plan.factorTemplate,
            state.abbreviations
          );
        }
        if (
          state.plan.factorTemplate.actNoIsAutoCalculated &&
          Object.keys(updates).some(x => x === "sequence")
        ) {
          setActivityNumber(
            item,
            state.plan.factorTemplate.activityNumberComponents,
            state.plan.jobNumber,
            state.plan.system,
            state.plan.unit
          );
        }
      });
    } else {
      updatePlanData(planData, rowIndex, subrowIndex, updates, item => {
        item.errors = { ...item.errors, ...errors };
        planData[rowIndex] = item;
      });
    }

    Object.keys(updates).forEach(x => {
      if (x === "typeOfWork" || x === "time" || x === "company") {
        if (!subrowIndex && subrowIndex !== 0) {
          const updatedHeader = updateChildByParentKey(
            planData,
            state.plan,
            rowIndex,
            x
          );
          planData[rowIndex] = updatedHeader;
        }
      }
    });

    if (Object.keys(updates).some(x => x === "sequence")) {
      if (!subrowIndex && subrowIndex !== 0) {
        //update subrow sequence with new sequence
        const updatedHeader = updateChildSequences(
          planData,
          state.plan,
          rowIndex,
          state.abbreviations
        );
        planData[rowIndex] = updatedHeader;
      }
      calculateSequence(planData, rowIndex);
    }

    const calcedPlanData = calculateTotals(
      planData.map(x => addHeaderCalculatedFields(x))
    );

    return {
      ...state,
      planData: calcedPlanData,
      hasChanges: state.hasChanges || valid,
    };
  }

  if (action.type === SAVE_PLAN_DATA) {
    const planData = _.chain(state.planData)
      .flatMap(x => {
        const parent = { item: x, parent: null, index: null, sliced: false };
        return [
          parent,
          ...x.subRows.map((sub, i) => {
            return { item: sub, parent, sliced: false, index: i };
          }),
        ];
      })
      .map(x => {
        let updated = action.payload.lines.find(
          upd => upd.clientId === x.item.clientId
        );
        if (updated) {
          const lineUpd = {
            ...updated,
            modified: updated.lastModified >= action.payload.saveTime,
          };
          if (!x.parent) x.item = lineUpd;
          else {
            if (!x.parent.sliced) {
              x.parent.item.subRows = x.parent.item.subRows.slice();
              x.parent.sliced = true;
            }
            x.parent.item.subRows[x.index] = lineUpd;
          }
        }
        return !x.parent ? x.item : null;
      })
      .filter(x => x !== null)
      .value();

    const rowIdsToDelete = state.rowIdsToDelete
      .slice()
      .filter(x => !action.payload.idsToDelete.includes(x));

    return {
      ...state,
      planData,
      rowIdsToDelete: rowIdsToDelete,
      lastSaved: new Date(),
    };
  }

  if (action.type === UPDATE_COLUMN) {
    const columns = state.columns.slice();
    const column = action.payload;
    const colIdx = columns.findIndex(x => x.accessor === column.accessor);
    const [oldCol] = columns.splice(colIdx, 1, column);
    if (oldCol.order !== column.order) {
      _.sortBy(columns, x => x.order).forEach((col, i) => {
        columns[i] = { ...col, order: i };
      });
    }
    return { ...state, columns };
  }

  if (action.type === UPDATE_ROW) {
    return { ...state };
  }

  //updates a columns order and reorders every element to the right
  if (action.type === UPDATE_COLUMN_ORDER) {
    const { accessor, order } = action.payload;
    const columns = state.columns.slice();

    //split the array based on the new column order
    const [greaterThanArray, lessThanArray] = _.partition(
      columns,
      x => (x.order || 0) >= order
    );

    const updatedColumnsOrder = _.each(greaterThanArray, (x, i) => {
      if (x.accessor !== accessor)
        greaterThanArray[i] = { ...x, order: x.order + 1 };
    }).concat(lessThanArray);

    //update the order for the accessed column
    const columnIdx = updatedColumnsOrder.findIndex(
      x => x.accessor === accessor
    );
    updatedColumnsOrder[columnIdx] = {
      ...updatedColumnsOrder[columnIdx],
      order,
    };

    return { ...state, columns: updatedColumnsOrder };
  }

  if (action.type === ADD_HEADER) {
    const { row } = action.payload;

    row.orderInExport = findMaxOrder(state.planData) + 1;
    addHeaderCalculatedFields(row, true);

    const planData = [...state.planData, row];
    const sortedPlanData = _.sortBy(planData, x => x.orderInExport);

    return { ...state, planData: sortedPlanData };
  }

  if (action.type === ADD_BLANK) {
    const { row, parentIndex } = action.payload;

    const planData = state.planData.slice();

    const previousTask = findLastSubRow(
      state.planData,
      state.planData.length - 1
    );

    const data = addNewSubRow(
      row,
      planData,
      previousTask,
      state.plan,
      state.abbreviations,
      parentIndex
    );

    return { ...state, planData: data };
  }

  if (action.type === ADD_BLANK_BELOW) {
    const { row, parentIndex, subrowIndex, selectedRow } = action.payload;

    const planData = state.planData.slice();

    const previousTask =
      _.isNaN(subrowIndex) || _.isNil(subrowIndex) || subrowIndex < 0
        ? findLastSubRow(planData, parentIndex - 1)
        : planData[parentIndex].subRows[subrowIndex];

    const data = addNewSubRow(
      row,
      planData,
      previousTask,
      state.plan,
      state.abbreviations,
      parentIndex,
      subrowIndex,
      selectedRow
    );

    return { ...state, planData: data };
  }

  if (action.type === REMOVE_ROW) {
    const { index, subrowIndex } = action.payload;

    return removeRow(state, index, subrowIndex);
  }

  if (action.type === TOGGLE_PINNED_COLUMN) {
    const { accessor, width } = action.payload;
    return updateColumn(state, accessor, column => ({
      ...column,
      pinned: !column.pinned,
      width,
    }));
  }

  if (action.type === UPDATE_COLUMN_WIDTH) {
    const { accessor, width } = action.payload;
    return updateColumn(state, accessor, column => ({
      ...column,
      width,
    }));
  }

  if (action.type === SET_COLUMN_FILTER_TYPE) {
    const { accessor, filterType } = action.payload;
    return updateColumn(state, accessor, column => ({
      ...column,
      filterType,
    }));
  }

  if (action.type === UPDATE_COLUMN_ALIAS) {
    const { accessor, alias } = action.payload;
    return updateColumn(state, accessor, column => ({ ...column, alias }));
  }

  if (action.type === MOVE_ROWS) {
    const { movedRowIndexes, destRowIndex } = action.payload;

    if (!state.planData[destRowIndex.index].isHeader) {
      destRowIndex.subrowIndex = undefined;
    }

    const planData = moveRows(
      state.plan,
      state.planData,
      destRowIndex,
      movedRowIndexes,
      state.abbreviations
    );

    return { ...state, planData: calculateTotals(planData) };
  }

  if (action.type === SELECT_ROW) {
    const { rowId } = action.payload;
    const selectedRows = state.selectedRows.slice();

    const selected = _.some(selectedRows, x => x.rowId === rowId)
      ? selectedRows.filter(x => x.rowId !== rowId)
      : selectedRows.concat([action.payload]);

    return { ...state, selectedRows: selected };
  }

  if (action.type === PROMOTE_HEADER) {
    const { index: parentIndex, subrowIndex } = action.payload;

    const planData = promoteHeader(
      parentIndex,
      subrowIndex,
      state.plan,
      state.planData
    );

    return { ...state, planData };
  }

  if (action.type === DEMOTE_HEADER) {
    const {
      rowIndex: { index },
    } = action.payload;

    const planData = demoteHeader(
      index,
      state.plan,
      state.planData,
      state.abbreviations
    );

    return { ...state, planData };
  }

  if (action.type === COPY_DOWN_VALUE) {
    const { value, columnId, orderInExport } = action.payload;
    let planData = state.planData.slice();

    planData.forEach(header =>
      header.subRows.forEach(row => {
        if (row.orderInExport > orderInExport) {
          row[columnId] = value;
          row.modified = true;
          row.lastModified = Date.now();
        }
      })
    );

    return { ...state, planData };
  }

  if (action.type === RESEQUENCE_PLAN) {
    const dataCopy = state.planData.slice();
    //this mapping allows easy access to the header
    let mappedResult: Array<{
      header: PlanLineItemState;
      subrow: PlanLineItemState;
    }>;

    mappedResult = _.flatMap(state.planData, x => {
      return x.subRows.map(y => {
        return { header: x, subrow: y };
      });
    });

    const itemsOutOfSequence = _.filter(
      mappedResult,
      x => !x.subrow.isInSequence
    );

    //move subrows around
    itemsOutOfSequence.forEach(item => {
      const headerIndex = dataCopy.findIndex(
        x => x.sequence === item.subrow.sequence && x.isHeader
      );

      //if the header for a sequence doesn't exist, we do nothing
      if (headerIndex >= 0) {
        const header: Readonly<PlanLineItemState> = {
          ...dataCopy[headerIndex],
          subRows: dataCopy[headerIndex].subRows.slice(),
        };

        header.subRows.push(item.subrow);
        const removeIndex = item.header.subRows.findIndex(
          z => z.clientId === item.subrow.clientId
        );

        item.header.subRows.splice(removeIndex, 1);

        addHeaderCalculatedFields(header, true);
        addHeaderCalculatedFields(item.header, true);

        dataCopy[headerIndex] = header;
      }
    });

    //move headers around based on sequence number
    const sortedPlanData = _.sortBy(dataCopy, x => x.sequence);

    //refresh the row orders for the entire plan
    updateRowOrders(sortedPlanData, 0, 1);
    sortedPlanData.forEach((x, i) => calculateSequence(sortedPlanData, i));

    return { ...state, planData: calculateTotals(sortedPlanData) };
  }

  if (action.type === SET_DEFAULT_VALUE_COLUMNS) {
    const { defaultValues } = action.payload;
    return { ...state, defaultValueColumns: defaultValues };
  }

  if (action.type === TOGGLE_HEADER) {
    const {
      rowIndex: { index, subrowIndex },
    } = action.payload;

    const isHeader = _.isNil(subrowIndex)
      ? state.planData[index].isHeader
      : state.planData[index].subRows[subrowIndex].isHeader;

    if (isHeader) {
      const planData = demoteHeader(
        index,
        state.plan,
        state.planData,
        state.abbreviations
      );

      return { ...state, planData, selectedRows: [] };
    } else {
      const planData = promoteHeader(
        index,
        subrowIndex,
        state.plan,
        state.planData
      );

      return { ...state, planData, selectedRows: [] };
    }
  }

  if (action.type === SET_PREVIOUS_VALUE_COLUMNS) {
    const { previousValues } = action.payload;
    return { ...state, previousValueColumns: previousValues };
  }

  if (action.type === DUPLICATE_ROWS) {
    let planData = state.planData.slice();
    let maxOrder = findMaxOrder(planData) + 1;
    const sortedSelection = _.sortBy(state.selectedRows, x => x);

    sortedSelection.forEach(selected => {
      const [index, subrowIndex] = selected.rowId.split(".");
      const rowIndex = {
        index: Number(index),
        subrowIndex: _.isUndefined(subrowIndex) ? null : Number(subrowIndex),
      };

      planData = duplicateRow(
        planData,
        rowIndex,
        state.plan,
        maxOrder++,
        state.defaultValueColumns,
        state.previousValueColumns
      );
    });

    return { ...state, planData, selectedRows: [] };
  }

  if (action.type === REMOVE_SELECTED_ROWS) {
    let mutativeState = { ...state };

    const [subrowsSelected, headersSelected] = _.partition(
      state.selectedRows,
      x => x.rowId.includes(".")
    );
    const sortedSubrows = _.sortBy(subrowsSelected, x =>
      Number(x.rowId.split(".").join(""))
    ).reverse();
    const sortedHeaders = _.sortBy(headersSelected, x =>
      Number(x.rowId)
    ).reverse();

    const sortedSelected = [...sortedSubrows, ...sortedHeaders];

    sortedSelected.forEach(selected => {
      const [index, subrowIndex] = selected.rowId.split(".");
      const indexParsed = Number(index);
      const subrowIndexParsed = _.isUndefined(subrowIndex)
        ? undefined
        : Number(subrowIndex);

      if (selected.canDelete) {
        mutativeState = removeRow(
          mutativeState,
          indexParsed,
          subrowIndexParsed
        );
      }
    });

    return { ...mutativeState, selectedRows: [] };
  }

  if (action.type === ADD_ABBR) {
    const { abbr, quantity } = action.payload;
    const selectedRows = state.selectedRows;
    let planDataCopy = state.planData.slice();

    _.forEach(selectedRows, selected => {
      _applyAbbr(selected.rowId, abbr, quantity, state)(planDataCopy);
    });

    planDataCopy = calculateTotals(planDataCopy);

    return { ...state, planData: planDataCopy, selectedRows: [] };
  }

  if (action.type === ADD_ABBR_EXPLICIT) {
    const { abbr, rowId } = action.payload;
    let planDataCopy = state.planData.slice();
    _applyAbbr(rowId, abbr, null, state)(planDataCopy);

    return { ...state, planData: planDataCopy };
  }

  if (action.type === GET_COL_PREFERENCES) {
    const { columns } = action.payload;
    let stateColumns = state.columns.slice();
    let mappedColumns = Array<ColumnInfo>();

    _.forEach(stateColumns, x => {
      const foundPreference = columns.find(
        y => y.columnAccessor === x.accessor
      );

      if (foundPreference) {
        mappedColumns.push({
          ...x,
          width: foundPreference.columnWidth ?? x.width,
          pinned: foundPreference.isPinned ?? x.pinned,
          hidden: foundPreference.isHidden ?? x.hidden,
          order: foundPreference.order ?? x.order,
        });
      } else {
        mappedColumns.push(x);
      }
    });

    return { ...state, columns: mappedColumns };
  }

  if (action.type === EXPORT_VALIDATION_ERRORS) {
    const validate = (line: PlanLineItemState): PlanLineItemState => {
      const err = action.payload.find(x => x.clientId === line.clientId);
      if (err) {
        return err;
      }

      return line.errors ? { ...line, errors: {} } : line;
    };

    const planData = state.planData.map(line => {
      const subRows = line.subRows.map(validate);
      let curr = validate(line);

      if (curr === line) curr = { ...curr, subRows };
      else curr.subRows = subRows;

      return curr;
    });

    return { ...state, planData: planData };
  }

  if (action.type === ADD_HARD_FACTOR) {
    let hardFactors = state.hardFactors.slice();
    return { ...state, hardFactors: hardFactors.concat(action.payload) };
  }

  if (action.type === DELETE_HARD_FACTOR) {
    const hardFactor = action.payload;
    let factorToDelete = { ...hardFactor };
    let hardFactors = state.hardFactors.slice();

    if (factorToDelete.id === 0) {
      let index = hardFactors.findIndex(x => _.isEqual(x, factorToDelete));
      hardFactors.splice(index, 1);
    } else {
      factorToDelete.isDeleted = true;
      let index = hardFactors.findIndex(x => x.id === factorToDelete.id);
      hardFactors.splice(index, 1, factorToDelete);
    }
    return { ...state, hardFactors };
  }

  if (action.type === SAVE_HARD_FACTORS) {
    const plan = { ...state.plan, hardFactors: state.plan.hardFactors.slice() };
    plan.hardFactors = action.payload;

    let planData = state.planData.map(line => {
      line.subRows.map(row => {
        setHardFactors(row, plan.hardFactors);
        addCalculatedFields(
          row,
          state.plan.factorTemplate,
          state.abbreviations
        );

        return row;
      });

      addHeaderCalculatedFields(line, true);

      return line;
    });

    planData = calculateTotals(planData);

    return { ...state, plan, planData, hardFactors: action.payload };
  }

  if (action.type === RESET_HARD_FACTORS) {
    return { ...state, hardFactors: state.plan?.hardFactors };
  }

  if (action.type === CLEAR_HARD_FACTORS) {
    return { ...state, hardFactors: null };
  }

  if (action.type === UPDATE_HARD_FACTOR) {
    const { hardFactor, field, value } = action.payload;
    let hf = { ...hardFactor };
    hf[field] = value;
    let hfs = state.hardFactors.slice();
    let hfIndex = hfs.findIndex(x => x === hardFactor);
    hfs.splice(hfIndex, 1, hf);

    addHardFactorCalculations(hfs, state.planData);

    return { ...state, hardFactors: hfs };
  }

  if (action.type === GET_COL_DEFAULTS) {
    const columnDefaults = action.payload;
    let defaultValueColumns = [] as DefaultValue[];
    let previousValueColumns = [] as PreviousValue[];
    columnDefaults.forEach(x => {
      defaultValueColumns = defaultValueColumns.concat({
        id: x.columnName,
        value: x.defaultValue,
      });
      previousValueColumns = previousValueColumns.concat({
        colAccessor: x.columnName,
        enabled: x.isDuplicatingPrevious,
      });
    });

    return { ...state, defaultValueColumns, previousValueColumns };
  }

  return state;
};

export default reduce;

import React from "react";
import { connect } from "react-redux";
import AuthenticationContainer from "../../features/auth/AuthContainer";

const Forbidden = (props) => {
  return (
    <AuthenticationContainer loading={false}>
      <div>
        <p>
          This account does not have the necessary permissions to perform the
          requested action.
        </p>
        <p>Contact your administrator to request permission.</p>
      </div>
    </AuthenticationContainer>
  );
};

export default connect()(Forbidden);

import React from "react";
import { Menu, StrictMenuItemProps, Button, Input } from "semantic-ui-react";
import { Column } from "./react-table";
import { ColumnInfo } from ".";
import { FilterOperations, TextOps, NumberOps } from "./types";
import { SetColumnFilterTypeDispatch } from "../../me/plans/actions";
import { PlanLineItemDto, CalculatedFields } from "../../../models";
import styled from "@emotion/styled";

//TODO: rows type?
type FilterFunction = (rows: any[], id: string, filterValue: any) => any[];

type FilterTypes = {
  [P in FilterOperations]: FilterFunction;
};

type MenuItemProps = StrictMenuItemProps & {
  name: FilterOperations;
};

type MenuClickEventHandler = (
  event: React.MouseEvent<HTMLAnchorElement>,
  data: MenuItemProps
) => void;

type TextFilterMenuProps = {
  activeItem: TextOps;
  handleItemClick: MenuClickEventHandler;
};

type NumberFilterMenuProps = {
  activeItem: NumberOps;
  handleItemClick: MenuClickEventHandler;
};

type FilterMenuProps = TextFilterMenuProps | NumberFilterMenuProps;

const menuStyle = {
  position: "absolute",
  zIndex: 120,
  marginTop: "0",
};

const DefaultColumnFilterRoot = styled.div`
  display: flex;

  .ui.input {
    font-size: 0.7em;
    flex: 1;
  }

  .ui.basic.button.icon {
    padding: 6px;
    margin-right: 6px;
  }
`;

function TextFilterMenu({ activeItem, handleItemClick }: TextFilterMenuProps) {
  return (
    <Menu size="mini" vertical style={menuStyle}>
      <Menu.Item
        name="equal"
        active={activeItem === "equal"}
        onClick={handleItemClick}
      >
        Equal
      </Menu.Item>
      <Menu.Item
        name="notEqual"
        active={activeItem === "notEqual"}
        onClick={handleItemClick}
      >
        Not Equal
      </Menu.Item>
      <Menu.Item
        name="startsWith"
        active={activeItem === "startsWith"}
        onClick={handleItemClick}
      >
        Starts With
      </Menu.Item>
      <Menu.Item
        name="contains"
        active={activeItem === "contains"}
        onClick={handleItemClick}
      >
        Contains
      </Menu.Item>
      <Menu.Item
        name="endsWith"
        active={activeItem === "endsWith"}
        onClick={handleItemClick}
      >
        Ends With
      </Menu.Item>
      <Menu.Item
        name="doesNotContain"
        active={activeItem === "doesNotContain"}
        onClick={handleItemClick}
      >
        Does Not Contain
      </Menu.Item>
      <Menu.Item
        name="doesNotStartWith"
        active={activeItem === "doesNotStartWith"}
        onClick={handleItemClick}
      >
        Does Not Start With
      </Menu.Item>

      <Menu.Item
        name="doesNotEndWith"
        active={activeItem === "doesNotEndWith"}
        onClick={handleItemClick}
      >
        Does Not End With
      </Menu.Item>
    </Menu>
  );
}

function NumberFilterMenu({
  activeItem,
  handleItemClick,
}: NumberFilterMenuProps) {
  return (
    <Menu size="mini" vertical style={menuStyle}>
      <Menu.Item
        name="eq"
        active={activeItem === "eq"}
        onClick={handleItemClick}
      >
        Equal
      </Menu.Item>
      <Menu.Item
        name="notEqual"
        active={activeItem === "notEq"}
        onClick={handleItemClick}
      >
        Not Equal
      </Menu.Item>
      <Menu.Item
        name="greaterThan"
        active={activeItem === "greaterThan"}
        onClick={handleItemClick}
      >
        Greater Than
      </Menu.Item>
      <Menu.Item
        name="greaterThanEqj"
        active={activeItem === "greaterThanEq"}
        onClick={handleItemClick}
      >
        Greater Than or Equal
      </Menu.Item>
      <Menu.Item
        name="lessThan"
        active={activeItem === "lessThan"}
        onClick={handleItemClick}
      >
        Less Than
      </Menu.Item>
      <Menu.Item
        name="lessThanEq"
        active={activeItem === "lessThanEq"}
        onClick={handleItemClick}
      >
        Less Than Or Equal
      </Menu.Item>
    </Menu>
  );
}

function FilterMenu(props: FilterMenuProps) {
  const { activeItem, handleItemClick } = props;
  switch (activeItem) {
    case "eq":
    case "notEq":
    case "greaterThan":
    case "greaterThanEq":
    case "lessThan":
    case "lessThanEq":
      return (
        <NumberFilterMenu
          activeItem={activeItem}
          handleItemClick={handleItemClick}
        />
      );
    default:
      return (
        <TextFilterMenu
          activeItem={activeItem}
          handleItemClick={handleItemClick}
        />
      );
  }
}

type ColumnFilterProps = {
  column: Column;
  setColumnFilterType: SetColumnFilterTypeDispatch;
};

export function DefaultColumnFilter({
  column: { id, filter, filterValue, preFilteredRows, setFilter },
  setColumnFilterType,
}: ColumnFilterProps) {
  const [showMenu, setShowMenu] = React.useState(false);

  const _handleItemClick = React.useCallback<MenuClickEventHandler>(
    (e, { name }) => {
      setColumnFilterType(id, name);
      setShowMenu(false);
    },
    [id, setColumnFilterType]
  );

  const _handleButtonClick = React.useCallback(
    _ => {
      setShowMenu(!showMenu);
    },
    [showMenu]
  );

  return (
    <DefaultColumnFilterRoot className="filter">
      <Button
        basic
        secondary
        icon="filter"
        size="mini"
        onClick={_handleButtonClick}
      />
      {showMenu ? (
        <FilterMenu activeItem={filter} handleItemClick={_handleItemClick} />
      ) : null}
      <Input
        value={filterValue || ""}
        type="search"
        onChange={e => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
      />
    </DefaultColumnFilterRoot>
  );
}

const makeFilter =
  (pred: (rowValue, filterValue) => boolean) =>
  (rows: any[], id: string, filterValue: any): any[] => {
    return rows.filter(row => {
      const rowValue = row.values[id];
      var headResult = pred(rowValue, filterValue);

      return (
        headResult ||
        (row.depth === 0 &&
          row.subRows.some(x => pred(x.values[id], filterValue)))
      );
    });
  };

const textFilter = (
  pred: (rowValue: string, filterValue: string) => boolean
): ReturnType<typeof makeFilter> => {
  const wrapped = (rowValue, filterValue) =>
    pred(String(rowValue).toLowerCase(), String(filterValue).toLowerCase());
  return makeFilter(wrapped);
};

const numberFilter = (
  pred: (rowValue: number, filterValue: number) => boolean
): ReturnType<typeof makeFilter> => {
  const wrapped = (rowValue, filterValue) =>
    pred(Number(rowValue), Number(filterValue));
  return makeFilter(wrapped);
};

export const filterTypes: FilterTypes = {
  //text
  equal: textFilter((rowValue, filterValue) => rowValue === filterValue),

  notEqual: textFilter((rowValue, filterValue) => rowValue !== filterValue),

  startsWith: textFilter((rowValue, filterValue) =>
    rowValue.startsWith(filterValue)
  ),

  doesNotStartWith: textFilter(
    (rowValue, filterValue) => !rowValue.startsWith(filterValue)
  ),

  endsWith: textFilter((rowValue, filterValue) =>
    rowValue.endsWith(filterValue)
  ),

  doesNotEndWith: textFilter(
    (rowValue, filterValue) => !rowValue.endsWith(filterValue)
  ),

  contains: textFilter((rowValue, filterValue) =>
    rowValue.includes(filterValue)
  ),

  doesNotContain: textFilter(
    (rowValue, filterValue) => !rowValue.includes(filterValue)
  ),

  //number
  eq: numberFilter((rowValue, filterValue) => rowValue === filterValue),

  notEq: numberFilter((rowValue, filterValue) => rowValue !== filterValue),

  greaterThan: numberFilter((rowValue, filterValue) => rowValue > filterValue),

  greaterThanEq: numberFilter(
    (rowValue, filterValue) => rowValue >= filterValue
  ),

  lessThan: numberFilter((rowValue, filterValue) => rowValue < filterValue),

  lessThanEq: numberFilter((rowValue, filterValue) => rowValue <= filterValue),
};

const defaultFilters: Partial<
  Record<keyof PlanLineItemDto | keyof CalculatedFields, FilterOperations>
> = {
  numberOfMen: "eq",
  quantity: "eq",
  user: "eq",
  duration: "eq",
  rate: "eq",
  baseFactor: "eq",
  bumped: "eq",
  baseHours: "eq",
  bumpedHr: "eq",
  dollars: "eq",
  runningTotal: "eq",
  runningHrTotal: "eq",
  bumpedRunningHrTotal: "eq",
  miscellaneousNumeric1: "eq",
  miscellaneousNumeric2: "eq",
  miscellaneousNumeric3: "eq",
  miscellaneousNumeric4: "eq",
  miscellaneousNumeric5: "eq",
  miscellaneousNumeric6: "eq",

  sequence: "contains",
  abbr: "contains",
  actNo: "contains",
  description: "contains",
  area: "contains",
  notes: "contains",
  drawingNumber: "contains",
  equipmentNumber: "contains",
  typeOfWork: "contains",
  resource: "contains",
  time: "contains",
  company: "contains",
  tpNumber: "contains",
  size1: "contains",
  tag: "contains",
  miscellaneousField1: "contains",
  miscellaneousField2: "contains",
  miscellaneousField3: "contains",
  miscellaneousField4: "contains",
  miscellaneousField5: "contains",
  miscellaneousField6: "contains",
};

export function getDefaultFilterType(col: ColumnInfo): FilterOperations {
  return defaultFilters[col.accessor];
}

import { Hooks, Row } from "react-table";

const pluginName = "useRowDescriptor";

export const useRowDescriptor = (hooks: Hooks) => {
  hooks.prepareRow.push(prepareRow);
};

useRowDescriptor.pluginName = pluginName;

export const prepareRow = (row: Row) => {
  if (row.original) {
    row.canDelete =
      !row.original.isHeader || row.index !== 0 || row.subRows.length === 0;

    row.canDemote = !(row.original.isHeader && row.index === 0);
  } else {
    row.canDelete = row.canDemote = false;
  }
};

import { Api } from "../../util/api/api";
import {
  AbbreviationDescriptionGetDto,
  AbbreviationGetDto,
} from "../../models";

export const LOAD_ABBREVIATIONS = "LOAD_ABBREVIATIONS";
export const LOAD_ABBREVIATIONS_DESCRIPTIONS =
  "LOAD_ABBREVIATIONS_DESCRIPTIONS";
export const DOWNLOAD_ABBREVIATIONS = "DOWNLOAD_ABBREVIATIONS";
export const DOWNLOAD_ABBREVIATIONS_FINISHED =
  "DOWNLOAD_ABBREVIATIONS_FINISHED";

export interface State {
  abbreviations?: AbbreviationGetDto[];
  abbreviationDescriptions?: AbbreviationDescriptionGetDto[];
  loading?: boolean;
}

interface LoadAbbreviations {
  type: typeof LOAD_ABBREVIATIONS;
  value: AbbreviationGetDto[];
}

interface LoadAbbreviationsDescriptions {
  type: typeof LOAD_ABBREVIATIONS_DESCRIPTIONS;
  value: AbbreviationDescriptionGetDto[];
}

interface DownloadAbbreviations {
  type: typeof DOWNLOAD_ABBREVIATIONS;
}

interface DownloadAbbreviationsFinished {
  type: typeof DOWNLOAD_ABBREVIATIONS_FINISHED;
}

export const loadAbbreviations = dispatch => {
  return (api: Api): Promise<any> => {
    return api.get("/abbreviations").then(response => {
      if (!response.hasErrors) {
        dispatch({ type: LOAD_ABBREVIATIONS, value: response.result });
        return response;
      } else {
        return Promise.reject(response.errors.map(x => x.errorMessage));
      }
    });
  };
};

export type LoadAbbreviationsDispatch = ReturnType<typeof loadAbbreviations>;

export const loadAbbreviationsDescriptions = dispatch => {
  return (api: Api): Promise<any> => {
    return api.get("/abbreviations/descriptions").then(response => {
      if (!response.hasErrors) {
        dispatch({
          type: LOAD_ABBREVIATIONS_DESCRIPTIONS,
          value: response.result,
        });
        return response;
      } else {
        return Promise.reject(response.errors.map(x => x.errorMessage));
      }
    });
  };
};

export type LoadAbbreviationsDescriptionsDispatch = ReturnType<
  typeof loadAbbreviationsDescriptions
>;

export const downloadAbbreviations = dispatch => {
  return (api: Api) => {
    dispatch({ type: DOWNLOAD_ABBREVIATIONS });
    return api
      .getStream(`/abbreviations/descriptions/download`, {})
      .finally(() => {
        dispatch({ type: DOWNLOAD_ABBREVIATIONS_FINISHED });
      });
  };
};

export type DownloadAbbreviationsDispatch = ReturnType<
  typeof downloadAbbreviations
>;

export type Actions =
  | LoadAbbreviations
  | LoadAbbreviationsDescriptions
  | DownloadAbbreviations
  | DownloadAbbreviationsFinished;

import React from "react";
import {
  AbbreviationGetDto,
  ErrorMap,
  PlanLineItemState,
  Response,
  SavePlanLinesRequest,
  RowIndex,
  PlanDataGetDto,
  PlanLineItemDto,
  NewPlanLineItemState,
  SelectedRowDescriptor,
  HardFactorDto,
  ColumnDefaultDto,
  PlanGetDto,
} from "../../../models";
import { Api } from "../../../util/api/api";
import { ColumnInfo, ColumnInfoDto } from "../../components/grid";
import { FilterOperations } from "../../components/grid/types";
import { batch } from "react-redux";
import { makeErrorMaps, getErrorMessage } from "../../../util";
import { toast } from "react-toastify";
import _ from "lodash";
import { PreviousValue, DefaultValue } from "../../../models/grid";
import { ExportValidationErrors } from "../actions";

export const ADD_ABBR = "AddAbbr";
export const ADD_ABBR_EXPLICIT = "ADD_ABBR_EXPLICIT";
export const ADD_BLANK = "AddBlank";
export const ADD_BLANK_BELOW = "AddBlankBelow";
export const ADD_HEADER = "AddHeader";
export const COPY_DOWN_VALUE = "CopyDownValue";
export const DEMOTE_HEADER = "DemoteHeader";
export const DUPLICATE_ROWS = "DuplicateRows";
export const GET_COL_PREFERENCES = "GetColPreferences";
export const INIT_PLAN_DATA = "InitPlanData";
export const MOVE_ROWS = "MoveRow";
export const PROMOTE_HEADER = "PromoteHeader";
export const REMOVE_ROW = "RemoveRow";
export const REMOVE_SELECTED_ROWS = "RemoveSelectedRows";
export const RESEQUENCE_PLAN = "ResequencePlan";
export const SAVE_COL_PREFERENCES = "SaveColPreferences";
export const SAVE_PLAN_DATA = "SavePlanData";
export const SELECT_ROW = "SelectRow";
export const SET_COLUMN_FILTER_TYPE = "SetColumnFilterType";
export const SET_DEFAULT_VALUE_COLUMNS = "SetDefaultValueColumns";
export const SET_PREVIOUS_VALUE_COLUMNS = "SetPreviousValueColumns";
export const TOGGLE_HEADER = "ToggleHeader";
export const TOGGLE_PINNED_COLUMN = "TogglePinnedColumn";
export const UPDATE_COLUMN = "UpdateColumn";
export const UPDATE_COLUMN_ORDER = "UpdateColumnOrder";
export const UPDATE_COLUMN_WIDTH = "UpdateColumnWidth";
export const UPDATE_COLUMN_ALIAS = "UpdateColumnAlias";
export const UPDATE_ROW = "UpdateRow";
export const UPDATE_ROW_DATA = "UpdateRowData";
export const ADD_HARD_FACTOR = "ADD_HARD_FACTOR";
export const DELETE_HARD_FACTOR = "DELETE_HARD_FACTOR";
export const SAVE_HARD_FACTORS = "SAVE_HARD_FACTORS";
export const RESET_HARD_FACTORS = "RESET_HARD_FACTORS";
export const UPDATE_HARD_FACTOR = "UPDATE_HARD_FACTOR";
export const CLEAR_HARD_FACTORS = "CLEAR_HARD_FACTORS";
export const GET_COL_DEFAULTS = "GET_COL_DEFAULTS";

export interface UpdateRowData {
  type: typeof UPDATE_ROW_DATA;
  payload: {
    rowIndex: number;
    subrowIndex: number;
    valid: boolean;
    errors: ErrorMap<PlanLineItemState>;
    updates: { [P in keyof PlanLineItemState]?: any };
  };
}

interface InitPlanData {
  type: typeof INIT_PLAN_DATA;
  payload: {
    plan: Readonly<PlanDataGetDto>;
    abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>;
    userId: Readonly<number>;
  };
}

export interface SavePlanData {
  type: typeof SAVE_PLAN_DATA;
  payload: { saveTime: number } & SavePlanLinesRequest;
}

interface UpdateColumn {
  type: typeof UPDATE_COLUMN;
  payload: ColumnInfo;
}

interface UpdateColumnOrder {
  type: typeof UPDATE_COLUMN_ORDER;
  payload: { accessor: keyof PlanLineItemState; order: number };
}

interface TogglePinnedColumn {
  type: typeof TOGGLE_PINNED_COLUMN;
  payload: { accessor: keyof PlanLineItemState; width: number };
}

interface UpdateColumnWidth {
  type: typeof UPDATE_COLUMN_WIDTH;
  payload: { accessor: keyof PlanLineItemState; width: number };
}

interface UpdateColumnAlias {
  type: typeof UPDATE_COLUMN_ALIAS;
  payload: { accessor: keyof PlanLineItemState; alias: string };
}

interface SetColumnFilterType {
  type: typeof SET_COLUMN_FILTER_TYPE;
  payload: { accessor: keyof PlanLineItemState; filterType: FilterOperations };
}

export interface AddHeader {
  type: typeof ADD_HEADER;
  payload: { row: NewPlanLineItemState };
}

export interface AddBlank {
  type: typeof ADD_BLANK;
  payload: {
    row: NewPlanLineItemState;
    parentIndex: number;
  };
}

export interface AddBlankBelow {
  type: typeof ADD_BLANK_BELOW;
  payload: {
    row: NewPlanLineItemState;
    parentIndex: number;
    subrowIndex: number;
    selectedRow: PlanLineItemDto;
  };
}

export interface MoveRow {
  type: typeof MOVE_ROWS;
  payload: {
    movedRowIndexes: RowIndex[];
    destRowIndex: RowIndex;
  };
}

export interface RemoveRow {
  type: typeof REMOVE_ROW;
  payload: { index: number; subrowIndex?: number };
}

export interface UpdateRow {
  type: typeof UPDATE_ROW;
  payload: PlanLineItemState;
}

export interface SelectRow {
  type: typeof SELECT_ROW;
  payload: SelectedRowDescriptor;
}

export interface PromoteHeader {
  type: typeof PROMOTE_HEADER;
  payload: RowIndex;
}

export interface DemoteHeader {
  type: typeof DEMOTE_HEADER;
  payload: {
    rowIndex: RowIndex;
  };
}

export interface ResequencePlan {
  type: typeof RESEQUENCE_PLAN;
  payload: {};
}

export interface CopyDownValue {
  type: typeof COPY_DOWN_VALUE;
  payload: { value: any; columnId: string; orderInExport: number };
}

export interface SetDefaultValueColumns {
  type: typeof SET_DEFAULT_VALUE_COLUMNS;
  payload: { defaultValues: DefaultValue[] };
}

export interface SetPreviousValueColumns {
  type: typeof SET_PREVIOUS_VALUE_COLUMNS;
  payload: {
    previousValues: PreviousValue[];
  };
}

export interface ToggleHeader {
  type: typeof TOGGLE_HEADER;
  payload: {
    rowIndex: RowIndex;
  };
}

export interface DuplicateRows {
  type: typeof DUPLICATE_ROWS;
}

export interface RemoveSelectedRows {
  type: typeof REMOVE_SELECTED_ROWS;
}

export interface AddAbbr {
  type: typeof ADD_ABBR;
  payload: { abbr: AbbreviationGetDto; quantity: number };
}

export interface AddAbbrExplicit {
  type: typeof ADD_ABBR_EXPLICIT;
  payload: { abbr: AbbreviationGetDto; rowId: string };
}

export interface SaveColumnPreferences {
  type: typeof SAVE_COL_PREFERENCES;
}

export interface GetColumnPreferences {
  type: typeof GET_COL_PREFERENCES;
  payload: { columns: ColumnInfoDto[] };
}

export interface AddHardFactor {
  type: typeof ADD_HARD_FACTOR;
  payload: HardFactorDto;
}

export interface DeleteHardFactor {
  type: typeof DELETE_HARD_FACTOR;
  payload: HardFactorDto;
}

export interface SaveHardFactors {
  type: typeof SAVE_HARD_FACTORS;
  payload: HardFactorDto[];
}

export interface ResetHardFactors {
  type: typeof RESET_HARD_FACTORS;
}

export interface UpdateHardFactor {
  type: typeof UPDATE_HARD_FACTOR;
  payload: {
    hardFactor: HardFactorDto;
    field: string;
    value: any;
  };
}

export interface ClearHardFactors {
  type: typeof CLEAR_HARD_FACTORS;
}

export interface GetColumnDefaults {
  type: typeof GET_COL_DEFAULTS;
  payload: ColumnDefaultDto[];
}

export const initPlanData = (dispatch: React.Dispatch<InitPlanData>) => {
  return (
    api: Api,
    plan: Readonly<PlanGetDto>,
    abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>,
    userId: Readonly<number>
  ) => {
    return api
      .get<Response<PlanDataGetDto>>(`/plans/${plan.id}`)
      .then(response => {
        if (!response.hasErrors) {
          dispatch({
            type: INIT_PLAN_DATA,
            payload: {
              plan: response.result,
              abbreviations,
              userId,
            },
          });

          return response;
        } else {
          return Promise.reject(response.errors.map(x => x.errorMessage));
        }
      });
  };
};

export type InitPlanDataDispatch = ReturnType<typeof initPlanData>;

function validate(
  column: keyof PlanLineItemState,
  value: any
): [boolean, ErrorMap<PlanLineItemState>] {
  const required = ["actNo", "sequence"];
  let errors: ErrorMap<PlanLineItemState> = {};
  let valid = true;

  if (required.includes(column) && _.isEmpty(value)) {
    console.log(`'${value}' is not a valid value for '${column}'`);
    errors[column] = { type: "error", description: "required" };
    valid = false;
  }

  return [valid, errors];
}

export const updateRowData = (dispatch: React.Dispatch<UpdateRowData>) => {
  return (
    rowIndex: number,
    subrowIndex: number,
    updates: { [P in keyof PlanLineItemState]: any }
  ) => {
    if (Object.keys(updates).length > 0) {
      const [valid, errors] = Object.keys(updates)
        .map((columnId: keyof PlanLineItemState) =>
          validate(columnId, updates[columnId])
        )
        .reduce(([valid, errors], curr) => {
          return [valid && curr[0], { ...errors, ...curr[1] }];
        });

      dispatch({
        type: UPDATE_ROW_DATA,
        payload: {
          rowIndex,
          subrowIndex,
          valid,
          errors,
          updates,
        },
      });

      return valid;
    }

    return false;
  };
};

export type UpdateRowDataDispatch = ReturnType<typeof updateRowData>;

export const savePlanData = (
  dispatch: React.Dispatch<SavePlanData | UpdateRow>
) => {
  return (
    api: Api,
    planId: number,
    updates: readonly PlanLineItemState[],
    deletes: readonly number[]
  ) => {
    const data = {
      planId,
      lines: updates.map(x => {
        return { ...x };
      }),
      idsToDelete: deletes.slice(),
    };

    const saveTime = Date.now();

    return api
      .put<Response<{ clientId: string; id: number }[]>>("plans/lines", data)
      .then(resp => {
        if (resp.hasErrors) {
          const errorMaps = makeErrorMaps(resp.errors, data);
          errorMaps.forEach(x => {
            if (x.key === null) {
              toast.error((x.errors as string[]).join("\n"));
            } else {
              let line = x.accessor(data);
              line.hasErrors = true;
              line.errors = x.errors;
            }
          });

          batch(() => {
            data.lines.forEach(row => {
              dispatch({ type: UPDATE_ROW, payload: row });
            });
          });
        } else {
          resp.result.forEach(x => {
            const line = data.lines.find(y => x.clientId === y.clientId);
            line.id = x.id;
          });

          dispatch({ type: SAVE_PLAN_DATA, payload: { saveTime, ...data } });
        }

        return Promise.resolve(resp);
      });
  };
};

export type SavePlanDataDispatch = ReturnType<typeof savePlanData>;

export const updateColumn = (dispatch: React.Dispatch<UpdateColumn>) => {
  return (column: ColumnInfo) => {
    dispatch({ type: UPDATE_COLUMN, payload: column });
  };
};

export type UpdateColumnDispatch = ReturnType<typeof updateColumn>;

export const updateColumnOrder = (
  dispatch: React.Dispatch<UpdateColumnOrder>
) => {
  return ({ accessor, order }) => {
    dispatch({ type: UPDATE_COLUMN_ORDER, payload: { accessor, order } });
  };
};

export type UpdateColumnOrderDispatch = ReturnType<typeof updateColumnOrder>;

export const addHeader = (dispatch: React.Dispatch<AddHeader>) => {
  return ({ row }: { row: NewPlanLineItemState }) => {
    dispatch({ type: ADD_HEADER, payload: { row } });
  };
};

export type AddHeaderDispatch = ReturnType<typeof addHeader>;

export const addBlank = (dispatch: React.Dispatch<AddBlank>) => {
  return ({
    row,
    parentIndex,
  }: {
    row: NewPlanLineItemState;
    parentIndex: number;
  }) => {
    dispatch({ type: ADD_BLANK, payload: { row, parentIndex } });
  };
};

export type AddBlankDispatch = ReturnType<typeof addBlank>;

export const addBlankBelow = (dispatch: React.Dispatch<AddBlankBelow>) => {
  return ({
    row,
    parentIndex,
    subrowIndex,
    selectedRow,
  }: {
    row: NewPlanLineItemState;
    parentIndex: number;
    subrowIndex: number;
    selectedRow: PlanLineItemDto;
  }) => {
    dispatch({
      type: ADD_BLANK_BELOW,
      payload: { row, parentIndex, subrowIndex, selectedRow },
    });
  };
};

export type AddBlankBelowDispatch = ReturnType<typeof addBlankBelow>;

export const removeRow = (dispatch: React.Dispatch<RemoveRow>) => {
  return ({ index, subrowIndex }: { index: number; subrowIndex: number }) => {
    dispatch({ type: REMOVE_ROW, payload: { index, subrowIndex } });
  };
};
export type RemoveRowDispatch = ReturnType<typeof removeRow>;

export const togglePinnedColumn = (
  dispatch: React.Dispatch<TogglePinnedColumn>
) => {
  return (accessor: keyof PlanLineItemState, width: number) => {
    dispatch({ type: TOGGLE_PINNED_COLUMN, payload: { accessor, width } });
  };
};

export type TogglePinnedColumnDispatch = ReturnType<typeof togglePinnedColumn>;

export const updateColumnWidth = (
  dispatch: React.Dispatch<UpdateColumnWidth>
) => {
  return (accessor: keyof PlanLineItemState, width: number) => {
    dispatch({ type: UPDATE_COLUMN_WIDTH, payload: { accessor, width } });
  };
};

export type UpdateColumnWidthDispatch = ReturnType<typeof updateColumnWidth>;

export const updateColumnAlias = (
  dispatch: React.Dispatch<UpdateColumnAlias>
) => {
  return (accessor: keyof PlanLineItemState, alias: string) => {
    dispatch({ type: UPDATE_COLUMN_ALIAS, payload: { accessor, alias } });
  };
};

export type UpdateColumnAliasDispatch = ReturnType<typeof updateColumnAlias>;

export const setColumnFilterType = (
  dispatch: React.Dispatch<SetColumnFilterType>
) => {
  return (accessor, filterType) => {
    dispatch({
      type: SET_COLUMN_FILTER_TYPE,
      payload: { accessor, filterType },
    });
  };
};

export type SetColumnFilterTypeDispatch = ReturnType<
  typeof setColumnFilterType
>;

export const moveRows = (dispatch: React.Dispatch<MoveRow>) => {
  return (movedRowIndexes: RowIndex[], destRowIndex: RowIndex) => {
    dispatch({
      type: MOVE_ROWS,
      payload: {
        movedRowIndexes,
        destRowIndex,
      },
    });
  };
};

export type MoveRowsDispatch = ReturnType<typeof moveRows>;

export const selectRow = (dispatch: React.Dispatch<SelectRow>) => {
  return (rowId: string, canDelete: boolean, canDemote: boolean) => {
    dispatch({ type: SELECT_ROW, payload: { rowId, canDelete, canDemote } });
  };
};

export const promoteHeader = (dispatch: React.Dispatch<PromoteHeader>) => {
  return (index: RowIndex) => {
    dispatch({ type: PROMOTE_HEADER, payload: index });
  };
};

export type SelectRowDispatch = ReturnType<typeof selectRow>;
export type PromoteHeaderDispatch = ReturnType<typeof promoteHeader>;

export const demoteHeader = (dispatch: React.Dispatch<DemoteHeader>) => {
  return (index: RowIndex) => {
    dispatch({
      type: DEMOTE_HEADER,
      payload: { rowIndex: index },
    });
  };
};

export type DemoteHeaderDispatch = ReturnType<typeof demoteHeader>;

export const copyDownValue = (dispatch: React.Dispatch<CopyDownValue>) => {
  return (value: any, columnId: string, orderInExport: number) => {
    dispatch({
      type: COPY_DOWN_VALUE,
      payload: { value, columnId, orderInExport },
    });
  };
};

export type CopyDownValueDispatch = ReturnType<typeof copyDownValue>;

export const toggleHeader = (dispatch: React.Dispatch<ToggleHeader>) => {
  return (rowIndex: RowIndex) => {
    dispatch({
      type: TOGGLE_HEADER,
      payload: { rowIndex },
    });
  };
};

export type ToggleHeaderDispatch = ReturnType<typeof toggleHeader>;

export const resequencePlan = (dispatch: React.Dispatch<ResequencePlan>) => {
  return () => {
    dispatch({ type: RESEQUENCE_PLAN, payload: {} });
  };
};

export type ResequencePlanDispatch = ReturnType<typeof resequencePlan>;

export const setDefaultValueColumns = (
  dispatch: React.Dispatch<SetDefaultValueColumns>
) => {
  return (defaultValues: DefaultValue[]) => {
    dispatch({
      type: SET_DEFAULT_VALUE_COLUMNS,
      payload: { defaultValues: defaultValues },
    });
  };
};

export type SetDefaultValueColumnsDispatch = ReturnType<
  typeof setDefaultValueColumns
>;

export const setPreviousValueColumns = (
  dispatch: React.Dispatch<SetPreviousValueColumns>
) => {
  return (previousValues: PreviousValue[]) => {
    dispatch({
      type: SET_PREVIOUS_VALUE_COLUMNS,
      payload: { previousValues: previousValues },
    });
  };
};

export type SetPreviousValueColumnsDispatch = ReturnType<
  typeof setPreviousValueColumns
>;

export const duplicateRows = (dispatch: React.Dispatch<DuplicateRows>) => {
  return () => {
    dispatch({
      type: DUPLICATE_ROWS,
    });
  };
};

export type DuplicateRowsDispatch = ReturnType<typeof duplicateRows>;

export const removeSelectedRows = (
  dispatch: React.Dispatch<RemoveSelectedRows>
) => {
  return () => {
    dispatch({
      type: REMOVE_SELECTED_ROWS,
    });
  };
};

export type RemoveSelectedRowsDispatch = ReturnType<typeof removeSelectedRows>;

export const addAbbr = (dispatch: React.Dispatch<AddAbbr>) => {
  return ({
    abbr,
    quantity,
  }: {
    abbr: AbbreviationGetDto;
    quantity: number;
  }) => {
    dispatch({ type: ADD_ABBR, payload: { abbr, quantity } });
  };
};

export type AddAbbrDispatch = ReturnType<typeof addAbbr>;

export const addAbbrExplicit = (dispatch: React.Dispatch<AddAbbrExplicit>) => {
  return ({ abbr, rowId }: { abbr: AbbreviationGetDto; rowId: string }) => {
    dispatch({ type: ADD_ABBR_EXPLICIT, payload: { abbr, rowId } });
  };
};

export type AddAbbrExplicitDispatch = ReturnType<typeof addAbbrExplicit>;

//TODO: maybe add boolean saving state
export const saveColumnPreferences = (
  dispatch: React.Dispatch<SaveColumnPreferences>
) => {
  return (columns: Readonly<ColumnInfo[]>, api: Api) => {
    const mappedColumns = _.map<ColumnInfo, ColumnInfoDto>(columns, x => {
      return {
        isPinned: x.pinned,
        isHidden: x.hidden,
        order: x.order,
        columnAccessor: x.accessor,
        columnWidth: x.width,
      };
    });

    return api
      .put<Response<any>>("plans/columnPreferences", mappedColumns)
      .then(async result => {
        if (result.hasErrors) {
          toast.error(getErrorMessage(result.errors));
        } else {
          toast.info("Column preferences saved!");
        }
      });
  };
};

export type SaveColumnPreferencesDispatch = ReturnType<
  typeof saveColumnPreferences
>;

export const getColumnPreferences = dispatch => {
  return (api: Api) => {
    return api
      .get<Response<ColumnInfoDto[]>>("plans/columnPreferences")
      .then(async response => {
        if (response.hasErrors) {
          toast.error(getErrorMessage(response.errors));
        } else {
          dispatch({
            type: GET_COL_PREFERENCES,
            payload: { columns: response.result },
          });
        }
      });
  };
};

export type GetColumnPreferencesDispatch = ReturnType<
  typeof getColumnPreferences
>;

export const downloadPlanDataFile = (
  planId: number,
  api: Api
): Promise<any> => {
  return api
    .getFileWithFileName(`plans/${planId}/data-file/download`)
    .then(response => {
      const filename = response.filename;
      const blob = response.blob;
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${filename}`);
      link.setAttribute("style", "display: none");
      document.body.appendChild(link);
      link.click();
      window.setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 200);
      return Promise.resolve();
    })
    .catch(err => {
      if (err.response.status === 400) {
        toast.error("No template saved.");
      } else {
        toast.error(err);
      }
    });
};

export type DownloadPlanDataFileDispatch = ReturnType<
  typeof downloadPlanDataFile
>;

export const uploadPlanDataFile = dispatch => {
  return (planId: number, file: File, api: Api) => {
    var formData = new FormData();
    formData.append("dataFile", file, file.name);
    return api
      .post(`plans/${planId}/data-file`, formData)
      .then(async response => {
        if (response.hasErrors) {
          toast.error(getErrorMessage(response.errors));
        } else {
          toast.success("Plan Data File was uploaded.");
        }
      });
  };
};

export type UploadPlanDataFileDispatch = ReturnType<typeof uploadPlanDataFile>;

export const deletePlanDataFile = dispatch => {
  return (planId: number, api: Api) => {
    return api
      .delete(`plans/${planId}/data-file`, null)
      .then(async response => {
        if (response.hasErrors) {
          toast.error(getErrorMessage(response.errors));
        } else {
          toast.success("Plan Data File was deleted.");
        }
      });
  };
};

export type DeletePlanDataFileDispatch = ReturnType<typeof deletePlanDataFile>;

export const addHardFactor = dispatch => {
  return (hardFactor: HardFactorDto) => {
    dispatch({ type: ADD_HARD_FACTOR, payload: hardFactor });
  };
};

export type AddHardFactorDispatch = ReturnType<typeof addHardFactor>;

export const deleteHardFactor = dispatch => {
  return (hardFactor: HardFactorDto) => {
    dispatch({ type: DELETE_HARD_FACTOR, payload: hardFactor });
  };
};

export type DeleteHardFactorDispatch = ReturnType<typeof deleteHardFactor>;

export const saveHardFactors = dispatch => {
  return (
    hardFactors: HardFactorDto[],
    planId: number,
    api: Api
  ): Promise<Response<any>> => {
    const [updates, deletes] = _.partition(
      hardFactors,
      x => x.isDeleted === false
    );
    return api
      .put(`/plans/${planId}/hard-factors`, {
        hardFactors: updates,
        idsToDelete: deletes.map(x => x.id),
        planId,
      })
      .then(response => {
        if (response.hasErrors) {
          toast.error(getErrorMessage(response.errors));
        } else {
          dispatch({ type: SAVE_HARD_FACTORS, payload: response.result });
        }
        return response;
      });
  };
};

export type SaveHardFactorsDispatch = ReturnType<typeof saveHardFactors>;

export const resetHardFactors = dispatch => {
  return () => {
    dispatch({ type: RESET_HARD_FACTORS });
  };
};

export type ResetHardFactorsDispatch = ReturnType<typeof resetHardFactors>;

export const updateHardFactor = dispatch => {
  return (
    hardFactor: HardFactorDto,
    field: keyof HardFactorDto,
    value: any
  ) => {
    dispatch({
      type: UPDATE_HARD_FACTOR,
      payload: { hardFactor, field, value },
    });
  };
};

export type UpdateHardFactorDispatch = ReturnType<typeof updateHardFactor>;

export const saveColumnDefault = (
  columnDefault: ColumnDefaultDto,
  api: Api
) => {
  return api
    .put(`plans/${columnDefault.planId}/columnDefaults`, { columnDefault })
    .then(response => {
      if (response.hasErrors) {
        toast.error(getErrorMessage(response.errors));
      }
    });
};

export const getColumnDefaults = dispatch => {
  return (planId: number, api: Api) => {
    return api.get(`plans/${planId}/columnDefaults`).then(response => {
      if (response.hasErrors) {
        toast.error(getErrorMessage(response.errors));
      } else {
        dispatch({
          type: GET_COL_DEFAULTS,
          payload: response.result,
        });
      }
    });
  };
};

export type GetColumnDefaultsDispatch = ReturnType<typeof getColumnDefaults>;

export type Actions =
  | InitPlanData
  | UpdateRowData
  | SavePlanData
  | UpdateColumn
  | UpdateColumnOrder
  | TogglePinnedColumn
  | UpdateColumnWidth
  | SetColumnFilterType
  | AddHeader
  | AddBlank
  | AddBlankBelow
  | MoveRow
  | RemoveRow
  | UpdateRow
  | SelectRow
  | PromoteHeader
  | DemoteHeader
  | CopyDownValue
  | SetDefaultValueColumns
  | SetPreviousValueColumns
  | ToggleHeader
  | ResequencePlan
  | DuplicateRows
  | RemoveSelectedRows
  | AddAbbr
  | SaveColumnPreferences
  | GetColumnPreferences
  | ExportValidationErrors
  | AddAbbrExplicit
  | AddHardFactor
  | DeleteHardFactor
  | SaveHardFactors
  | ResetHardFactors
  | UpdateHardFactor
  | ClearHardFactors
  | GetColumnDefaults
  | UpdateColumnAlias;

import XLSX from "xlsx";

export const id = x => x;

export const exportObjectArrayToExcel = (data: any[]) => {
  const wb = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(data);

  XLSX.utils.book_append_sheet(wb, worksheet, "Resources");
  XLSX.writeFile(wb, "Resources.xlsx");
};

import {
  SEARCH_EMPLOYEE,
  AdminActionTypes,
  GET_SITES,
  GET_USER_SITES,
  GET_USER_SITE_PLANS,
  DISMISS_USER_INFO,
} from "./actions";
import {
  SiteGetDto,
  UserGetDto,
  PlanSummaryDto,
  SiteSummaryDto,
} from "../../models";

type State = {
  searchUsers: UserGetDto[];
  sites: SiteGetDto[];
  showUserInfo: boolean;
  selectedUser?: UserGetDto;
  selectedUserSite?: SiteSummaryDto;
  selectedUserSites?: SiteSummaryDto[];
  selectedUserSitePlans?: PlanSummaryDto[];
};

const initialState: State = {
  searchUsers: null,
  sites: [],
  showUserInfo: false,
};

const adminReducer = (
  state = initialState,
  action: AdminActionTypes
): State => {
  switch (action.type) {
    case SEARCH_EMPLOYEE:
      return {
        ...state,
        searchUsers: action.payload,
      };
    case GET_SITES:
      return {
        ...state,
        sites: action.sites,
      };

    case GET_USER_SITES:
      return {
        ...state,
        showUserInfo: true,
        selectedUser: action.user,
        selectedUserSites: action.sites,
        selectedUserSite: undefined,
        selectedUserSitePlans: undefined,
      };

    case GET_USER_SITE_PLANS:
      return {
        ...state,
        selectedUserSite: action.site,
        selectedUserSitePlans: action.plans,
      };

    case DISMISS_USER_INFO:
      return {
        ...state,
        showUserInfo: false,
      };

    default:
      return state;
  }
};

export default adminReducer;

import React from "react";
import { Column } from "react-table";
import { ColumnInfo } from ".";
import {
  InputListSelector,
  InputCell,
  AbbrInputCellExport,
  BumpedInputCell,
} from "./controls";
import { Icon, Checkbox } from "semantic-ui-react";
import { DefaultColumnFilter } from "./filters";
import styled from "@emotion/styled/macro";
import { PlanLineItemDto, CalculatedFields } from "../../../models";
import { columnSort } from "../../../util";

const RowIcon = styled(Icon)`
  cursor: pointer;
  color: #00563f;
`;

export const actionColumn: Column = {
  // Build our expander column
  id: "expander", // Make sure it has an ID
  Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    <span {...getToggleAllRowsExpandedProps()}>
      {isAllRowsExpanded ? (
        <RowIcon name="chevron down" />
      ) : (
        <RowIcon name="chevron right" />
      )}
    </span>
  ),
  Cell: ({ row, cell }) => (
    <div
      style={{
        display: "flex",
      }}
    >
      {row.original.isHeader ? null : <RowIcon name="move" />}
      {row.original.isHeader && row.depth === 0 ? (
        <span
          {...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 2}rem`,
            },
          })}
        >
          {row.isExpanded ? (
            <RowIcon name="chevron down" />
          ) : (
            <RowIcon name="chevron right" />
          )}
        </span>
      ) : null}
      &nbsp;
      <Checkbox checked={row.isSelected} onChange={row.select} />
      &nbsp;
      {row.original.orderInExport}
    </div>
  ),
  left: 0,
  width: 110,
  pinned: true,
};

const getCellForColumn = (
  accessor: keyof PlanLineItemDto | keyof CalculatedFields
) => {
  switch (accessor) {
    case "resource":
      return InputListSelector("resource-list");
    case "typeOfWork":
      return InputListSelector("discipline-list");
    case "time":
      return InputListSelector("time-list");
    case "company":
      return InputListSelector("company-list");
    case "abbr":
      return AbbrInputCellExport;
    case "bumped":
      return BumpedInputCell;
    case "miscellaneousField1":
      return InputListSelector("misc1");
    case "miscellaneousField2":
      return InputListSelector("misc2");
    case "miscellaneousField3":
      return InputListSelector("misc3");
    default:
      return InputCell;
  }
};

export const mapColumns = (
  columnInfos: ReadonlyArray<Readonly<ColumnInfo>>
) => {
  let columns = columnInfos
    .filter(x => !x.hidden)
    .sort(columnSort)
    .reduce((accum, x, i, arr) => {
      const last = i === 0 ? null : accum[i - 1];

      accum.push({
        Header: x.alias || x.title,
        Cell: getCellForColumn(x.accessor),
        accessor: x.accessor,
        canDefault: x.canDefault,
        canFilter: x.canFilter,
        canSort: false,
        order: x.order,
        isVisible: !x.hidden,
        left: last === null ? actionColumn.width : last.left + last.width,
        width: x.width,
        pinned: x.pinned,
        format: x.format,
        readonly: x.readonly,
        filter: x.filterType,
        type: x.type,
        Filter: DefaultColumnFilter,
      });

      return accum;
    }, []);

  return columns;
};

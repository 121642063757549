import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { Dropdown, Image, Label, Menu } from "semantic-ui-react";
import styled from "@emotion/styled/macro";
import { logout } from "../features/auth/actions";
import AppContext from "../app/AppContext";
import logo from "../img/turner-logo.jpg";
import { Auth } from "../util/auth/client";
import { colors } from "../styles";
import { AppState } from "../app/AppState";
import { UserHasClaims, Claims } from "../util/security/security";
import {
  GetSiteDto,
  HardFactorDto,
  PlanLineItemState,
  PlanGetDto,
  User,
} from "../models";
import { logoutSite } from "../features/me/actions";
import HardFactorsModal from "../features/me/plans/HardFactorsModal";
import { getUserDisplayName } from "../features/me/helpers";

const envLabelMap = {
  local: {
    color: "grey",
    tag: "LOCAL",
  },
  development: {
    color: "red",
    tag: "DEV",
  },
  test: {
    color: "yellow",
    tag: "TEST",
  },
  stage: {
    color: "green",
    tag: "STAGE",
  },
};
const envTag = envLabelMap[process.env.REACT_APP_ENVIRONMENT];

const AppHeaderRoot = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;

  .project-header {
    display: flex;
    flex: 1;
    flex-direction: row;
  }

  .project-actions-container {
    align-items: center;
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: flex-end;
  }

  .project-menu-container {
    display: flex;
  }

  .ui.menu {
    border-bottom: solid 5px ${colors.greenLight};
    border-radius: 0;
    flex: 1;

    &.inverted {
      background: ${colors.black};
    }

    .item {
      padding: 0.628571em 1.14285714em;

      &.logo-container {
        font-size: 1.5rem;
        font-weight: bold;
      }

      .logo {
        height: 32px;
        margin-right: 15px;
        width: 32px;
      }
    }

    .recalculate-button {
      margin-right: 12px;
    }
  }

  i.circular.icon.warning-icon {
    box-shadow: none;
    height: 18px !important;
    margin: 0px !important;
    padding: 0px 0px 0px 8px !important;
    width: 0px !important;
  }

  .ui.dropdown.dropdown-warning > .text {
    color: #fbbd08;
  }

  .completed-project-message {
    padding-right: 16px;
    color: ${colors.greenDark};
  }
`;

interface OwnProps {
  user?: User;
  currentSite?: GetSiteDto;
  selectedPlan?: PlanGetDto;
  history: any; //TODO: type?
  hardFactors: HardFactorDto[];
  planData: ReadonlyArray<Readonly<PlanLineItemState>>;
  isSipaSite: boolean;
}

interface DispatchProps {
  logout: (client: Auth) => void;
  logoutSite: () => void;
}

type Props = OwnProps & DispatchProps;

const defaultProps = {
  history: undefined,
  user: null,
};

const menuStyle = { zIndex: 200 };

export function AppHeader(props: Props) {
  const { auth } = React.useContext(AppContext);

  const [hardFactorModalOpen, setHardFactorModalOpen] = useState(false);

  const _handleLogout = () => {
    props.logout(auth);
  };

  const _showHardFactorModal = () => {
    setHardFactorModalOpen(true);
  };

  const _closeHardFactorModal = () => {
    setHardFactorModalOpen(false);
  };

  return (
    <AppHeaderRoot>
      <Menu size="large" style={menuStyle}>
        <Menu.Item className="logo-container">
          <Image className="logo" src={logo} title="Turner Industries, LLC" />
          <span>Quickplan™</span>
          {envTag && <Label color={envTag.color}>{envTag.tag}</Label>}
        </Menu.Item>
        <Dropdown item text="Navigation">
          <Dropdown.Menu>
            <Dropdown.Item as={Link} to={"/sites"}>
              Sites
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={"/abbreviations"}>
              Abbreviations
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={"/resources"}>
              Resources
            </Dropdown.Item>
            <Dropdown.Item as={Link} to={"/dataTemplates"}>
              Plan Templates
            </Dropdown.Item>

            {props.user &&
            UserHasClaims(props.user, [Claims.CorporateAdminClaim]) ? (
              <>
                <Dropdown.Item as={Link} to={"/admin/planners"}>
                  Manage Planners
                </Dropdown.Item>
                <Dropdown.Item as={Link} to={"/admin/sites"}>
                  Manage Sites
                </Dropdown.Item>
              </>
            ) : null}
          </Dropdown.Menu>
        </Dropdown>

        {props.currentSite != null ? (
          <>
            <Dropdown item text={props.currentSite.name}>
              <Dropdown.Menu>
                <Dropdown.Item
                  as={Link}
                  to={`/sites/${props.currentSite.id}/plans`}
                >
                  Site Plans
                </Dropdown.Item>
                {props.currentSite.isAdmin ? (
                  <>
                    <Dropdown.Item
                      as={Link}
                      to={`/sites/${props.currentSite.id}/admin/templates`}
                    >
                      Man Hour Factor Templates
                    </Dropdown.Item>
                    <Dropdown.Item
                      as={Link}
                      to={`/sites/${props.currentSite.id}/admin`}
                    >
                      Site Admin
                    </Dropdown.Item>
                  </>
                ) : null}
                <Dropdown.Item
                  as={Link}
                  to={`/sites/${props.currentSite.id}/premise`}
                >
                  Planning Premise
                </Dropdown.Item>
                <Dropdown.Item onClick={props.logoutSite}>
                  Log Out of Site
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {window.location.pathname.match(/\/sites\/\d+\/plans\/\d+/) ? (
              <Menu.Item onClick={() => _showHardFactorModal()}>
                Hard Factors
              </Menu.Item>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}

        <Menu.Menu position="right">
          <Dropdown item text={getUserDisplayName(props.user)}>
            <Dropdown.Menu>
              <Dropdown.Item onClick={_handleLogout}>Log Out</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item as={Link} to={"/releaseNotes"}>
            Release Notes
          </Menu.Item>
        </Menu.Menu>
      </Menu>

      <HardFactorsModal
        open={hardFactorModalOpen}
        onClose={() => _closeHardFactorModal()}
        hardFactors={props.hardFactors}
        planId={props.selectedPlan?.id}
        planData={props.planData}
        isSipaSite={props.isSipaSite}
      />
    </AppHeaderRoot>
  );
}

const mapStateToProps = (state: AppState): OwnProps => {
  return {
    ...defaultProps,
    user: state.auth.user,
    currentSite: state.me.selectedSite,
    selectedPlan: state.me.selectedPlan,
    hardFactors: state.planData.hardFactors,
    planData: state.planData.planData,
    isSipaSite: state.me.selectedSiteIsSipa,
  };
};

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    logout: logout(dispatch),
    logoutSite: logoutSite(dispatch),
  };
};

const Connected = connect(mapStateToProps, mapDispatchToProps)(AppHeader);

export default withRouter(Connected);

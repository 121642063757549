import { HardFactorCategory } from "../models";

export const Fields = {
  MiscField1: "Misc Field 1",
  MiscField2: "Misc Field 2",
  MiscField3: "Misc Field 3",
  MiscField4: "Misc Field 4",
  MiscField5: "Misc Field 5",
  MiscField6: "Misc Field 6",
};

export const fieldDropdownOptions = [
  { text: Fields.MiscField1, value: Fields.MiscField1 },
  { text: Fields.MiscField2, value: Fields.MiscField2 },
  { text: Fields.MiscField3, value: Fields.MiscField3 },
  { text: Fields.MiscField4, value: Fields.MiscField4 },
  { text: Fields.MiscField5, value: Fields.MiscField5 },
  { text: Fields.MiscField6, value: Fields.MiscField6 },
];

export const ActNoProps = {
  WorkItemNumber: "WorkItemNumber",
  Sequence: "Sequence",
  SystemValue: "SystemValue",
  UnitValue: "UnitValue",
};

export const activityNumberProperties = [
  { text: "Work Item Number", value: ActNoProps.WorkItemNumber },
  { text: "Sequence Number", value: ActNoProps.Sequence },
  { text: "System Value", value: ActNoProps.SystemValue },
  { text: "Unit Value", value: ActNoProps.UnitValue },
];

export const hardFactorList: {
  description: string;
  factor: number;
  category?: HardFactorCategory;
}[] = [
  { description: "GROUND LEVEL", factor: 1, category: "SIPA" },
  { description: "20' ELEVATION", factor: 1.1, category: "SIPA" },
  { description: "40' ELEVATION", factor: 1.2, category: "SIPA" },
  { description: "60' ELEVATION", factor: 1.3, category: "SIPA" },
  { description: "80' ELEVATION", factor: 1.4, category: "SIPA" },
  { description: "100' ELEVATION", factor: 1.5, category: "SIPA" },
  { description: "1-LAYER", factor: 1, category: "SIPA" },
  { description: "2-LAYERS", factor: 1.06, category: "SIPA" },
  { description: "3-LAYERS", factor: 1.13, category: "SIPA" },
  { description: "4-LAYERS", factor: 1.2, category: "SIPA" },
  { description: "5-LAYERS", factor: 1.29, category: "SIPA" },
  { description: "6-LAYERS", factor: 1.39, category: "SIPA" },
  { description: "7-LAYERS", factor: 1.5, category: "SIPA" },
  { description: "8-LAYERS", factor: 1.64, category: "SIPA" },
  { description: "9-LAYERS", factor: 1.8, category: "SIPA" },
  { description: "10-LAYERS", factor: 2, category: "SIPA" },
  { description: "100% SPARK CONTAINMENT", factor: 1.1 },
  { description: "100% TIE OFF", factor: 1.1 },
  { description: "CALSIL ALUMINUM", factor: 1.29, category: "SIPA" },
  { description: "CALSIL STAINLESS", factor: 1.48, category: "SIPA" },
  { description: "COLD FOAMGLASS ALUMINUM", factor: 1.8, category: "SIPA" },
  { description: "COLD FOAMGLASS PVC", factor: 1.8, category: "SIPA" },
  { description: "COLD FOAMGLASS STAINLESS", factor: 2.07, category: "SIPA" },
  { description: "COLD INSULATION/VAPOR BARRIER", factor: 1.4 },
  { description: "CONFINED SPACE", factor: 1.1 },
  { description: "COVERALLS/SHOWER TIME", factor: 1.1 },
  { description: "CRYOGEL ALUMINUM", factor: 1.29, category: "SIPA" },
  { description: "CRYOGEL STAINLESS", factor: 1.48, category: "SIPA" },
  { description: "ELECTRIC TRACING", factor: 1.1 },
  { description: "ESLIN ALUMINUM", factor: 1, category: "SIPA" },
  { description: "ESLIN STAINLESS", factor: 1.15, category: "SIPA" },
  { description: "EXPANDED PERLITE ALUMINUM", factor: 1.29, category: "SIPA" },
  { description: "EXPANDED PERLITE STAINLESS", factor: 1.48, category: "SIPA" },
  { description: "FIBERGLASS ALUMINUM", factor: 1, category: "SIPA" },
  { description: "FIBERGLASS STAINLESS", factor: 1.15, category: "SIPA" },
  { description: "FRESH AIR", factor: 1.1 },
  { description: "HEAT FACTOR -JUNE THRU SEPT-", factor: 1.12 },
  { description: "HOT FOAMGLASS ALUMINUM", factor: 1.14, category: "SIPA" },
  { description: "HOT FOAMGLASS STAINLESS", factor: 1.3, category: "SIPA" },
  { description: "HOT PIPE/LIVE STEAM", factor: 1.2 },
  { description: "INADEQUATE ENGINEERING/PACKAGE DELIVERY", factor: 1.2 },
  { description: "INTERNAL", factor: 1.4 },
  { description: "LINE BREAKING SUITS", factor: 1.1 },
  { description: "LOGISTICS - DEPLOYMENT AREA", factor: 1.25 },
  { description: "LOGISTICS - DEPLOYMENT AREA TO JOB", factor: 1.15 },
  { description: "MINERAL WOOL ALUMINUM", factor: 1, category: "SIPA" },
  { description: "MINERAL WOOL STAINLESS", factor: 1.15, category: "SIPA" },
  { description: "OUTSIDE INTERFERENCES", factor: 1.15 },
  { description: "PYROGEL ALUMINUM", factor: 1, category: "SIPA" },
  { description: "PYROGEL STAINLESS", factor: 1.15, category: "SIPA" },
  { description: "SPECIAL PPE", factor: 2.0 },
  { description: "STEAM TRACING-THERMON", factor: 1.2 },
  { description: "STRUCTURAL ELEVATIONS", factor: 1.1 },
  { description: "SUSPENDED", factor: 1.3 },
  { description: "TURNAROUND SITUATION", factor: 1.5 },
  { description: "UNPLANNED / EMERGENCY TICKET", factor: 1.1 },
  { description: "URATHANE ALUMINUM", factor: 1, category: "SIPA" },
  { description: "URATHANE PVC", factor: 1, category: "SIPA" },
  { description: "URATHANE STAINLESS", factor: 1.15, category: "SIPA" },
  { description: "WIRING IN HOT POWER PANELS", factor: 2.0 },
];

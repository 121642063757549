import {
  State,
  ActionTypes,
  ADD_FACTOR_TEMPLATE,
  GET_FACTOR_TEMPLATES,
  UPDATE_FACTOR_TEMPLATE,
  SELECT_FACTOR_TEMPLATE,
  DELETE_FACTOR_TEMPLATE,
  UPDATE_FACTOR_RATE,
  SAVE_FACTOR_RATES,
  SAVE_ACTNO_ENTRIES,
} from "./actions";
import { LOGGED_OUT_SITE, LoggedOutSite } from "../../me/actions";

const defaultState: State = {
  factors: [],
  hasChanges: false,
};

export default function reduce(
  state: State = defaultState,
  action: ActionTypes | LoggedOutSite
): State {
  switch (action.type) {
    case GET_FACTOR_TEMPLATES:
      return {
        ...state,
        factors: action.payload,
      };

    case ADD_FACTOR_TEMPLATE:
    case UPDATE_FACTOR_TEMPLATE: {
      const factors = state.factors.slice();
      const idx = factors.findIndex(x => x.id === action.payload.id);

      if (action.payload.isDefaultTemplate) {
        factors.forEach(x => (x.isDefaultTemplate = false));
      }

      if (action.type === ADD_FACTOR_TEMPLATE) {
        factors.push(action.payload);
      } else {
        factors.splice(idx, 1, action.payload);
      }

      return {
        ...state,
        selectedTemplate: action.payload,
        factors,
      };
    }

    case DELETE_FACTOR_TEMPLATE: {
      const idx = state.factors.findIndex(x => x.id === action.payload.id);
      const factors = state.factors.slice();
      factors.splice(idx, 1);
      return {
        ...state,
        selectedTemplate: null,
        factors,
      };
    }

    case UPDATE_FACTOR_RATE: {
      let rates = state.selectedTemplate.resourceRates.slice();
      let rateIdx = rates.findIndex(
        x => x.resourceId === action.payload.resourceId
      );
      rates.splice(rateIdx, 1, action.payload);

      return {
        ...state,
        selectedTemplate: { ...state.selectedTemplate, resourceRates: rates },
      };
    }

    case SAVE_FACTOR_RATES: {
      const rates = state.selectedTemplate.resourceRates
        .slice()
        .map(x => ({ ...x, rate: x.newVal ?? x.rate, newVal: null }));
      const newTpl = { ...state.selectedTemplate, resourceRates: rates };
      let factors = state.factors.slice();
      const tplIdx = factors.findIndex(x => x.id === newTpl.id);
      factors.splice(tplIdx, 1, newTpl);
      return {
        ...state,
        selectedTemplate: newTpl,
        factors: factors,
      };
    }

    case SELECT_FACTOR_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload,
      };

    case SAVE_ACTNO_ENTRIES:
      const newTpl = { ...state.selectedTemplate };
      newTpl.activityNumberComponents = action.payload;
      const index = state.factors.findIndex(x => x.id === newTpl.id);
      const newFactors = state.factors.slice();
      newFactors.splice(index, 1, newTpl);

      return {
        ...state,
        selectedTemplate: newTpl,
        factors: newFactors,
      };

    case LOGGED_OUT_SITE:
      return {
        ...state,
        selectedTemplate: null,
        factors: [],
      };

    default:
      return state;
  }
}

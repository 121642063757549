import React, { createElement } from "react";
import { connect } from "react-redux";
import { Redirect, Route, useLocation } from "react-router-dom";
import AppContext from "../app/AppContext";
import { AppState } from "../app/AppState";
import { User, UserClaim, GetSiteDto } from "../models";

type Props = {
  user?: User;
  currentSite?: GetSiteDto;
  component: any; //TODO: type?
  requiredClaims?: UserClaim[];
  requiredSiteAdmin?: boolean;
  [rest: string]: any;
};

export function PrivateRoute(props: Props) {
  const { auth } = React.useContext(AppContext);

  const location = useLocation();

  if (!auth.validateAuthentication()) {
    console.log(`Redirecting to /auth/login from ${location.pathname}`);
    return (
      <Redirect
        to={{ pathname: "/auth/login", state: { from: location.pathname } }}
      />
    );
  }

  if (props.requiredClaims && props.requiredClaims.length > 0) {
    if (!props.requiredClaims.every(x => props.user.claims.includes(x))) {
      console.log("Redirecting to /forbidden");
      return <Redirect to="/forbidden" />;
    }
  }

  if (props.requiredSiteAdmin && !props.currentSite?.isAdmin) {
    console.log("Redirecting to /forbidden");
    return <Redirect to="/forbidden" />;
  }

  return (
    <Route {...props.rest} render={p => createElement(props.component, p)} />
  );
}

const mapStateToProps = (state: AppState, ownProps: Props): Props => ({
  ...ownProps,
  user: state.auth.user,
  currentSite: state.me.selectedSite,
});

export default connect(mapStateToProps)(PrivateRoute);

import {
  State,
  LOAD_ABBREVIATIONS,
  Actions,
  LOAD_ABBREVIATIONS_DESCRIPTIONS,
  DOWNLOAD_ABBREVIATIONS,
  DOWNLOAD_ABBREVIATIONS_FINISHED,
} from "./actions";

const initialState: State = {
  abbreviations: [],
  abbreviationDescriptions: [],
  loading: false,
};

const abbrReducer = (state: State = initialState, action: Actions) => {
  switch (action.type) {
    case LOAD_ABBREVIATIONS:
      return { ...state, abbreviations: action.value };

    case LOAD_ABBREVIATIONS_DESCRIPTIONS:
      return { ...state, abbreviationDescriptions: action.value };

    case DOWNLOAD_ABBREVIATIONS:
      return { ...state, loading: true };

    case DOWNLOAD_ABBREVIATIONS_FINISHED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default abbrReducer;

import React from "react";
import { Button } from "semantic-ui-react";
import { toast } from "react-toastify";
import AppContext from "../../app/AppContext";
import { downloadUserReport, emailUserReport } from "./actions";

export function UserReport() {
  const { api } = React.useContext(AppContext);
  const [generatingReport, setGeneratingReport] = React.useState(false);
  const [emailingReport, setEmailingReport] = React.useState(false);

  const _handleDownloadReport = () => {
    setGeneratingReport(true);

    downloadUserReport(api).then(_ => setGeneratingReport(false));
  };

  const _handleEmailReport = () => {
    setEmailingReport(true);

    emailUserReport(api).then(_ => {
      setEmailingReport(false);
      toast("Report generated. You should receive an email shortly.");
    });
  };

  return (
    <div>
      <Button
        content="Download Users Report"
        disabled={generatingReport}
        icon="download"
        loading={generatingReport}
        onClick={_handleDownloadReport}
        secondary
        title="Download the Quickplan User Report as an Excel file"
      />

      <Button
        content="Email Users Report"
        disabled={emailingReport}
        icon="mail"
        loading={emailingReport}
        onClick={_handleEmailReport}
        secondary
        title="Send the Quickplan User Report to your email"
      />
    </div>
  );
}

import React from "react";
import { Hooks, actions } from "react-table";

actions.addCopyPreviousValue = "addCopyPreviousValue";
actions.toggleCopyPreviousValue = "toggleCopyPreviousValue";

export const usePreviousValue = (hooks: Hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

usePreviousValue.pluginName = "usePreviousValue";

function reducer(state, action, prevState, instance) {
  if (action.type === actions.init) {
    return {
      ...state,
      copyPreviousValue: instance.previousValues,
    };
  } else if (action.type === actions.addCopyPreviousValue) {
    const { colAccessor, enabled } = action;

    //determine if record exists already
    const record = state.copyPreviousValue.find(
      x => x.colAccessor === colAccessor
    );
    const recordExists = !!record;
    if (recordExists) {
      record.enabled = enabled;
    } else {
      state.copyPreviousValue.push({
        colAccessor: colAccessor,
        enabled: enabled,
      });
    }

    return {
      ...state,
    };
  } else if (action.type === actions.toggleCopyPreviousValue) {
    const { colAccessor, enabled } = action;

    //determine if record exists already
    const record = state.copyPreviousValue.find(
      x => x.colAccessor === colAccessor
    );
    const recordExists = !!record;
    if (recordExists) {
      record.enabled = enabled;
    }
    //no previous value has been entered here yet, so create a blank one
    else {
      state.copyPreviousValue.push({
        colAccessor: colAccessor,
        enabled: enabled,
      });
    }

    return {
      ...state,
    };
  }
}

function useInstance(instance) {
  const {
    allColumns,
    state: { copyPreviousValue },
    dispatch,
  } = instance;

  // FIXME: why does this need to be called on every render
  React.useEffect(() => {
    instance.setPreviousValueColumns(copyPreviousValue);
  });

  const addCopyPreviousValue = React.useCallback(
    object => {
      dispatch({ type: actions.addCopyPreviousValue, ...object });
    },
    [dispatch]
  );

  //adds convenience methods & properties to each column object
  allColumns.forEach(column => {
    column.addCopyPreviousValue = object => addCopyPreviousValue(object);

    const container = copyPreviousValue.find(x => x.colAccessor === column.id);

    const isEnabled = container?.enabled || false;

    column.copyPrevValueEnabled = isEnabled;
  });

  Object.assign(instance, {
    addCopyPreviousValue,
  });
}

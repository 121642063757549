import _ from "lodash";
import { ReleaseNoteGetDto } from "../../models";
import { generateUUID } from "../../util";
import {
  ActionTypes,
  ADD_RELEASE_NOTES,
  DELETE_RELEASE_NOTES,
  EDIT_RELEASE_NOTES,
  GET_RELEASE_NOTES,
} from "./actions";

type InitialState = {
  releaseNotes: ReleaseNoteGetDto[];
};

const initialState: InitialState = {
  releaseNotes: [],
};

const reducer = (state = initialState, action: ActionTypes): InitialState => {
  switch (action.type) {
    case GET_RELEASE_NOTES:
      _.forEach(action.payload, x => (x.clientId = generateUUID()));

      return {
        ...state,
        releaseNotes: action.payload,
      };
    case ADD_RELEASE_NOTES: {
      let releaseNotes = state.releaseNotes.slice();
      _.forEach(action.payload, x => (x.clientId = generateUUID()));

      return {
        ...state,
        releaseNotes: releaseNotes.concat(action.payload),
      };
    }
    case DELETE_RELEASE_NOTES: {
      let releaseNotes = state.releaseNotes.slice();
      _.forEach(action.payload, id => {
        const index = releaseNotes.findIndex(x => x.id === id);
        releaseNotes.splice(index, 1);
      });

      return {
        ...state,
        releaseNotes,
      };
    }
    case EDIT_RELEASE_NOTES: {
      let releaseNotes = state.releaseNotes.slice();
      _.forEach(action.payload, note => {
        const index = releaseNotes.findIndex(x => x.id === note.id);
        note = { ...note, modified: false };
        releaseNotes.splice(index, 1, note);
      });

      return {
        ...state,
        releaseNotes,
      };
    }
    default:
      return state;
  }
};

export default reducer;

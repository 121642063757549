import React, { useEffect, useState } from "react";
import { DropdownProps, Select } from "semantic-ui-react";
import { getUsers } from "../admin/actions";
import AppContext from "../../app/AppContext";
import { toast } from "react-toastify";
import { UserGetDto } from "../../models";
import _ from "lodash";

type Props = {
  onChange: (user: UserGetDto) => void;
  filter?: (user: UserGetDto) => boolean;
};

export default function UserDropdown(props: Props) {
  const { api } = React.useContext(AppContext);
  const [users, setUsers] = useState<
    { text: string; value: number; user: UserGetDto }[]
  >();

  const dropOptions = !!props.filter
    ? users?.filter(x => props.filter(x.user))
    : users;

  useEffect(() => {
    getUsers(api)()
      .then(x => {
        const sortedResult = _.sortBy(x.result, x => x.name);
        setUsers(
          sortedResult.map(x => {
            return {
              text: `${x.name} (${x.emailAddress})`,
              value: x.id,
              user: x,
            };
          })
        );
      })
      .catch(err => {
        toast.error("An error occurred while retrieving users");
      });
  }, [api]);

  const _handleChange = (e: any, data: DropdownProps) => {
    let selection = users.find(x => x.value === data.value);
    props.onChange(selection.user);
  };

  return (
    <Select
      placeholder="Select User"
      fluid
      search
      selection
      options={dropOptions}
      onChange={_handleChange}
    />
  );
}

import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";
import qs from "qs";
import { Auth } from "../auth/client";
import { toast } from "react-toastify";
import ResponseMessage from "../../features/components/ResponseMessage";
import { isResponse } from "../../models/";

const config = {
  baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
  paramsSerializer: params => qs.stringify(params, { allowDots: true }),
  timeout: 0,
};

export const createApi = (dispatch, authClient: Auth) => {
  const instance = axios.create(config);

  const getMessage = error => {
    if (isResponse(error.response?.data)) {
      return ResponseMessage({ response: error.response.data });
    } else {
      return "Error: " + error.message;
    }
  };

  const api = {
    async get<T = any>(url, params?, config?: AxiosRequestConfig) {
      try {
        const { data } = await instance.get<T>(url, { ...config, params });
        return data;
      } catch (err) {
        toast(getMessage(err));
        return Promise.reject(err);
      }
    },

    async getFileWithFileName(url, params?, config?: AxiosRequestConfig) {
      config = {
        responseType: "blob",
        ...config,
      };

      var response = await instance.get(url, { ...config, params });
      return {
        filename: parseFilename(response.headers["content-disposition"]),
        blob: response.data,
      };
    },

    async getStream(url, params, config = {}) {
      config = {
        responseType: "blob",
        ...config,
      };

      return await instance.get(url, { ...config, params });
    },

    async post<T = any>(url, payload, config = {}) {
      try {
        const { data } = await instance.post<T>(url, payload, { ...config });
        return data;
      } catch (err) {
        toast(getMessage(err));
        return Promise.reject(err);
      }
    },

    async put<T = any>(url, payload, config = {}) {
      try {
        const { data } = await instance.put<T>(url, payload, { ...config });
        return data;
      } catch (err) {
        toast(getMessage(err));
        return Promise.reject(err);
      }
    },

    async patch<T = any>(url, payload, config = {}) {
      try {
        const { data } = await instance.patch<T>(url, payload, { ...config });
        return data;
      } catch (err) {
        toast(getMessage(err));
        return Promise.reject(err);
      }
    },

    async delete<T = any>(url, params, config = {}) {
      try {
        const { data } = await instance.delete<T>(url, { ...config, params });
        return data;
      } catch (err) {
        toast(getMessage(err));
        return Promise.reject(err);
      }
    },
  };

  const applyAuthHeader = (authClient: Auth) => {
    return (config: InternalAxiosRequestConfig<any>) => {
      const token = authClient.getToken();
      config.headers["Authorization"] = `Bearer ${token}`;
      return config;
    };
  };

  instance.interceptors.request.use(applyAuthHeader(authClient), error =>
    Promise.reject(error)
  );

  instance.interceptors.response.use(
    response => response,
    error => {
      console.log(error);
      if (
        error.response?.status === 401 &&
        (error.response.config.params?.retry ?? 0) < 1
      ) {
        return authClient.login(dispatch).then(() => {
          let req = error.response.config;
          console.log("Retrying request after reauthorization.");
          req.params = { ...req.params, retry: 1 };
          return instance.request(req);
        });
      } else {
        if (error.response?.status === 403) {
          console.log(`Response Error: ${error.message}`);
          error.message =
            "You do not have permission to perform the requested action";
        }

        return Promise.reject(error);
      }
    }
  );

  return api;
};

const parseFilename = (filename: string): string => {
  const split = filename.split(";");
  const name = split.find(x => x.trim().slice(0, 9) === "filename=");
  return name.trim().replace(/"/g, "").slice(9);
};

export type Api = ReturnType<typeof createApi>;

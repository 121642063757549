import { Api } from "../../util/api/api";
import {
  DataTemplate,
  Response,
  DataTemplateDropdownOption,
} from "../../models";

export const GET_DATA_TEMPLATES = "GET_DATA_TEMPLATES";
export const DATA_TEMPLATES_DROPDOWN = "DATA_TEMPLATES_DROPDOWN";

interface GetDataTemplates {
  type: typeof GET_DATA_TEMPLATES;
  templates: DataTemplate;
}

interface GetDataTemplateDropdownOptions {
  type: typeof DATA_TEMPLATES_DROPDOWN;
  options: DataTemplateDropdownOption[];
}

export const getDataTemplates = dispatch => {
  return (api: Api) => {
    return api
      .get<Response<DataTemplate>>(`/narratives`, null)
      .then(response => {
        dispatch({ type: GET_DATA_TEMPLATES, templates: response.result });
      });
  };
};

export type GetDataTemplatesDispatch = ReturnType<typeof getDataTemplates>;

export function downloadDataTemplate(name: string, api: Api): Promise<any> {
  return api
    .get("/narratives/" + name + "/export", null, {
      responseType: "blob",
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${name.toUpperCase()}.xlsx`);
      link.setAttribute("style", "display: none");
      document.body.appendChild(link);
      link.click();
      window.setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 200);
      return Promise.resolve();
    });
}

export type DownloadDataTemplateDispatch = ReturnType<
  typeof downloadDataTemplate
>;

export const getDataTemplateDropdownOptions = dispatch => {
  return (api: Api) => {
    return api
      .get<Response<DataTemplateDropdownOption[]>>(`/data-templates`, null)
      .then(response => {
        dispatch({ type: DATA_TEMPLATES_DROPDOWN, options: response.result });
      });
  };
};

export type GetDataTemplateDropdownOptionsDispatch = ReturnType<
  typeof getDataTemplateDropdownOptions
>;

export type ActionTypes = GetDataTemplates | GetDataTemplateDropdownOptions;

import React from "react";
import { Hooks, actions } from "react-table";

//actions
actions.setColumnDefault = "setColumnDefault";
actions.showDefaultModal = "showDefaultModal";
actions.closeDefaultModal = "closeDefaultModal";

export const useDefaultValue = (hooks: Hooks) => {
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
};

useDefaultValue.pluginName = "useDefaultValue";

function reducer(state, action, prevState, instance) {
  if (action.type === actions.init) {
    return {
      ...state,
      showDefaultValueModal: false,
      editingColumn: null,
      defaultValues: instance.defaultValues,
    };
  } else if (action.type === actions.showDefaultModal) {
    const { column } = action;
    return {
      ...state,
      showDefaultValueModal: true,
      editingColumn: column,
    };
  } else if (action.type === actions.closeDefaultModal) {
    return {
      ...state,
      showDefaultValueModal: false,
      editingColumn: null,
    };
  } else if (action.type === actions.setColumnDefault) {
    const { columnId, defaultValue } = action;
    const { allColumns } = instance;

    const column = allColumns.find(x => x.id === columnId);

    if (!column) {
      throw new Error(
        `React-Table: Could not find a column with id: ${columnId}`
      );
    }

    const previousValue = state.defaultValues.find(x => x.id === columnId);

    //if previous default value exists - set new value here
    if (previousValue) {
      return {
        ...state,
        defaultValues: state.defaultValues.map(f => {
          if (f.id === columnId) {
            return { id: columnId, value: defaultValue };
          }
          return f;
        }),
      };
    }

    return {
      ...state,
      defaultValues: [
        ...state.defaultValues,
        { id: columnId, value: defaultValue },
      ],
    };
  } else {
    return state;
  }
}

function useInstance(instance) {
  const {
    allColumns,
    state: { defaultValues },
    dispatch,
  } = instance;

  React.useEffect(() => {
    instance.setDefaultValueColumns(defaultValues);
  }, [defaultValues, instance]);

  const showDefaultModal = React.useCallback(
    column => {
      dispatch({ type: actions.showDefaultModal, column });
    },
    [dispatch]
  );

  const closeDefaultModal = React.useCallback(() => {
    dispatch({ type: actions.closeDefaultModal });
  }, [dispatch]);

  const setDefaultValue = React.useCallback(
    (columnId, defaultValue) => {
      dispatch({ type: actions.setColumnDefault, columnId, defaultValue });
    },
    [dispatch]
  );

  allColumns.forEach(column => {
    //provide convenience methods
    column.showDefaultModal = () => showDefaultModal(column);
    column.closeDefaultModal = () => closeDefaultModal();
    column.setDefaultValue = value => setDefaultValue(column.id, value);

    //convenient default value access on column
    const colDefault = defaultValues.find(x => x.id === column.id);
    column.defaultValue = colDefault && colDefault.value;
  });

  Object.assign(instance, {
    showDefaultModal,
    closeDefaultModal,
    setDefaultValue,
  });
}

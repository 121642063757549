import { combineReducers } from "redux";
import auth from "../features/auth/authReducer";
import admin from "../features/admin/adminReducer";
import me from "../features/me/meReducer";
import templates from "../features/sites/templates/reducer";
import resources from "../features/resources/reducer";
import dataTemplates from "../features/dataTemplates/reducer";
import siteAdmin from "../features/siteAdmin/siteAdminReducer";
import planData from "../features/me/plans/reducer";
import abbr from "../features/abbreviations/abbrReducer";
import premise from "../features/planningPremise/reducer";
import about from "../features/releaseNotes/reducer";

const allReducers = combineReducers({
  auth,
  admin,
  siteAdmin,
  me,
  templates,
  resources,
  dataTemplates,
  planData,
  abbr,
  premise,
  about,
});

export default allReducers;

import React from "react";
import { Table, Input, Button } from "semantic-ui-react";
import { ActivityNumberEntry } from "../../models";

type Props = {
  selections: ActivityNumberEntry[];
  onChange: (ActivityNumberEntry) => void;
  onDelete: (ActivityNumberEntry) => void;
};

export function ActivityNumberTable(props: Props) {
  const Items = props.selections?.map(x => (
    <Table.Row key={x?.id}>
      <Table.Cell collapsing>
        <Input
          fluid
          value={x?.order}
          onChange={(e, data) => props.onChange({ ...x, order: data.value })}
        ></Input>
      </Table.Cell>
      <Table.Cell>
        <Input
          fluid
          value={x?.prefix}
          onChange={(e, data) => props.onChange({ ...x, prefix: data.value })}
        ></Input>
      </Table.Cell>
      <Table.Cell collapsing> {x?.property}</Table.Cell>
      <Table.Cell>
        <Input
          fluid
          value={x?.suffix}
          onChange={(e, data) => props.onChange({ ...x, suffix: data.value })}
        ></Input>
      </Table.Cell>
      <Table.Cell collapsing>
        <Button
          icon="remove"
          onClick={() => props.onDelete(x)}
          negative
          title="Remove activity number configuration"
        ></Button>
      </Table.Cell>
    </Table.Row>
  ));

  return (
    <Table striped unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell collapsing colSpan="1">
            Order
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="1">Prefix</Table.HeaderCell>
          <Table.HeaderCell colSpan="1">Property</Table.HeaderCell>
          <Table.HeaderCell colSpan="1">Suffix</Table.HeaderCell>
          <Table.HeaderCell collapsing colSpan="1" />
        </Table.Row>
      </Table.Header>

      <Table.Body>{Items}</Table.Body>
    </Table>
  );
}

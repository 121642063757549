import { Auth0ContextInterface, LogoutOptions, User } from "@auth0/auth0-react";

type Auth0UserContext = Auth0ContextInterface<User>;
export type AccessParams = Parameters<
  Auth0UserContext["getAccessTokenSilently"]
>[0];

export const loginRequest: AccessParams = {
  cacheMode: "on",
  //detailedResponse: true,
};

export const logoutRequest: LogoutOptions = {
  logoutParams: {
    returnTo: window.location.origin,
  },
};

import React from "react";
import { Container, Segment, Header } from "semantic-ui-react";
import styled from "@emotion/styled/macro";

const StyledRoot = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.15);

  .ui.container {
    width: 400px;
  }
`;

const AuthContainer = ({ loading, children }) => {
  return (
    <StyledRoot>
      <Container>
        <Header inverted attached="top">
          Quickplan
        </Header>
        <Segment attached loading={loading}>
          {children}
        </Segment>
      </Container>
    </StyledRoot>
  );
};

export default AuthContainer;

import { Resource, KeyedLookup } from "../../models";
import { Actions, LOAD_RESOURCES } from "./actions";

export type State = {
  byId: KeyedLookup<Resource>;
};

export default function reduce(
  state: State = { byId: null },
  action: Actions
): State {
  switch (action.type) {
    case LOAD_RESOURCES: {
      let byId = new Map<number, Resource>(action.payload.map(x => [x.id, x]));
      return {
        ...state,
        byId,
      };
    }
    default:
      return state;
  }
}

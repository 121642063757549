import { ColumnInfo } from "../../components/grid";
import { getDefaultFilterType } from "../../components/grid/filters";
import { Fields } from "../../../constants";
import { PlanningPremiseDto } from "../../planningPremise/actions";
import { updateColumnAlias } from "./actions";

const columns: ColumnInfo[] = [
  {
    title: "ActNo",
    accessor: "actNo",
    pinned: false,
    order: 0,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (
      isHeader: boolean,
      miscFieldColumn: string,
      actNoIsAutoCalculated: boolean
    ) => {
      return actNoIsAutoCalculated ? true : false;
    },
    canDefault: false,
  },
  {
    title: "Abbr",
    accessor: "abbr",
    pinned: false,
    order: 1,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: false,
  },
  {
    title: "Description",
    accessor: "description",
    pinned: false,
    order: 2,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Sequence",
    accessor: "sequence",
    pinned: false,
    order: 3,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return false;
    },
    canDefault: false,
  },
  {
    title: "Number Of Men",
    accessor: "numberOfMen",
    pinned: false,
    order: 4,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? false : true;
    },
    canDefault: false,
    type: "int",
  },
  {
    title: "Drawing Number",
    accessor: "drawingNumber",
    pinned: false,
    order: 5,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Equipment Number",
    accessor: "equipmentNumber",
    pinned: false,
    order: 6,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "User's Factor",
    accessor: "user",
    pinned: false,
    order: 7,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    format: (value: any) => {
      if (value >= 0) {
        return Number(value).toFixed(2);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "Quantity",
    accessor: "quantity",
    pinned: false,
    order: 8,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: false,
  },
  {
    title: "Discipline",
    accessor: "typeOfWork",
    pinned: false,
    order: 9,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? false : true;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Resource",
    accessor: "resource",
    pinned: false,
    order: 10,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Phase",
    accessor: "time",
    pinned: false,
    order: 11,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => !isHeader,
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Company",
    accessor: "company",
    pinned: false,
    order: 12,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return !isHeader;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "TP Number",
    accessor: "tpNumber",
    pinned: false,
    order: 13,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: false,
  },
  {
    title: "Duration",
    accessor: "duration",
    pinned: false,
    order: 14,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? false : true;
    },
    canDefault: false,
    type: "int",
  },
  {
    title: "Area",
    accessor: "area",
    pinned: false,
    order: 18,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Notes",
    accessor: "notes",
    pinned: false,
    order: 19,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Size1",
    accessor: "size1",
    pinned: false,
    order: 20,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Tag",
    accessor: "tag",
    pinned: false,
    order: 21,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Field 1",
    accessor: "miscellaneousField1",
    pinned: false,
    order: 22,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean, miscFieldColumn: string) => {
      return isHeader || miscFieldColumn === Fields.MiscField1 ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Field 2",
    accessor: "miscellaneousField2",
    pinned: false,
    order: 23,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean, miscFieldColumn: string) => {
      return isHeader || miscFieldColumn === Fields.MiscField2 ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Field 3",
    accessor: "miscellaneousField3",
    pinned: false,
    order: 24,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean, miscFieldColumn: string) => {
      return isHeader || miscFieldColumn === Fields.MiscField3 ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Field 4",
    accessor: "miscellaneousField4",
    pinned: false,
    order: 25,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean, miscFieldColumn: string) => {
      return isHeader || miscFieldColumn === Fields.MiscField4 ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Field 5",
    accessor: "miscellaneousField5",
    pinned: false,
    order: 26,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean, miscFieldColumn: string) => {
      return isHeader || miscFieldColumn === Fields.MiscField5 ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Field 6",
    accessor: "miscellaneousField6",
    pinned: false,
    order: 27,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean, miscFieldColumn: string) => {
      return isHeader || miscFieldColumn === Fields.MiscField6 ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Num 1",
    accessor: "miscellaneousNumeric1",
    pinned: false,
    order: 28,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Num 2",
    accessor: "miscellaneousNumeric2",
    pinned: false,
    order: 29,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Num 3",
    accessor: "miscellaneousNumeric3",
    pinned: false,
    order: 30,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Num 4",
    accessor: "miscellaneousNumeric4",
    pinned: false,
    order: 31,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Num 5",
    accessor: "miscellaneousNumeric5",
    pinned: false,
    order: 32,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Misc Num 6",
    accessor: "miscellaneousNumeric6",
    pinned: false,
    order: 33,
    hidden: true,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return isHeader ? true : false;
    },
    canDefault: (isHeader: boolean) => !isHeader,
  },
  {
    title: "Rate",
    accessor: "rate",
    pinned: false,
    order: 36,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return "$" + Number(value).toFixed(2);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "BaseFactor",
    accessor: "baseFactor",
    pinned: false,
    order: 37,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return Number(value).toFixed(4);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "Bumped",
    accessor: "bumped",
    pinned: false,
    order: 38,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: any) => {
      if (value >= 0) {
        return Number(value).toFixed(4);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "BaseHours",
    accessor: "baseHours",
    pinned: false,
    order: 39,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return Number(value).toFixed(3);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "BumpedHr",
    accessor: "bumpedHr",
    pinned: false,
    order: 40,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return Number(value).toFixed(3);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "Dollars",
    accessor: "dollars",
    pinned: false,
    order: 41,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return "$" + Number(value).toFixed(2);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "RunningTotal",
    accessor: "runningTotal",
    pinned: false,
    order: 42,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return "$" + Number(value).toFixed(2);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "RunningHrTotal",
    accessor: "runningHrTotal",
    pinned: false,
    order: 43,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return Number(value).toFixed(2);
      }
      return value;
    },
    canDefault: false,
  },
  {
    title: "BumpedRunningHrTotal",
    accessor: "bumpedRunningHrTotal",
    pinned: false,
    order: 44,
    hidden: false,
    width: 255,
    canFilter: true,
    readonly: (isHeader: boolean) => {
      return true;
    },
    format: (value: number) => {
      if (value >= 0) {
        return Number(value).toFixed(2);
      }
      return value;
    },
    canDefault: false,
  },
];

const filterMappedCols = columns.map(x => {
  x.filterType = getDefaultFilterType(x);
  return x;
});

export default filterMappedCols;

export const updateColumnAliasPlugin = (
  dispatch,
  premise: PlanningPremiseDto
) => {
  if (premise) {
    if (premise.planningPremiseEntries) {
      const aliases = premise.planningPremiseEntries.filter(
        x => x.linkedField === 0 || x.linkedField === 1 || x.linkedField === 2
      );
      const misc1 = aliases.find(x => x.linkedField === 0);
      const misc2 = aliases.find(x => x.linkedField === 1);
      const misc3 = aliases.find(x => x.linkedField === 2);

      misc1
        ? updateColumnAlias(dispatch)(
            "miscellaneousField1",
            `${misc1.category} (Misc Field 1)`
          )
        : updateColumnAlias(dispatch)("miscellaneousField1", null);

      misc2
        ? updateColumnAlias(dispatch)(
            "miscellaneousField2",
            `${misc2.category} (Misc Field 2)`
          )
        : updateColumnAlias(dispatch)("miscellaneousField2", null);

      misc3
        ? updateColumnAlias(dispatch)(
            "miscellaneousField3",
            `${misc3.category} (Misc Field 3)`
          )
        : updateColumnAlias(dispatch)("miscellaneousField3", null);
    }
  }
};

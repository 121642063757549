import React, { useState } from "react";
import { connect } from "react-redux";
import {
  Form,
  Table,
  Button,
  Icon,
  Modal,
  Grid,
  Menu,
  Header,
  Card,
} from "semantic-ui-react";
import styled from "@emotion/styled/macro";
import {
  searchEmployee,
  SearchEmployeeDispatch,
  getUserSites,
  GetUserSitesDispatch,
  GetUserSitePlansDispatch,
  getUserSitePlans,
  dismissUserInfo,
  DismissUserInfoDispatch,
} from "./actions";
import { AppState } from "../../app/AppState";
import { UserReport } from "./UserReport";
import { User, UserGetDto, SiteSummaryDto, PlanSummaryDto } from "../../models";
import AppContext from "../../app/AppContext";
import PageContent from "../components/PageContent";
import { UserDetailActions } from "./UserDetailActions";
import _ from "lodash";
import AddPlannerModal from "./planners/AddPlannerModal";
import { getUserDisplayName } from "../me/helpers";

const StyledRoot = styled(PageContent)`
  .body {
    input {
      min-width: 250px;
    }
  }
`;

interface DispatchProps {
  searchEmployee: SearchEmployeeDispatch;
  getUserSites: GetUserSitesDispatch;
  getUserSitePlans: GetUserSitePlansDispatch;
  dismissUserInfo: DismissUserInfoDispatch;
}

interface OwnProps {
  searchUsers: UserGetDto[];
  adminUser: User;
  showUserInfo: boolean;
  selectedUser?: UserGetDto;
  selectedUserSite?: SiteSummaryDto;
  selectedUserSites?: SiteSummaryDto[];
  selectedUserSitePlans?: PlanSummaryDto[];
}

type Props = OwnProps & DispatchProps;

function ManagePlanners(props: Props) {
  const { api } = React.useContext(AppContext);
  const [isSearching, setIsSearching] = useState(false);
  const [userQuery, setUserQuery] = useState("");
  const [newPlannerModalOpen, setNewPlannerModalOpen] = useState(false);

  const _handleSearch = () => {
    setIsSearching(true);
    props.searchEmployee(api, userQuery).finally(() => {
      setIsSearching(false);
    });
  };

  const _viewUser = user => {
    props.getUserSites(user, api);
  };

  const _viewUserSite = site => {
    console.log(site);
    props.getUserSitePlans(props.selectedUser.id, site, api);
  };

  return (
    <>
      <StyledRoot actions={<UserReport />} title="Manage Planners">
        <Form>
          <Form.Group inline>
            <Form.Input
              onChange={(e, { value }) => setUserQuery(value)}
              placeholder="Search..."
              value={userQuery}
              required
            />
            <Button
              content="Search Employees"
              icon="search"
              primary
              loading={isSearching}
              disabled={isSearching}
              onClick={_handleSearch}
            />
            {props.searchUsers?.length < 1 && (
              <>
                <Button
                  content="Add User"
                  icon="plus"
                  primary
                  onClick={() => setNewPlannerModalOpen(true)}
                />
                <AddPlannerModal
                  open={newPlannerModalOpen}
                  onClose={() => setNewPlannerModalOpen(false)}
                  admin={props.adminUser}
                />
              </>
            )}
          </Form.Group>
        </Form>

        {!!props.searchUsers === false ? null : (
          <Table padded singleLine striped textAlign="center" unstackable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell></Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell collapsing />
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {props.searchUsers?.map(user => (
                <Table.Row key={user.emailAddress}>
                  <Table.Cell width={1}>
                    {_.isNil(user.id) ? null : (
                      <Button icon onClick={_ => _viewUser(user)}>
                        <Icon name="info circle" />
                      </Button>
                    )}
                  </Table.Cell>
                  <Table.Cell>{user.name}</Table.Cell>

                  <Table.Cell>{user.emailAddress}</Table.Cell>

                  <UserDetailActions admin={props.adminUser} employee={user} />
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        )}
      </StyledRoot>
      {!_.isNil(props.selectedUser) ? (
        <Modal open={props.showUserInfo}>
          <Modal.Header>
            Summary for
            {getUserDisplayName(props.selectedUser)}
          </Modal.Header>
          <Modal.Content>
            <Grid>
              <Grid.Column width={4}>
                <Header as="h3">Sites</Header>
                <Menu fluid vertical tabular>
                  {props.selectedUserSites.map(site => (
                    <Menu.Item
                      key={site.id}
                      active={props.selectedUserSite?.id === site.id}
                      onClick={_ => _viewUserSite(site)}
                    >
                      <Header as="h4">{site.name}</Header>
                      <p>{site.planCount} plans</p>
                    </Menu.Item>
                  ))}
                </Menu>
              </Grid.Column>
              <Grid.Column stretched width={12}>
                <Header as="h3">Plans</Header>
                {_.isNil(props.selectedUserSitePlans) ? null : (
                  <Card.Group
                    style={{ maxHeight: 600, overflowX: "auto" }}
                    items={props.selectedUserSitePlans.map(plan => ({
                      key: plan.id,
                      header: `${plan.jobNumber} - ${plan.name}`,
                      description: `${plan.lineCount} lines`,
                      meta: `Last modified: ${plan.lastModified}`,
                    }))}
                  />
                )}
              </Grid.Column>
            </Grid>
          </Modal.Content>
          <Modal.Actions>
            <Button
              primary
              content="OK"
              onClick={_ => props.dismissUserInfo()}
            />
          </Modal.Actions>
        </Modal>
      ) : undefined}
    </>
  );
}
const mapStateToProps = (state: AppState): OwnProps => ({
  adminUser: state.auth.user,
  searchUsers: state.admin.searchUsers,
  showUserInfo: state.admin.showUserInfo,
  selectedUser: state.admin.selectedUser,
  selectedUserSite: state.admin.selectedUserSite,
  selectedUserSites: state.admin.selectedUserSites,
  selectedUserSitePlans: state.admin.selectedUserSitePlans,
});

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    searchEmployee: searchEmployee(dispatch),
    getUserSites: getUserSites(dispatch),
    getUserSitePlans: getUserSitePlans(dispatch),
    dismissUserInfo: dismissUserInfo(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePlanners);

import { PreviousValuesContainer } from "./grid";

export interface GetSiteDto {
  id: number;
  name: string;
  isAdmin: boolean;
}

export interface SiteUserGetDto {
  id: number;
  siteId: number;
  userId: number;
  isAdmin: boolean;
  createdDate: string;
  lastModifiedDate: string;
}

export interface UserDto {
  id: number;
  name: string;
  emailAddress: string;
  preferredEmail: string;
}

export type UserGetDto = UserDto & {
  employeeId: string;
  claims: UserClaim[];
  factorTemplates?: FactorTemplate[];
  sites?: SiteUserGetDto[]; // TODO: change from array to single value on API
};

export type UserGetDtoWithPlans = {
  plans: PlanDetailsGetDto[];
} & UserGetDto;

export interface SiteGetDto {
  id: number;
  name: string;
  users: UserGetDto[];
  siteAdmins: UserGetDto[];
  plans: PlanGetDto[];
}

export interface SiteSummaryDto {
  id: number;
  name: string;
  planCount: number;
}

export interface PlanSummaryDto {
  id: number;
  name: string;
  jobNumber: string;
  lineCount: number;
  lastModified: string;
}

export interface SiteDetailsGetDto {
  id: number;
  name: string;
  plans: PlanDetailsGetDto[];
  factorTemplates: FactorTemplate[];
  users: UserGetDto[];
}

export interface PropertyError {
  propertyName: string;
  errorMessage: string;
}

export interface SiteDto {
  name: string;
}

export interface EditSiteDto extends SiteDto {
  siteAdmins: number[];
}

export interface Response<T> {
  result: T;
  hasErrors: boolean;
  errors: Array<PropertyError>;
}

export const isResponse = (data: any): data is Response<any> => {
  return data?.errors !== undefined && data?.hasErrors !== undefined;
};

export interface User {
  id: number;
  name: string;
  emailAddress: string;
  claims: UserClaim[];
}

export interface UserClaim {
  type: string;
  value: string;
}

export interface PlanGetDto extends Plan {
  createdDate: string;
  createdBy: string;
  transferDetails?: string;
}

export interface PlanDetailsGetDto extends PlanGetDto {
  baseHours: number;
}

export interface Plan {
  id: number;
  title: string;
  unit: string;
  system: string;
  jobNumber: string;
  userId: number;
  siteId: number;
  miscFieldColumn: string;
  miscFieldValue: string;
  factorTemplateId: number;
}

export type NewPlan = Plan & { dataTemplateId: number };

export interface ActivityNumberEntry {
  id: number | string;
  property: string;
  prefix: string;
  suffix: string;
  order: number;
  factorTemplateId?: number;
  createdDate?: string;
  lastModifiedDate?: string;
}

export interface FactorTemplate {
  id?: number;
  siteId: number;
  name: string;
  defaultRate: number;
  factorGlobalAdjustment: number;
  actNoIsAutoCalculated: boolean;
  isDefaultTemplate: boolean;
  activityNumberComponents: ActivityNumberEntry[];
}

export interface FactorTemplateResourceRate {
  factorTemplateId: number;
  resourceId: number;
  rate: number;
}

export type FactorTemplateResourceRateChange = FactorTemplateResourceRate & {
  newVal?: number;
};

export type FactorTemplateWithRates = FactorTemplate & {
  resourceRates: FactorTemplateResourceRateChange[];
};

export interface Resource {
  id: number;
  abbreviation: string;
  description: string;
}

export type IdLookup<T> = {
  [key: number]: T;
};

export type KeyedLookup<T> = Map<number, T> | IdLookup<T>;

export type DataTemplate = Map<string, DataTemplateLine[]>;

export interface DataTemplateLine {
  sequence: string;
  description: string;
  isHeader: boolean;
}

export interface DataTemplateDropdownOption {
  id: number;
  name: string;
}

export interface PlanDataGetDto {
  id: number;
  siteId: number;
  title: string;
  unit: string;
  system: string;
  jobNumber: string;
  createdDate: Date;
  miscFieldColumn: string;
  miscFieldValue: string;
  lines: PlanLineItemDto[];
  factorTemplate: FactorTemplateDataDto;
  hardFactors: HardFactorDto[];
}

export interface PlanLineItemDto extends NewPlanLineItemDto {
  id?: number;
}

export type Issue = {
  type: "warning" | "error" | "export";
  description: string;
};

export type ErrorMap<T> = {
  [P in keyof T]?: Issue;
} & { ""?: string[] };

export type PlanLineItemState = PlanLineItemDto &
  CalculatedFields & {
    clientId: string;
    subRows: PlanLineItemState[];
    modified: boolean;
    lastModified?: number;
    errors: ErrorMap<PlanLineItemState>;
    hardFactors?: HardFactorDto[];
  };

export type SavePlanLinesRequest = {
  lines: PlanLineItemState[];
  idsToDelete: number[];
};

export type NewPlanLineItemState = PlanLineItemState & PreviousValuesContainer;

export interface NewPlanLineItemDto {
  planId: number;
  isHeader: boolean;
  user: number; //decimal
  numberOfMen: number;
  quantity: number; //decimal
  duration: number;
  orderInExport: number;
  isInSequence: boolean;
  abbreviationId: number;
  sequence: string;

  //optional fields
  abbr?: string;
  actNo?: string;
  description?: string;
  area?: string;
  notes?: string;
  drawingNumber?: string;
  equipmentNumber?: string;
  typeOfWork?: string; //aka discipline
  resource?: string;
  time?: string;
  company?: string;
  tpNumber?: string;
  size1?: string;
  tag?: string;
  miscellaneousField1?: string;
  miscellaneousField2?: string;
  miscellaneousField3?: string;
  miscellaneousField4?: string;
  miscellaneousField5?: string;
  miscellaneousField6?: string;
  miscellaneousNumeric1?: number; //decimal
  miscellaneousNumeric2?: number; //decimal
  miscellaneousNumeric3?: number; //decimal
  miscellaneousNumeric4?: number; //decimal
  miscellaneousNumeric5?: number; //decimal
  miscellaneousNumeric6?: number; //decimal
}

export interface CalculatedFields {
  rate?: number;
  baseFactor?: number;
  bumped?: number;
  baseHours?: number;
  bumpedHr?: number;
  dollars?: number;
  runningTotal?: number;
  runningHrTotal?: number;
  bumpedRunningHrTotal?: number;
}

export type FactorTemplateDataDto = FactorTemplate & {
  resourceRates: ResourceWithRateDto[];
  activityNumberComponents: ActivityNumberComponentDto[];
};

export interface ResourceWithRateDto {
  resourceId: number;
  abbreviation: string;
  description: string;
  rate: number; //decimal
}

export interface ActivityNumberComponentDto {
  property: string;
  order: number;
  prefix: string;
  suffix: string;
}

export interface AbbreviationGetDto {
  id: number;
  abbr: string;
  description: string;
  discipline: string;
  factor: number;
}

export interface AbbreviationDescriptionGetDto {
  id: number;
  abbr: string;
  description: string;
  discipline: string;
  measure: string;
  extendedDescription: string;
}

export type SelectedRowDescriptor = {
  rowId: string;
  canDelete: boolean;
  canDemote: boolean;
};

export const isHeaderIndex = ({ subrowIndex }: RowIndex) => !subrowIndex;

export type RowIndex = { index: number; subrowIndex?: number };

export type HardFactorCategory = "SIPA";

export interface HardFactorDto {
  id: number;
  clientId: string;
  planId: number;
  factor: number;
  description: string;
  abbr?: string;
  actNo?: string;
  company?: string;
  resource?: string;
  sequence?: string;
  time?: string;
  miscellaneousField1?: string;
  miscellaneousField2?: string;
  miscellaneousField3?: string;
  rows?: number;
  hoursBeforeBump?: number;
  hoursAfterBump?: number;
  isDeleted: boolean;
  category?: HardFactorCategory;
}

export interface ColumnDefaultDto {
  columnName: string;
  defaultValue: string;
  isDuplicatingPrevious: boolean;
  planId: number;
}

export interface ReleaseNoteDto {
  title: string;
  description: string;
  noteType: string;
  workItemNumber: string;
  releaseDate: Date;
}

export type ReleaseNoteGetDto = ReleaseNoteDto & {
  id: number;
  clientId: string;
  modified: boolean;
};

import _ from "lodash";
import { Api } from "../../util/api/api";
import { History } from "history";
import {
  GetSiteDto,
  FactorTemplate,
  NewPlan,
  PlanGetDto,
  PlanLineItemState,
  UserDto,
} from "../../models";
import { toast } from "react-toastify";
import { Response } from "../../models";
import ResponseMessage from "../components/ResponseMessage";
import { validatePlan } from "./validations";
import columns from "./plans/planGridColumns";

export const ME_LOADING_SITES = "ME_LOADING_SITES";
export const ME_LOADED_SITES = "ME_LOAD_SITES";
export const ME_LOADING_SITES_FAILED = "ME_LOADING_SITES_FAILED";
export const SELECT_SITE = "SELECT_SITE";
export const LOGGED_OUT_SITE = "LOGGED_OUT_SITE";
export const ME_LOADING_PLANS = "ME_LOADING_PLANS";
export const ME_LOADED_PLANS = "ME_LOAD_PLANS";
export const ME_LOADING_PLANS_FAILED = "ME_LOADING_PLANS_FAILED";
export const ME_LOADED_FACTOR_TEMPLATES = "ME_LOADED_FACTOR_TEMPLATES";
export const SELECT_PLAN = "SELECT_PLAN";
export const OPEN_PLAN = "OPEN_PLAN";
export const CLOSE_PLAN = "CLOSE_PLAN";
export const SET_SITES_DISPLAY_STYLE = "SET_SITES_DISPLAY_STYLE";
export const EXPORTING_PLAN = "EXPORTING_PLAN";
export const EXPORTING_PLAN_FINISHED = "EXPORTING_PLAN_FINISHED";
export const EXPORT_VALIDATION_ERRORS = "EXPORT_VALIDATION_ERRORS";
export const SET_SITE_USERS = "SET_SITE_USERS";

export enum DisplayStyle {
  Dropdown,
  Grid,
}

export interface State {
  isLoading: boolean;
  isExporting: boolean;
  message?: string;
  sites?: GetSiteDto[];
  selectedSite?: GetSiteDto;
  selectedSiteIsSipa: boolean;
  siteUsers?: UserDto[];
  plans?: PlanGetDto[];
  selectedPlan?: PlanGetDto;
  sitesDisplayStyle: DisplayStyle;
  factorTemplates: FactorTemplate[];
  planExporting?: Number;
}

interface LoadedSites {
  type: typeof ME_LOADED_SITES;
  payload: GetSiteDto[];
}

interface LoadingSites {
  type: typeof ME_LOADING_SITES;
}

interface LoadingFailed {
  type: typeof ME_LOADING_SITES_FAILED;
  payload: string;
}

export interface SelectSite {
  type: typeof SELECT_SITE;
  payload: GetSiteDto;
}

interface LoadedPlans {
  type: typeof ME_LOADED_PLANS;
  payload: PlanGetDto[];
}

interface LoadedFactorTemplates {
  type: typeof ME_LOADED_FACTOR_TEMPLATES;
  payload: FactorTemplate[];
}

interface LoadingPlans {
  type: typeof ME_LOADING_PLANS;
}

interface LoadingPlansFailed {
  type: typeof ME_LOADING_PLANS_FAILED;
  payload: string;
}

interface SelectPlan {
  type: typeof SELECT_PLAN;
  payload: PlanGetDto;
}

interface OpenPlan {
  type: typeof OPEN_PLAN;
  payload: PlanGetDto;
}

interface ClosePlan {
  type: typeof CLOSE_PLAN;
}

interface SetSitesDisplayStyle {
  type: typeof SET_SITES_DISPLAY_STYLE;
  payload: DisplayStyle;
}

export interface LoggedOutSite {
  type: typeof LOGGED_OUT_SITE;
}

interface ExportingPlan {
  type: typeof EXPORTING_PLAN;
  payload: number;
}

interface ExportingPlanFinished {
  type: typeof EXPORTING_PLAN_FINISHED;
}

export interface ExportValidationErrors {
  type: typeof EXPORT_VALIDATION_ERRORS;
  payload: PlanLineItemState[];
}

export interface SetSiteUsers {
  type: typeof SET_SITE_USERS;
  payload: UserDto[];
}

export const logoutSite = (dispatch: React.Dispatch<LoggedOutSite>) => {
  return () => {
    dispatch({ type: LOGGED_OUT_SITE });
  };
};

export const loadMySites = (
  dispatch: React.Dispatch<LoadingSites | LoadedSites | LoadingFailed>
) => {
  return (api: Api) => {
    dispatch({ type: ME_LOADING_SITES });
    return api
      .get("/me/sites")
      .then(response => {
        dispatch({ type: ME_LOADED_SITES, payload: response.result.sites });
      })
      .catch(err => {
        dispatch({ type: ME_LOADING_SITES_FAILED, payload: err.message });
      });
  };
};

export type LoadMySitesDispatch = ReturnType<typeof loadMySites>;

export const selectSite =
  (dispatch: React.Dispatch<SelectSite>) =>
  (site: GetSiteDto, history: History): void => {
    dispatch({ type: SELECT_SITE, payload: site });
    history.push(`/sites/${site.id}/plans`);
  };

export type SelectSiteDispatch = ReturnType<typeof selectSite>;

export const loadMyPlans = (
  dispatch: React.Dispatch<LoadingPlans | LoadedPlans | LoadingPlansFailed>
) => {
  return (api: Api, siteId: number, loadSilently: boolean = false) => {
    if (!loadSilently) dispatch({ type: ME_LOADING_PLANS });
    return api
      .get("/me/sites/" + siteId + "/plans")
      .then(response => {
        dispatch({ type: ME_LOADED_PLANS, payload: response.result });
      })
      .catch(err => {
        dispatch({ type: ME_LOADING_PLANS_FAILED, payload: err.message });
      });
  };
};

export type LoadMyPlansDispatch = ReturnType<typeof loadMyPlans>;

export const loadMyFactorTemplates = (
  dispatch: React.Dispatch<LoadedFactorTemplates>
) => {
  return (api: Api, siteId: number) => {
    return api
      .get<Response<FactorTemplate[]>>(`/me/sites/${siteId}/factorTemplates`)
      .then(resp => {
        if (!resp.hasErrors) {
          dispatch({ type: ME_LOADED_FACTOR_TEMPLATES, payload: resp.result });
        } else {
          toast.error(ResponseMessage({ response: resp }));
        }
      });
  };
};

export type LoadMyFactorTemplatesDispatch = ReturnType<
  typeof loadMyFactorTemplates
>;

export const selectPlan =
  (dispatch: React.Dispatch<SelectPlan>) =>
  (plan: PlanGetDto): void => {
    dispatch({ type: SELECT_PLAN, payload: plan });
  };

export type SelectPlanDispatch = ReturnType<typeof selectPlan>;

export const openPlan = (dispatch: React.Dispatch<OpenPlan>) => {
  return (plan: PlanGetDto, history: History): void => {
    dispatch({ type: OPEN_PLAN, payload: plan });
    history.push(`/sites/${plan.siteId}/plans/${plan.id}`);
  };
};

export type OpenPlanDispatch = ReturnType<typeof openPlan>;

export const closePlan = (dispatch: React.Dispatch<ClosePlan>) => {
  return (history: History) => {
    const backUrl = history.location.pathname.replace(/\d+$/, "");
    history.replace(backUrl);
    dispatch({ type: CLOSE_PLAN });
  };
};

export type ClosePlanDispatch = ReturnType<typeof closePlan>;

export const createPlan = (
  dispatch: React.Dispatch<SelectPlan | LoadedPlans>
) => {
  return (plan: NewPlan, planList: PlanGetDto[], api: Api) => {
    return api.post("/plans", plan).then(response => {
      if (!response.hasErrors) {
        dispatch({
          type: ME_LOADED_PLANS,
          payload: planList.concat(response.result),
        });

        dispatch({ type: SELECT_PLAN, payload: response.result });

        return response;
      } else {
        return Promise.reject(response.errors.map(x => x.errorMessage));
      }
    });
  };
};

export type CreatePlanDispatch = ReturnType<typeof createPlan>;

export const editPlan = (
  dispatch: React.Dispatch<LoadedPlans | SelectPlan>
) => {
  return (plan: PlanGetDto, planList: PlanGetDto[], api: Api): Promise<any> => {
    return api.put("/plans", plan).then(response => {
      if (!response.hasErrors) {
        let planIndex = planList.findIndex(x => x.id === plan.id);
        planList.splice(planIndex, 1, plan);
        dispatch({ type: ME_LOADED_PLANS, payload: planList });
        dispatch({ type: SELECT_PLAN, payload: plan });
        return response;
      } else {
        return Promise.reject(response.errors.map(x => x.errorMessage));
      }
    });
  };
};

export type EditPlanDispatch = ReturnType<typeof editPlan>;

export const deletePlan = (dispatch: React.Dispatch<LoadedPlans>) => {
  return (plan: PlanGetDto, planList: PlanGetDto[], api: Api) => {
    return api
      .delete("/plans/" + plan.id, null)
      .then(response => {
        if (!response.hasErrors) {
          let planIndex = planList.findIndex(x => x.id === plan.id);
          planList.splice(planIndex, 1);
          dispatch({ type: ME_LOADED_PLANS, payload: planList });
          toast.info("Plan deleted successfully");
        } else {
          return Promise.reject(getErrors(response));
        }
      })
      .catch(err => {
        toast.error(err);
      });
  };
};

export type DeletePlanDispatch = ReturnType<typeof deletePlan>;

export const importArchive = (dispatch: React.Dispatch<LoadedPlans>) => {
  return (siteId: number, file: File, planList: PlanGetDto[], api: Api) => {
    var formData = new FormData();
    formData.append("archiveFile", file, file.name);
    return api
      .post<
        Response<{ plan: PlanGetDto; hasMessage: boolean; message: string }>
      >(`site/${siteId}/import`, formData)
      .then(response => {
        if (response.hasErrors) {
          toast.error(response.errors[0].errorMessage);
        } else {
          console.log(response);
          dispatch({
            type: ME_LOADED_PLANS,
            payload: planList.concat(response.result.plan),
          });

          if (response.result.hasMessage) {
            toast.info(response.result.message);
          }
        }
      })
      .catch(err => {
        toast.error(err);
      });
  };
};

export type ImportArchiveDispatch = ReturnType<typeof importArchive>;

export const setSitesDisplayStyle = (
  dispatch: React.Dispatch<SetSitesDisplayStyle>
) => {
  return (style: DisplayStyle) => {
    dispatch({ type: SET_SITES_DISPLAY_STYLE, payload: style });
  };
};

export type SetSitesDisplayStyleDispatch = ReturnType<
  typeof setSitesDisplayStyle
>;

export const exportPlan = dispatch => {
  return (
    planId: Number,
    api: Api,
    updateActNo: boolean,
    lines?: readonly Readonly<PlanLineItemState>[]
  ) => {
    dispatch({ type: EXPORTING_PLAN, payload: planId });

    if (lines) {
      const isInSequence = _.every(lines, x => x.isInSequence);
      const invalidItems = validatePlan(lines);
      const errorHeaders = _.uniqBy(invalidItems, x => x.parent.orderInExport)
        .map(x => x.parent.orderInExport)
        .join(", ");
      dispatch({ type: EXPORT_VALIDATION_ERRORS, payload: invalidItems });

      if (invalidItems.length > 0) {
        const cols = _.uniq(invalidItems.flatMap(x => Object.keys(x.errors)))
          .map(x => columns.find(col => col.accessor === x).title)
          .join(", ");
        return Promise.reject(
          `The plan could not be exported because of invalid data in the columns: (${cols}).  See the grid tasks below for details.  The following header line numbers have errors: ${errorHeaders}`
        );
      }

      if (!isInSequence) {
        return Promise.reject(
          `The plan could not be exported because line items are out of sequence.`
        );
      }
    }

    return api
      .get(`/plans/${planId}/export`, { updateActNo })
      .then(response => {
        if (response.hasErrors) {
          return Promise.reject(getErrors(response));
        } else {
          return Promise.resolve(response);
        }
      })
      .finally(() => {
        dispatch({ type: EXPORTING_PLAN_FINISHED });
      });
  };
};

export type ExportPlanDispatch = ReturnType<typeof exportPlan>;

export const setSiteUsers =
  (dispatch: React.Dispatch<SetSiteUsers>) => (api: Api, siteId: number) => {
    return api.get("/sites/" + siteId + "/users").then(response => {
      if (!response.hasErrors) {
        dispatch({
          type: SET_SITE_USERS,
          payload: response.result,
        });
      } else {
        toast.error(ResponseMessage({ response }));
      }
    });
  };

export type SetSiteUsersDispatch = ReturnType<typeof setSiteUsers>;

export const sharePlan = (planId: number, userId: number, api: Api) => {
  return api.post(`/plans/${planId}/share`, { userId }).then(response => {
    if (!response.hasErrors) {
      return Promise.resolve(response);
    } else {
      return Promise.reject(getErrors(response));
    }
  });
};

export type SharePlanDispatch = ReturnType<typeof sharePlan>;

const getErrors = (response): string => {
  return response.errors.map(x => x.errorMessage).join();
};

export type MeActionTypes =
  | LoadedSites
  | LoadingSites
  | LoadingFailed
  | SelectSite
  | LoggedOutSite
  | LoadedPlans
  | LoadingPlans
  | LoadingPlansFailed
  | SelectPlan
  | SetSitesDisplayStyle
  | LoadedFactorTemplates
  | OpenPlan
  | ClosePlan
  | ExportingPlan
  | ExportingPlanFinished
  | ExportValidationErrors
  | SetSiteUsers;

import React, { useState } from "react";
import { Input, Button, Modal, Form, Dropdown } from "semantic-ui-react";
import { toast } from "react-toastify";
import AppContext from "../../../app/AppContext";
import ResponseMessage from "../../components/ResponseMessage";
import { Claims, UserHasClaim } from "../../../util/security/security";
import { User } from "../../../models";

type Props = {
  open: boolean;
  onClose: () => void;
  admin: User;
};

function AddPlannerModal(props: Props) {
  const { api } = React.useContext(AppContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("user");

  const dropdownOptions = [
    { key: "user", value: "user", text: "User" },
    {
      key: "corporate-admin",
      value: "corporate-admin",
      text: "Corporate Admin",
      disabled: !UserHasClaim(props.admin, Claims.AdminClaim),
    },
    {
      key: "super-admin",
      value: "super-admin",
      text: "Super Admin",
      disabled: !UserHasClaim(props.admin, Claims.AdminClaim),
    },
  ];

  const _addUser = () => {
    const endpoint =
      role === "user"
        ? "add"
        : role === "corporate-admin"
        ? "add-admin"
        : role === "super-admin"
        ? "add-super-admin"
        : null;

    api
      .post(`users/${endpoint}`, {
        name: name,
        email: email,
        addingNewUser: true,
      })
      .then(resp => {
        if (resp.hasErrors) {
          toast.error(<ResponseMessage response={resp} />);
        } else {
          _onClose();
        }
      })
      .catch();
  };

  const _onClose = () => {
    setName("");
    setEmail("");
    props.onClose();
  };

  return (
    <Modal
      onClose={props.onClose}
      size="tiny"
      open={props.open}
      closeOnDimmerClick={false}
    >
      <Modal.Header>Add New User</Modal.Header>
      <Modal.Content>
        <Form onSubmit={_addUser}>
          <Form.Field
            control={Input}
            id="name"
            label=" Name"
            onChange={(e, { value }) => setName(value)}
            value={name}
          />
          <Form.Field
            control={Input}
            id="email"
            label=" Email"
            onChange={(e, { value }) => setEmail(value)}
            required
            value={email}
          />
          <Form.Field
            control={Dropdown}
            id="role"
            label=" Role"
            onChange={(e, { value }) => {
              setRole(value);
            }}
            required
            options={dropdownOptions}
            value={role}
          />
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={_onClose} secondary />
        <Button content="Save" icon="save" onClick={_addUser} primary />
      </Modal.Actions>
    </Modal>
  );
}

export default AddPlannerModal;

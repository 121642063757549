import React from "react";
import { UserGetDtoWithPlans, PlanGetDto, GetSiteDto } from "../../../models";
import { Table, Button, Modal } from "semantic-ui-react";
import moment from "moment";
import { getSiteDetails, GetSiteDetailsDispatch } from "../actions";
import { connect } from "react-redux";
import AppContext from "../../../app/AppContext";
import { AppState } from "../../../app/AppState";
import { toast } from "react-toastify";
import { getErrorMessage } from "../../../util";
import { copyPlan, CopyPlanDispatch } from "../../admin/actions";
import { getUserDisplayName } from "../../me/helpers";

type Props = {
  user: UserGetDtoWithPlans;
  open: boolean;
  onClose: () => void;
  selectedSite: GetSiteDto;
  me: number;
} & DispatchProps;

//TODO: Api call to get detailed plan info from the user
function ManageUserPlansModal(props: Props) {
  const { api } = React.useContext(AppContext);
  const { getSiteDetails, selectedSite } = props;

  const _handleCopy = async (plan: PlanGetDto) => {
    let result = await props.copyPlan(plan.id, api, false);

    if (result.hasErrors) {
      toast.error(getErrorMessage(result.errors));
    } else {
      await getSiteDetails(api, selectedSite.id);
      toast.info("plan copied successfully");
    }
  };

  return (
    <Modal closeOnDimmerClick={false} open={props.open} onClose={props.onClose}>
      <Modal.Header>{`Manage Plans for ${getUserDisplayName(
        props.user
      )}`}</Modal.Header>

      <Modal.Content>
        <Table columns={5} padded striped unstackable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Title</Table.HeaderCell>

              <Table.HeaderCell textAlign="right">
                Work Item Number
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="right">
                Base Man Hours
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="center" collapsing>
                Date Created
              </Table.HeaderCell>

              <Table.HeaderCell textAlign="center" collapsing />
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {props.user?.plans?.map(x => (
              <Table.Row key={x?.id}>
                <Table.Cell textAlign="left">
                  {x?.jobNumber} - {x?.title}
                </Table.Cell>

                <Table.Cell textAlign="right">{x?.jobNumber}</Table.Cell>

                <Table.Cell textAlign="right">{x?.baseHours}</Table.Cell>

                <Table.Cell textAlign="center">
                  {moment(x?.createdDate).format("YYYY-MM-DD")}
                </Table.Cell>

                <Table.Cell collapsing textAlign="center">
                  <Button
                    content="Copy Plan"
                    icon="copy"
                    onClick={() => _handleCopy(x)}
                    primary
                    size="small"
                  />
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Modal.Content>

      <Modal.Actions>
        <Button content="Close" onClick={() => props.onClose()} secondary />
      </Modal.Actions>
    </Modal>
  );
}

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    copyPlan: copyPlan(dispatch),
    getSiteDetails: getSiteDetails(dispatch),
  };
};

const mapStateToProps = (state: AppState) => ({
  selectedSite: state.me.selectedSite,
  me: state.auth.user?.id,
});

interface DispatchProps {
  copyPlan: CopyPlanDispatch;
  getSiteDetails: GetSiteDetailsDispatch;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUserPlansModal);

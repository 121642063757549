import { Api } from "../../util/api/api";
import {
  Response,
  UserGetDto,
  SiteGetDto,
  SiteDto,
  PlanGetDto,
  EditSiteDto,
  SiteSummaryDto,
  PlanSummaryDto,
} from "../../models";
import { toast } from "react-toastify";
import ResponseMessage from "../components/ResponseMessage";
import { getErrorMessage } from "../../util";
import _ from "lodash";

export const SEARCH_EMPLOYEE = "SEARCH_EMPLOYEE";
export const GET_SITES = "GET_SITES";
export const GET_USER_SITES = "GET_USER_SITES";
export const GET_USER_SITE_PLANS = "GET_USER_SITE_PLANS";
export const DISMISS_USER_INFO = "DISMISS_USER_INFO";

interface SearchEmployee {
  type: typeof SEARCH_EMPLOYEE;
  payload: UserGetDto[];
}

interface GetSites {
  type: typeof GET_SITES;
  sites: SiteGetDto[];
}

interface GetUserSites {
  type: typeof GET_USER_SITES;
  user: UserGetDto;
  sites: SiteSummaryDto[];
}

interface GetUserSitePlans {
  type: typeof GET_USER_SITE_PLANS;
  site: SiteSummaryDto;
  plans: PlanSummaryDto[];
}

interface DismissUserInfo {
  type: typeof DISMISS_USER_INFO;
}

export const searchEmployee = (dispatch: React.Dispatch<SearchEmployee>) => {
  return async (api, query: string) => {
    let searchResult = (await api.get(
      "/users/search/" + query,
      null
    )) as Response<UserGetDto[]>;
    if (!searchResult.hasErrors) {
      let users = _.sortBy(searchResult.result, x => x.name);

      dispatch({
        type: SEARCH_EMPLOYEE,
        payload: users,
      });
    } else {
      toast.error(getErrorMessage(searchResult.errors));

      dispatch({
        type: SEARCH_EMPLOYEE,
        payload: [],
      });
    }
  };
};

export type SearchEmployeeDispatch = ReturnType<typeof searchEmployee>;

export function downloadUserReport(api: Api): Promise<any> {
  return api
    .get("/users/export", null, {
      responseType: "blob",
    })
    .then(blob => {
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "user-report.xlsx");
      link.setAttribute("style", "display: none");
      document.body.appendChild(link);
      link.click();
      window.setTimeout(() => {
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 200);
      return Promise.resolve();
    });
}
export type DownloadUserReportDispatch = ReturnType<typeof downloadUserReport>;

export function emailUserReport(api: Api): Promise<any> {
  return api.get("/users/export", { sendEmail: "true" });
}
export type EmailUserReportDispatch = ReturnType<typeof emailUserReport>;

export const getSites = dispatch => {
  return async (api: Api) => {
    let sites = (await api.get("/sites", null)) as Response<SiteGetDto[]>;
    if (sites.hasErrors) {
      toast(sites.errors.map(x => x.errorMessage).join(" "), {
        autoClose: false,
        position: "top-center",
      });
    } else {
      dispatch({
        type: GET_SITES,
        sites: _.sortBy(sites.result, x => x.name),
      });
    }
  };
};
export type GetSitesDispatch = ReturnType<typeof getSites>;

export const createSite = dispatch => {
  return (
    site: SiteDto,
    siteList: SiteGetDto[],
    api: Api
  ): Promise<Response<any>> => {
    return api
      .post("/sites", { site })
      .then(response => {
        if (!response.hasErrors) {
          dispatch({
            type: GET_SITES,
            sites: _.sortBy(
              siteList.concat({
                id: response.result.id,
                name: response.result.name,
                plans: [],
                users: [],
                siteAdmins: [],
              }),
              x => x.name
            ),
          });
        }
        return response;
      })
      .catch();
  };
};
export type CreateSiteDispatch = ReturnType<typeof createSite>;

export const editSite = dispatch => {
  return (
    site: EditSiteDto,
    siteId: number,
    siteList: SiteGetDto[],
    api: Api
  ): Promise<Response<any>> => {
    return api.patch(`/sites/${siteId}`, site).then(response => {
      if (!response.hasErrors) {
        let index = siteList.findIndex(x => x.id === siteId);
        let currentSite = siteList.find(x => x.id === siteId);
        currentSite.name = site.name;
        siteList.splice(index, 1, currentSite);
        dispatch({ type: GET_SITES, sites: siteList });
      }
      return response;
    });
  };
};
export type EditSiteDispatch = ReturnType<typeof editSite>;

export const deleteSite = dispatch => {
  return (
    siteId: number,
    siteList: SiteGetDto[],
    api: Api
  ): Promise<Response<any>> => {
    return api.delete(`/sites/${siteId}`, null).then(response => {
      if (!response.hasErrors) {
        let index = siteList.findIndex(x => x.id === siteId);
        siteList.splice(index, 1);
        dispatch({ type: GET_SITES, sites: siteList });
      }
      return response;
    });
  };
};
export type DeleteSiteDispatch = ReturnType<typeof deleteSite>;

export const addSiteAdmin = dispatch => {
  return (
    siteId: number,
    user: UserGetDto,
    siteList: SiteGetDto[],
    api: Api
  ) => {
    return api
      .post(`/sites/${siteId}/user`, { userId: user.id, isAdmin: true })
      .then(response => {
        if (!response.hasErrors) {
          let sites = siteList.slice();
          let currentSite = sites.find(x => x.id === siteId);
          currentSite.siteAdmins = currentSite.siteAdmins.slice();
          currentSite.siteAdmins.push(user);
          dispatch({ type: GET_SITES, sites: sites });
        }
        return response;
      });
  };
};
export type AddSiteAdminDispatch = ReturnType<typeof addSiteAdmin>;

export const removeSiteAdmin = dispatch => {
  return (siteId: number, userId: number, siteList: SiteGetDto[], api: Api) => {
    return api
      .delete(`/sites/${siteId}/user/${userId}`, null)
      .then(response => {
        if (!response.hasErrors) {
          let sites = siteList.slice();
          let currentSite = sites.find(x => x.id === siteId);
          currentSite.siteAdmins = currentSite.siteAdmins.slice();
          currentSite.siteAdmins.splice(
            currentSite.siteAdmins.findIndex(x => x.id === userId),
            1
          );
          dispatch({ type: GET_SITES, sites: sites });
        }
        return response;
      });
  };
};
export type RemoveSiteAdminDispatch = ReturnType<typeof removeSiteAdmin>;

export const getUsers = (api: Api) => {
  return () => {
    return api.get("/users", null) as Promise<Response<UserGetDto[]>>;
  };
};
export type GetUsersDispatch = ReturnType<typeof getUsers>;

export const deletePlan = dispatch => {
  return (
    planId: number,
    siteId: number,
    siteList: SiteGetDto[],
    api: Api
  ): Promise<Response<any>> => {
    return api.delete(`/plans/${planId}`, null).then(response => {
      if (!response.hasErrors) {
        let sites = siteList.slice();
        let currentSite = sites.find(x => x.id === siteId);
        currentSite.plans = currentSite.plans.slice();
        currentSite.plans.splice(
          currentSite.plans.findIndex(x => x.id === planId),
          1
        );
        dispatch({ type: GET_SITES, sites: sites });
      }
      return response;
    });
  };
};
export type DeletePlanDispatch = ReturnType<typeof deletePlan>;

export const copyPlan = dispatch => {
  return (planId: number, api: Api, refreshSites: boolean) => {
    return api
      .post(`/plans/${planId}/copy`, {})
      .then(async (response: Response<PlanGetDto>) => {
        if (!response.hasErrors) {
          if (refreshSites) await getSites(dispatch)(api);
        } else {
          toast.error(ResponseMessage({ response }));
        }

        return response;
      });
  };
};
export type CopyPlanDispatch = ReturnType<typeof copyPlan>;

export const getUserSites = (dispatch: React.Dispatch<GetUserSites>) => {
  return (user: UserGetDto, api: Api) => {
    return api
      .get<Response<{ sites: SiteSummaryDto[] }>>(`/users/${user.id}/sites`)
      .then(resp => {
        if (!resp.hasErrors) {
          dispatch({ type: GET_USER_SITES, user, sites: resp.result.sites });
        } else {
          toast.error(getErrorMessage(resp.errors));
        }
      });
  };
};

export type GetUserSitesDispatch = ReturnType<typeof getUserSites>;

export const getUserSitePlans = (
  dispatch: React.Dispatch<GetUserSitePlans>
) => {
  return (userId: number, site: SiteSummaryDto, api: Api) => {
    return api
      .get<Response<{ plans: PlanSummaryDto[] }>>(
        `/users/${userId}/sites/${site.id}/plans`
      )
      .then(resp => {
        if (!resp.hasErrors) {
          dispatch({
            type: GET_USER_SITE_PLANS,
            site,
            plans: resp.result.plans,
          });
        } else {
          toast.error(getErrorMessage(resp.errors));
        }
      });
  };
};

export type GetUserSitePlansDispatch = ReturnType<typeof getUserSitePlans>;

export const dismissUserInfo = dispatch => {
  return () => {
    dispatch({ type: DISMISS_USER_INFO });
  };
};

export type DismissUserInfoDispatch = ReturnType<typeof dismissUserInfo>;

export type AdminActionTypes =
  | SearchEmployee
  | GetSites
  | GetUserSites
  | GetUserSitePlans
  | DismissUserInfo;

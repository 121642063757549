import { AccessParams, loginRequest, logoutRequest } from "./authConfig";
import moment from "moment";
import { Response } from "../../models";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";

export interface AuthConfig {
  loginEndpoint: string;
}

export interface AuthInfo {
  token: string;
  user: User;
}

export class Auth {
  Client: Auth0ContextInterface<User>;
  Config: AuthConfig;
  User: User;
  LoggedIn: boolean;
  Token: string;

  constructor(config: AuthConfig, client: Auth0ContextInterface<User>) {
    this.Config = config;
    this.Client = client;
    this.LoggedIn = false;
    this.Token = "";
  }

  login(params?: AccessParams): Promise<User> {
    const request = { ...loginRequest, ...params };
    return this._acquireTokenSilent(request)
      .then(resp => this._exchangeToken(this.Config, resp))
      .catch(error => {
        console.log("Silent token acquisition failed. Logging in normally.");
        return this._acquireTokenPopup(request).then(resp =>
          this._exchangeToken(this.Config, resp)
        );
      });
  }

  loginSilent(params?: AccessParams) {
    return this._acquireTokenSilent({
      ...loginRequest,
      ...params,
    }).then(resp => this._exchangeToken(this.Config, resp));
  }

  private _exchangeToken(config: AuthConfig, resp: string): Promise<User> {
    const headers = new Headers();
    const bearer = `Bearer ${resp}`;

    headers.append("Authorization", bearer);

    return fetch(this.Config.loginEndpoint, {
      method: "POST",
      headers: headers,
    })
      .then(resp => resp.json() as Promise<Response<AuthInfo>>)
      .then(info => {
        const errors = info.errors.map(x => x.errorMessage);
        if (info.hasErrors)
          return Promise.reject({
            message: "An error occurred while logging in: " + errors.join(", "),
          });

        this.User = info.result.user;
        this.Token = info.result.token;
        return this.User;
      });
  }

  logout() {
    this.Client.logout(logoutRequest);
  }

  account(): User {
    return this.Client.user;
  }

  _isTokenExpired = token =>
    this._getExpirationDate(token).unix() < moment().unix();

  _getExpirationDate = token => moment.unix(parseInt(token.exp, 10));

  private _acquireTokenSilent(params: AccessParams): Promise<string> {
    return this.Client.getAccessTokenSilently(params);
  }

  private _acquireTokenPopup(
    params: AccessParams
  ): Promise<undefined | string> {
    return this.Client.getAccessTokenWithPopup(params);
  }

  private _loginPopup(params?: AccessParams): Promise<void> {
    return this.Client.loginWithPopup(params);
  }

  private _loginRedirect(params?: AccessParams): Promise<void> {
    return this.Client.loginWithRedirect(params);
  }

  getToken() {
    if (!this.validateAuthentication() || !!this.User === false) {
      return null;
    }

    let token = this.Token;

    if (!token) {
      //   this.Config.onTokenRevoked();
      this.LoggedIn = false;
    }

    return token;
  }

  validateAuthentication() {
    const user = this.account();
    this.LoggedIn = !!user && !!this.Token;

    //TODO: make sure this is ok
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/752#issuecomment-565208090
    // if (this.LoggedIn && this._isTokenExpired(user.idToken)) {
    //   this.LoggedIn = false;
    //   //   this.Config.onTokenRevoked();
    // }

    return this.LoggedIn;
  }
}

import React, { useCallback } from "react";
import { Input, Popup, Ref } from "semantic-ui-react";
import _ from "lodash";
import { makeKeyListener, kbd } from "../../../util/keyboard";
import "./controls.css";
import * as className from "classnames";
import { Issue } from "../../../models";
import ReactTooltip from "react-tooltip";
import { AppState } from "../../../app/AppState";
import { connect } from "react-redux";
import { addAbbrExplicit } from "../../me/plans/actions";

const showBlankValue = (row, initialValue, columnId) => {
  return (
    _.isNull(initialValue) ||
    _.isUndefined(initialValue) ||
    (!row.original.isHeader &&
      ["duration", "numberOfMen"].includes(columnId)) ||
    (row.original.isHeader &&
      !["actNo", "baseHours", "bumpedHr", "dollars"].includes(columnId))
  );
};

const useInputCell = ({
  addCopyPreviousValue,
  initialValue,
  format,
  columnId,
  copyPrevValueEnabled,
  row,
  updateData,
  type,
}) => {
  const inputRef = React.useRef<HTMLInputElement>();

  const [value, setValue] = React.useState(
    typeof format !== "undefined" ? format(initialValue) : initialValue
  );

  const onBlur = useCallback(() => {
    if (value !== initialValue) {
      const [headerIndex, subrowIndex] = row.id.split(".");

      const isNumeric = _.isNumber(initialValue);
      const cval = isNumeric ? Number(value) : value;

      updateData(Number(headerIndex), Number(subrowIndex), {
        [columnId]: isNumeric && _.isNaN(cval) ? 0 : cval,
      });
      setValue(typeof format !== "undefined" ? format(value) : value);

      //captures the last entry added for a particular column on any row
      //this is provided by the "usePreviousValue" hook.
      // TODO: is this needed anymore?
      addCopyPreviousValue({
        colAccessor: columnId,
        enabled: copyPrevValueEnabled,
      });
    }

    ReactTooltip.rebuild();
  }, [
    addCopyPreviousValue,
    columnId,
    copyPrevValueEnabled,
    format,
    initialValue,
    row.id,
    updateData,
    value,
  ]);

  const onChange = (e, { value }) => {
    if (type === "int") {
      value = value.replace(/[^0-9]/g, "");
    }
    setValue(value);
  };

  React.useEffect(() => {
    setValue(
      typeof format !== "undefined" ? format(initialValue) : initialValue
    );
  }, [format, initialValue]);

  React.useEffect(() => {
    const { current } = inputRef;

    const [setKey, removeKey] = makeKeyListener(current, false, false);

    if (current) {
      setKey(kbd("Ctrl-s"), onBlur);
      setKey(kbd("Meta-s"), onBlur);

      return () => {
        removeKey(kbd("Ctrl-s"));
        removeKey(kbd("Meta-s"));
      };
    }
  }, [onBlur]);

  return {
    inputRef,
    value,
    onBlur,
    onChange,
  };
};

export function InputCell({
  value: initialValue,
  errors,
  editable,
  row,
  column: {
    id: columnId,
    format,
    addCopyPreviousValue,
    copyPrevValueEnabled,
    type,
  },
  updateData,
  cell,
  isSelected,
}): React.ReactElement {
  const { inputRef, value, onBlur, onChange } = useInputCell({
    addCopyPreviousValue,
    initialValue,
    format,
    columnId,
    copyPrevValueEnabled,
    row,
    updateData,
    type,
  });

  React.useEffect(() => {
    if (inputRef.current && isSelected) {
      //TODO: figure out why the `Ref` doesn't return the actual input element
      const input = inputRef.current.firstChild as HTMLInputElement;
      input.focus();
      input.select();
    }
  }, [isSelected, inputRef.current]);

  if (!editable) {
    return showBlankValue(row, initialValue, columnId)
      ? ""
      : typeof format !== "undefined"
      ? format(initialValue)
      : `${initialValue}`;
  }
  const error = errors.hasOwnProperty(columnId);
  const message = errors[columnId] as Issue;

  return (
    <>
      <Ref innerRef={inputRef}>
        <Input
          className={className({
            expandable: true,
            resequence: !row.original.isInSequence && columnId === "sequence",
          })}
          value={value || ""}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          data-tip={error ? message.description : ""}
          data-type={
            error ? (message.type === "export" ? "error" : message.type) : ""
          }
          data-for="control"
        />
      </Ref>
    </>
  );
}

function AbbrInputCell({
  abbreviations,
  value: initialValue,
  errors,
  editable,
  row,
  column: {
    id: columnId,
    format,
    addCopyPreviousValue,
    copyPrevValueEnabled,
    type,
  },
  updateData,
  cell,
  isSelected,
  addAbbrExplicit,
}): React.ReactElement {
  const { inputRef, value, onBlur, onChange } = useInputCell({
    addCopyPreviousValue,
    initialValue,
    format,
    columnId,
    copyPrevValueEnabled,
    row,
    updateData,
    type,
  });

  const _localOnBlur = () => {
    const selection = abbreviations.find(
      x => x.abbr.toUpperCase() === value.toUpperCase()
    );
    if (selection && initialValue !== value) {
      addAbbrExplicit({ abbr: selection, rowId: row.id });
    }

    onBlur();
  };

  const _localOnChange = (_, { value }) => {
    onChange(_, { value: value.toUpperCase() });
  };

  React.useEffect(() => {
    if (inputRef.current && isSelected) {
      //TODO: figure out why the `Ref` doesn't return the actual input element
      const input = inputRef.current.firstChild as HTMLInputElement;
      input.focus();
      input.select();
    }
  }, [isSelected, inputRef]);

  if (!editable) {
    return showBlankValue(row, initialValue, columnId)
      ? ""
      : typeof format !== "undefined"
      ? format(initialValue)
      : `${initialValue}`;
  }
  const error = errors.hasOwnProperty(columnId);
  const message = errors[columnId] as Issue;

  return (
    <>
      <Ref innerRef={inputRef}>
        <Input
          className={className({
            expandable: true,
            resequence: !row.original.isInSequence && columnId === "sequence",
          })}
          value={value || ""}
          onChange={_localOnChange}
          onBlur={_localOnBlur}
          error={error}
          data-tip={error ? message.description : ""}
          data-type={
            error ? (message.type === "export" ? "error" : message.type) : ""
          }
          data-for="control"
        />
      </Ref>
    </>
  );
}

export function BumpedInputCell({
  value: initialValue,
  errors,
  editable,
  row,
  column: { id: columnId, format, addCopyPreviousValue, copyPrevValueEnabled },
  updateData,
  cell,
  isSelected,
  type,
}): React.ReactElement {
  const { inputRef, value } = useInputCell({
    addCopyPreviousValue,
    initialValue,
    format,
    columnId,
    copyPrevValueEnabled,
    row,
    updateData,
    type,
  });

  React.useEffect(() => {
    if (inputRef.current && isSelected) {
      //TODO: figure out why the `Ref` doesn't return the actual input element
      const input = inputRef.current.firstChild as HTMLInputElement;
      input.focus();
      input.select();
    }
  }, [isSelected, inputRef.current]);

  const hasHardfactors =
    row.original.hardFactors &&
    !row.original.isHeader &&
    row.original.hardFactors.length > 0;

  return (
    <>
      <Popup
        disabled={!hasHardfactors}
        trigger={
          <div
            className={className({
              expandable: true,
              resequence: !row.original.isInSequence && columnId === "sequence",
              hardfactored: hasHardfactors,
            })}
          >
            {value}
          </div>
        }
      >
        <Popup.Header>Hard Factors</Popup.Header>
        <Popup.Content>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
            }}
          >
            {row.original?.hardFactors?.map(x => {
              return (
                <span>
                  {x.description.toUpperCase()} - {x.factor}
                </span>
              );
            })}
          </div>
        </Popup.Content>
      </Popup>
    </>
  );
}

export const InputListSelector = (listResource: string) => {
  const InputList = ({
    value: initialValue,
    errors,
    editable,
    row,
    column: {
      id: columnId,
      format,
      addCopyPreviousValue,
      copyPrevValueEnabled,
      type,
    },
    updateData,
    isSelected,
  }) => {
    const { inputRef, value, onBlur, onChange } = useInputCell({
      addCopyPreviousValue,
      initialValue,
      format,
      columnId,
      copyPrevValueEnabled,
      row,
      updateData,
      type,
    });

    React.useEffect(() => {
      if (inputRef.current && isSelected) {
        //TODO: figure out why the `Ref` doesn't return the actual input element
        const input = inputRef.current.firstChild as HTMLInputElement;
        input.focus();
        input.select();
      }
    }, [isSelected, inputRef]);

    React.useEffect(() => {
      const { current } = inputRef;

      if (current) {
        const [setKey, removeKey] = makeKeyListener(current, false, true);

        setKey(kbd("<enter>"), () => {
          const input = current.firstChild as HTMLInputElement;
          input.blur();
        });

        return () => {
          removeKey(kbd("<enter>"));
        };
      }
    }, [inputRef.current]);

    if (!editable) {
      return showBlankValue(row, initialValue, columnId)
        ? ""
        : typeof format !== "undefined"
        ? format(initialValue)
        : `${initialValue}`;
    }

    const error = errors.hasOwnProperty(columnId);
    const message = errors[columnId] as Issue;

    return (
      <Ref innerRef={inputRef}>
        <Input
          value={value || ""}
          list={listResource}
          onChange={onChange}
          onBlur={onBlur}
          onMouseDown={onBlur}
          error={error}
          data-tip={error ? message.description : ""}
          data-type={
            error ? (message.type === "export" ? "error" : message.type) : ""
          }
          data-for="control"
        />
      </Ref>
    );
  };

  return InputList;
};

const mapStateToProps = (state: AppState) => {
  return {
    abbreviations: state.abbr.abbreviations,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    addAbbrExplicit: addAbbrExplicit(dispatch),
  };
};

export const AbbrInputCellExport = connect(
  mapStateToProps,
  mapDispatchToProps
)(AbbrInputCell);

import React from "react";
import { Route, Redirect, Switch, useLocation } from "react-router-dom";

import ManagePlanners from "./ManagePlanners";
import { Layout } from "../../layout/Layout";
import AppHeader from "../../layout/AppHeader";
import ManageSites from "./ManageSites";
import SiteAdmin from "../siteAdmin/SiteAdmin";
import SiteTemplates from "../sites/templates";

export const AdminRoutes = () => {
  const location = useLocation();
  return (
    <Layout>
      <AppHeader />
      <Switch>
        <Route exact path={"/admin/planners"} component={ManagePlanners} />
        <Route exact path={"/admin/sites"} component={ManageSites} />
        <Redirect to={{ pathname: "/", state: { from: location.pathname } }} />
      </Switch>
    </Layout>
  );
};

export const SiteAdminRoutes = () => {
  const location = useLocation();

  return (
    <Layout>
      <AppHeader />
      <Switch>
        <Route exact path="/sites/:siteId/admin" component={SiteAdmin} />
        <Route
          exact
          path="/sites/:siteId/admin/templates"
          component={SiteTemplates}
        />
        <Redirect to={{ pathname: "/", state: { from: location.pathname } }} />
      </Switch>
    </Layout>
  );
};

import React from "react";
import { Auth } from "../util/auth/client";
import { Api } from "../util/api/api";
import { useAuth0 } from "@auth0/auth0-react";
import { createApi } from "../util/api/api";

export interface IAppContext {
  auth: Auth;
  api: Api;
  saveInterval: number;
}

const AppContext = React.createContext<IAppContext>(null);

export const AppContextProvider = ({ children, dispatch }) => {

  const auth0 = useAuth0();

  const authClient = React.useMemo(
    () =>
      new Auth(
        {
          loginEndpoint: `${process.env.REACT_APP_API_BASE_URL}/auth/login/aad`,
        },
        auth0
      ),
    [auth0]
  );


  const appContext: IAppContext = React.useMemo(
    () => ({
      auth: authClient,
      api: createApi(dispatch, authClient),
      saveInterval: parseInt(
        `${process.env.REACT_APP_PLAN_DATA_SAVE_INTERVAL}`
      ),
    }),
    [authClient, dispatch]
  );

  return (
    <AppContext.Provider value={appContext}>{children}</AppContext.Provider>
  )
}

export default AppContext;
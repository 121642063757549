import React from "react";
import { Hooks, actions, makePropGetter, ensurePluginOrder } from "react-table";

actions.toggleContextMenu = "toggleContextMenu";

export const useContextMenu = (hooks: Hooks) => {
  hooks.getContextMenuRowProps = [defaultGetContextMenuRowProps];
  hooks.stateReducers.push(reducer);
  hooks.useInstance.push(useInstance);
  hooks.prepareRow.push(prepareRow);
};

const defaultGetContextMenuRowProps = (props, { instance, row }) => [
  props,
  {
    onContextMenu: (e: React.MouseEvent) => {
      e.preventDefault();
      const target = (e.target as HTMLElement).closest("td");
      const offsetParent = target.offsetParent as HTMLElement;
      const offsetTop = offsetParent.scrollTop - offsetParent.offsetTop;
      const offsetLeft = offsetParent.scrollLeft - offsetParent.offsetLeft;

      row.toggleContextMenu(e.clientX + offsetLeft, e.clientY + offsetTop, row);
    },
  },
];

function reducer(state, action, prevState, instance) {
  if (action.type === actions.init) {
    return {
      ...state,
      contextMenuState: { isVisible: false, row: null, column: null },
    };
  } else if (action.type === actions.toggleContextMenu) {
    const { isVisible, pageX, pageY, row } = action;

    const column = state.selectedCell?.column;

    return {
      ...state,
      contextMenuState: { isVisible, pageX, pageY, row, column },
    };
  } else {
    return state;
  }
}

function useInstance(instance) {
  const { dispatch, plugins } = instance;

  ensurePluginOrder(plugins, ["useCellSelect"], "useContextMenu");

  const toggleContextMenu = React.useCallback(
    (pageX, pageY, row) => {
      dispatch({
        type: actions.toggleContextMenu,
        isVisible: true,
        pageX,
        pageY,
        row,
      });
    },
    [dispatch]
  );

  React.useEffect(() => {
    document.onclick = e =>
      dispatch({ type: actions.toggleContextMenu, isVisible: false });

    return () => (document.onclick = null);
  }, [dispatch]);

  Object.assign(instance, {
    toggleContextMenu,
  });
}

function prepareRow(row, { instance: { getHooks }, instance }) {
  row.getContextMenuRowProps = makePropGetter(
    instance.getHooks().getContextMenuRowProps,
    { instance: instance, row }
  );
  row.toggleContextMenu = (pageX: number, pageY: number) =>
    instance.toggleContextMenu(pageX, pageY, row);
}

useContextMenu.pluginName = "useContextMenu";

export default useContextMenu;

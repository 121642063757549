import {
  State,
  MeActionTypes,
  ME_LOADING_SITES,
  ME_LOADED_SITES,
  ME_LOADING_SITES_FAILED,
  SELECT_SITE,
  LOGGED_OUT_SITE,
  ME_LOADING_PLANS,
  ME_LOADED_PLANS,
  ME_LOADING_PLANS_FAILED,
  SELECT_PLAN,
  SET_SITES_DISPLAY_STYLE,
  DisplayStyle,
  ME_LOADED_FACTOR_TEMPLATES,
  OPEN_PLAN,
  CLOSE_PLAN,
  EXPORTING_PLAN,
  EXPORTING_PLAN_FINISHED,
  EXPORT_VALIDATION_ERRORS,
  SET_SITE_USERS,
} from "./actions";

const initialState: State = {
  isLoading: true,
  isExporting: false,
  planExporting: null,
  sitesDisplayStyle: DisplayStyle.Grid,
  factorTemplates: [],
  selectedSiteIsSipa: false,
};

const meReducer = (state: State = initialState, action: MeActionTypes) => {
  switch (action.type) {
    case ME_LOADING_SITES:
      return { ...state, isLoading: true };

    case ME_LOADED_SITES:
      return { ...state, sites: action.payload, isLoading: false };

    case ME_LOADING_SITES_FAILED:
      return { ...state, message: action.payload, isLoading: false };

    case SELECT_SITE:
      return {
        ...state,
        selectedSite: action.payload,
        selectedSiteIsSipa: action.payload.name.includes("SIPA"),
      };

    case LOGGED_OUT_SITE:
      return {
        ...state,
        selectedSite: null,
        selectedSiteIsSipa: false,
        siteUsers: null,
      };

    case ME_LOADING_PLANS:
      return { ...state, isLoading: true, message: "" };

    case ME_LOADED_PLANS:
      return {
        ...state,
        plans: action.payload,
        selectedPlanData: null,
        isLoading: false,
        message: "",
      };

    case ME_LOADING_PLANS_FAILED:
      return { ...state, message: action.payload, isLoading: false };

    case SELECT_PLAN:
      return { ...state, selectedPlan: action.payload, message: "" };

    case OPEN_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
        message: "",
      };

    case CLOSE_PLAN:
      return { ...state, selectedPlanData: null };

    case SET_SITES_DISPLAY_STYLE:
      return { ...state, sitesDisplayStyle: action.payload };

    case ME_LOADED_FACTOR_TEMPLATES:
      return { ...state, factorTemplates: action.payload };

    case EXPORTING_PLAN:
      return { ...state, isExporting: true, planExporting: action.payload };

    case EXPORTING_PLAN_FINISHED:
      return { ...state, isExporting: false, planExporting: null };

    case EXPORT_VALIDATION_ERRORS:
      return { ...state, isExporting: false };

    case SET_SITE_USERS:
      return { ...state, siteUsers: action.payload };

    default:
      return state;
  }
};

export default meReducer;

import React from "react";
import { Button, Modal } from "semantic-ui-react";

type ModalProps = {
  open: boolean;
  onClose: () => void;
  deleteAction: () => Promise<void>;
  modalContent?: React.ReactNode;
  modalTitle?: string;
};

export function DeleteModal(props: ModalProps) {
  return (
    <Modal
      open={props.open}
      onClose={props.onClose}
      size="tiny"
      closeOnDimmerClick={false}
    >
      <Modal.Header content={props.modalTitle || "Delete"} />
      <Modal.Content content={props.modalContent || "Are you sure?"} />
      <Modal.Actions>
        <Button content="Cancel" onClick={() => props.onClose()} secondary />
        <Button
          content="Delete"
          icon="trash alternate outline"
          negative
          onClick={async () => {
            await props.deleteAction();
            props.onClose();
          }}
        />
      </Modal.Actions>
    </Modal>
  );
}

import React, { useState, useEffect } from "react";
import { User, UserClaim, UserGetDto } from "../../models";
import { Button, Popup, Table } from "semantic-ui-react";
import { Claims, UserHasClaim } from "../../util/security/security";
import { toast } from "react-toastify";
import ResponseMessage from "../components/ResponseMessage";
import AppContext from "../../app/AppContext";

interface Props {
  employee: UserGetDto;
  admin: User;
}

const baseUrl = "/users/";

export function UserDetailActions(props: Props) {
  const { api } = React.useContext(AppContext);

  const _addUser = (
    url: string,
    userDto: UserGetDto,
    successCallback: () => void
  ) => {
    api
      .post(baseUrl + url, {
        name: userDto.name,
        email: userDto.emailAddress,
      })
      .then(resp => {
        if (resp.hasErrors) {
          toast.error(<ResponseMessage response={resp} />);
        } else {
          successCallback();
        }
      })
      .catch();
  };

  const _deleteUser = (
    url: string,
    userDto: UserGetDto,
    successCallback: () => void
  ) => {
    api.post(baseUrl + url, { id: userDto.id }).then(x => {
      if (x.hasErrors) {
        toast.error(<ResponseMessage response={x} />);
      } else {
        successCallback();
      }
    });
  };

  const _processUser = (
    url: string,
    userDto: UserGetDto,
    stateManager: typeof setUserState
  ) => {
    if (url.includes("remove"))
      return _deleteUser(url, userDto, () =>
        stateManager({ wasRemoved: true, wasAdded: false })
      );
    return _addUser(url, userDto, () =>
      stateManager({ wasAdded: true, wasRemoved: false })
    );
  };

  const _buildUrl = (suffixUrl: string, claim: UserClaim) => {
    let hasClaim = UserHasClaim(props.employee, claim);
    return hasClaim ? `remove${suffixUrl}` : `add${suffixUrl}`;
  };

  const _hasModifyAccess = () => {
    return (
      UserHasClaim(props.admin, Claims.AdminClaim) ||
      (UserHasClaim(props.admin, Claims.CorporateAdminClaim) &&
        !UserHasClaim(props.employee, Claims.CorporateAdminClaim) &&
        !UserHasClaim(props.employee, Claims.AdminClaim))
    );
  };

  const _resetStates = () => {
    setUserState({ wasAdded: false, wasRemoved: false });
    setAdminState({ wasAdded: false, wasRemoved: false });
    setSuperAdminState({ wasAdded: false, wasRemoved: false });
  };

  useEffect(() => {
    _resetStates();
  }, [props.employee]);

  const [userState, setUserState] = useState({
    wasAdded: false,
    wasRemoved: false,
  });

  const [adminState, setAdminState] = useState({
    wasAdded: false,
    wasRemoved: false,
  });

  const [superAdminState, setSuperAdminState] = useState({
    wasAdded: false,
    wasRemoved: false,
  });

  const userButtonDisabled =
    userState.wasAdded || userState.wasRemoved || !_hasModifyAccess();

  const corpAdminButtonDisabled =
    adminState.wasAdded || adminState.wasRemoved || !_hasModifyAccess();

  const superAdminButtonDisabled =
    superAdminState.wasAdded ||
    superAdminState.wasRemoved ||
    !_hasModifyAccess();

  const getActionText = (claim, claimText, { wasAdded, wasRemoved }) =>
    UserHasClaim(props.employee, claim)
      ? `${wasRemoved ? "" : "Remove "}${claimText}${
          wasRemoved ? " was removed!" : ""
        }`
      : `${wasAdded ? "" : "Add "}${claimText}${wasAdded ? " was added!" : ""}`;

  return (
    <Table.Cell>
      <Popup
        content="You are not authorized to edit admin users."
        disabled={
          !(
            userButtonDisabled &&
            UserHasClaim(props.employee, Claims.CorporateAdminClaim)
          )
        }
        trigger={
          <div>
            {UserHasClaim(props.admin, Claims.CorporateAdminClaim) ? (
              <>
                <Button
                  disabled={userButtonDisabled}
                  content={getActionText(Claims.UserClaim, "User", userState)}
                  icon={
                    UserHasClaim(props.employee, Claims.UserClaim)
                      ? "remove"
                      : "add"
                  }
                  negative={UserHasClaim(props.employee, Claims.UserClaim)}
                  onClick={() =>
                    _processUser(
                      _buildUrl("", Claims.UserClaim),
                      props.employee,
                      setUserState
                    )
                  }
                  primary={!UserHasClaim(props.employee, Claims.UserClaim)}
                  size="tiny"
                />

                {UserHasClaim(props.admin, Claims.AdminClaim) ? (
                  <>
                    <Button
                      secondary={
                        !UserHasClaim(
                          props.employee,
                          Claims.CorporateAdminClaim
                        )
                      }
                      negative={UserHasClaim(
                        props.employee,
                        Claims.CorporateAdminClaim
                      )}
                      content={getActionText(
                        Claims.CorporateAdminClaim,
                        "Corporate Admin",
                        adminState
                      )}
                      icon={
                        UserHasClaim(props.employee, Claims.CorporateAdminClaim)
                          ? "remove"
                          : "add"
                      }
                      size="tiny"
                      disabled={corpAdminButtonDisabled}
                      onClick={() =>
                        _processUser(
                          _buildUrl("-admin", Claims.CorporateAdminClaim),
                          props.employee,
                          setAdminState
                        )
                      }
                    />

                    <Button
                      secondary={
                        !UserHasClaim(props.employee, Claims.AdminClaim)
                      }
                      negative={UserHasClaim(props.employee, Claims.AdminClaim)}
                      content={getActionText(
                        Claims.AdminClaim,
                        "Super Admin",
                        superAdminState
                      )}
                      icon={
                        UserHasClaim(props.employee, Claims.AdminClaim)
                          ? "remove"
                          : "add"
                      }
                      size="tiny"
                      disabled={superAdminButtonDisabled}
                      onClick={() =>
                        _processUser(
                          _buildUrl("-super-admin", Claims.AdminClaim),
                          props.employee,
                          setSuperAdminState
                        )
                      }
                    />
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        }
      />
    </Table.Cell>
  );
}

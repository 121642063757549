import React, { useState } from "react";
import { StickyTable, Row, Cell } from "react-sticky-table";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Dropdown,
  DropdownProps,
  Icon,
  Input,
  Modal,
} from "semantic-ui-react";
import AppContext from "../../../app/AppContext";
import { hardFactorList } from "../../../constants";
import { HardFactorDto, PlanLineItemState } from "../../../models";
import {
  addHardFactor,
  AddHardFactorDispatch,
  deleteHardFactor,
  DeleteHardFactorDispatch,
  resetHardFactors,
  ResetHardFactorsDispatch,
  saveHardFactors,
  SaveHardFactorsDispatch,
  updateHardFactor,
  UpdateHardFactorDispatch,
} from "./actions";
import { addHardFactorCalculations } from "./helpers";
import { hardFactorColumns, HardFactorColumn } from "./hardFactorsColumns";
import { generateUUID } from "../../../util";

type DispatchProps = {
  addHardFactor: AddHardFactorDispatch;
  deleteHardFactor: DeleteHardFactorDispatch;
  saveHardFactors: SaveHardFactorsDispatch;
  resetHardFactors: ResetHardFactorsDispatch;
  updateHardFactor: UpdateHardFactorDispatch;
};

type Props = DispatchProps & {
  open: boolean;
  onClose: () => void;
  hardFactors: HardFactorDto[];
  planId: number;
  planData: ReadonlyArray<Readonly<PlanLineItemState>>;
  isSipaSite: boolean;
};

function HardFactorsModal(props: Props) {
  const { api } = React.useContext(AppContext);
  const [reason, setReason] = useState(null as string);
  const [columns, setColumns] = useState(hardFactorColumns);

  const _togglePinnedColumn = (col: HardFactorColumn) => {
    const cols = columns.slice();
    const index = cols.findIndex(x => x === col);
    let column = cols.find(x => x === col);
    column.pinned = !col.pinned;
    cols.splice(index, 1, column);
    setColumns(cols);
  };

  const _handleCancel = () => {
    props.resetHardFactors();
    props.onClose();
  };

  const _handleSubmit = () => {
    props.saveHardFactors(props.hardFactors, props.planId, api).then(x => {
      if (x.hasErrors) {
        toast(x.errors.map(x => x.errorMessage).join(" "));
      } else {
        props.onClose();
      }
    });
  };

  const _addFactor = () => {
    const hardFactor = hardFactorList.find(x => x.description === reason);

    if (hardFactor) {
      props.addHardFactor({
        id: 0,
        clientId: generateUUID(),
        planId: props.planId,
        description: hardFactor.description,
        factor: hardFactor.factor,
        isDeleted: false,
        rows: 0,
        hoursBeforeBump: 0.0,
        hoursAfterBump: 0.0,
        category: hardFactor.category,
      });
    }
  };

  const _handleSelection = (e: any, data: DropdownProps) => {
    let selection = hardFactorList.find(x => x.description === data.value);
    setReason(selection.description);
  };

  const hardFactorDropdownOptions = props.isSipaSite
    ? hardFactorList
        .filter(x => x.category === "SIPA")
        .map(x => {
          return {
            key: x.description,
            value: x.description,
            text: x.description,
          };
        })
    : hardFactorList
        .filter(x => x.category !== "SIPA")
        .map(x => {
          return {
            key: x.description,
            value: x.description,
            text: x.description,
          };
        });

  if (props.hardFactors && props.hardFactors.length > 0) {
    addHardFactorCalculations(props.hardFactors, props.planData);
  }

  const HeaderCells = columns
    .filter(x =>
      props.isSipaSite
        ? x
        : x.accessor !== "miscellaneousField1" &&
          x.accessor !== "miscellaneousField2" &&
          x.accessor !== "miscellaneousField3"
    )
    ?.sort((x, y) =>
      !((x.pinned || y.pinned) && !(x.pinned && y.pinned))
        ? x.order - y.order
        : x.pinned
        ? -1
        : 1
    )
    .map(x => (
      <Cell colSpan="1" key={x.order}>
        <div style={{ paddingRight: 30 }}>
          {x.Header}
          <Button
            icon
            onClick={() => _togglePinnedColumn(x)}
            size="mini"
            style={{ position: "absolute", right: 0, top: 0 }}
          >
            <Icon name="pin" rotated={x.pinned ? "clockwise" : undefined} />
          </Button>
        </div>
      </Cell>
    ));

  const TableRows = props.hardFactors
    ?.filter(x => !x.isDeleted)
    ?.map(x => (
      <Row key={x.clientId}>
        {columns
          .filter(x =>
            props.isSipaSite
              ? x
              : x.accessor !== "miscellaneousField1" &&
                x.accessor !== "miscellaneousField2" &&
                x.accessor !== "miscellaneousField3"
          )
          ?.sort((x, y) =>
            !((x.pinned || y.pinned) && !(x.pinned && y.pinned))
              ? x.order - y.order
              : x.pinned
              ? -1
              : 1
          )
          .map(y => {
            if (y.Header === "Difference") {
              return (
                <Cell key="diff">
                  {Number((x.hoursAfterBump - x.hoursBeforeBump).toFixed(2))}
                </Cell>
              );
            } else if (
              y.accessor === "miscellaneousField1" ||
              y.accessor === "miscellaneousField2" ||
              y.accessor === "miscellaneousField3"
            ) {
              const list = y.Header.toLowerCase();

              return (
                <Cell key={y.order}>
                  <Input
                    value={x[y.accessor] || ""}
                    list={list}
                    onChange={(e, { value }) => {
                      props.updateHardFactor(x, y.accessor, value);
                    }}
                    onBlur={evt => {
                      props.updateHardFactor(x, y.accessor, evt.target.value);
                    }}
                  />
                </Cell>
              );
            } else if (y.readonly) {
              return <Cell key={y.order}>{x[y.accessor]}</Cell>;
            } else {
              return (
                <Cell key={y.order}>
                  <Input
                    value={x[y.accessor] || ""}
                    onChange={(e, { value }) => {
                      props.updateHardFactor(x, y.accessor, value);
                    }}
                    onBlur={evt => {
                      props.updateHardFactor(x, y.accessor, evt.target.value);
                    }}
                  />
                </Cell>
              );
            }
          })}
        <Cell collapsing>
          <Button
            icon="trash alternate outline"
            title="Delete"
            negative
            onClick={() => props.deleteHardFactor(x)}
          />
        </Cell>
      </Row>
    ));

  return (
    <Modal
      onClose={props.onClose}
      size="fullscreen"
      open={props.open}
      closeOnDimmerClick={false}
    >
      <Modal.Header content="Hard Factors" />
      <Modal.Content>
        <Dropdown
          onChange={_handleSelection}
          options={hardFactorDropdownOptions}
          placeholder="Select Reason"
          search
          selection
          value={reason}
        />
        <Button primary icon="add" onClick={_addFactor} content="Add Factor" />
        <div style={{ overflowX: "auto" }}>
          <StickyTable
            leftStickyColumnCount={columns.filter(x => x.pinned).length}
          >
            <Row>{HeaderCells}</Row>
            {TableRows}
          </StickyTable>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Cancel" onClick={_handleCancel} secondary />
        <Button content="Save" icon="save" onClick={_handleSubmit} primary />
      </Modal.Actions>
    </Modal>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    addHardFactor: addHardFactor(dispatch),
    deleteHardFactor: deleteHardFactor(dispatch),
    saveHardFactors: saveHardFactors(dispatch),
    resetHardFactors: resetHardFactors(dispatch),
    updateHardFactor: updateHardFactor(dispatch),
  };
};

export default connect(null, mapDispatchToProps)(HardFactorsModal);

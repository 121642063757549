import React, { useEffect, CSSProperties } from "react";
import styled from "@emotion/styled/macro";
import {
  PlanLineItemDto,
  PlanLineItemState,
  CalculatedFields,
  FactorTemplateDataDto,
  AbbreviationGetDto,
  SelectedRowDescriptor,
} from "../../../models";
import { Icon, Table, Menu, Button, Ref, Popup } from "semantic-ui-react";
import "./index.css";
import {
  useTable,
  useResizeColumns,
  useFilters,
  useExpanded,
  Row,
  Column,
  actions,
} from "./react-table";
import { useContextMenu } from "./hooks/useContextMenu";
import { useDrag, useDrop } from "react-dnd";
import { DefaultValueModal } from "./modals/DefaultValueModal";
import { useDefaultValue } from "./hooks/useDefaultValue";
import { connect } from "react-redux";
import { usePreviousValue } from "./hooks/usePreviousValue";
import _ from "lodash";
import {
  togglePinnedColumn,
  TogglePinnedColumnDispatch,
  UpdateColumnOrderDispatch,
  setColumnFilterType,
  SetColumnFilterTypeDispatch,
  updateColumnOrder,
  MoveRowsDispatch,
  RemoveRowDispatch,
  SelectRowDispatch,
  selectRow,
  promoteHeader,
  demoteHeader,
  PromoteHeaderDispatch,
  DemoteHeaderDispatch,
  moveRows,
  copyDownValue,
  CopyDownValueDispatch,
  setDefaultValueColumns,
  SetDefaultValueColumnsDispatch,
  setPreviousValueColumns,
  SetPreviousValueColumnsDispatch,
  AddHeaderDispatch,
  AddBlankBelowDispatch,
  updateColumnWidth,
  UpdateColumnWidthDispatch,
} from "../../me/plans/actions";
import { DefaultColumnFilter, filterTypes } from "./filters";
import { FilterOperations } from "./types";
import { mapColumns, actionColumn } from "./columns";
import { useCellSelect } from "./hooks/useCellSelect";
import { generateNewLine, nextSequence } from "../../me/plans/helpers";
import * as className from "classnames";
import ReactTooltip from "react-tooltip";
import { useRowSelection } from "./hooks/useRowSelection";
import { useDragScroll } from "./hooks/useDragScroll";
import { useRowDescriptor } from "./hooks/useRowDescriptor";
import { makeKeyListener, kbd } from "../../../util/keyboard";
import { Api } from "../../../util/api/api";
import { DefaultValue, PreviousValue } from "../../../models/grid";

const MIN_COLUMN_WIDTH = 60;

export interface ColumnInfo {
  title: string;
  accessor: keyof PlanLineItemDto | keyof CalculatedFields;
  pinned: boolean;
  order: number;
  hidden: boolean;
  canFilter: boolean;
  readonly: (
    isHeader: boolean,
    miscFieldColumn: string,
    actNoIsAutoCalculated: boolean
  ) => boolean;
  format?: (any) => any;
  filterValue?: string;
  filterType?: FilterOperations;
  alias?: string;
  width: number;
  type?: string;
  canDefault:
    | boolean
    | ((isHeader: boolean, actNoIsAutoCalculated?: boolean) => boolean);
}

export function canDefault(
  col: ColumnInfo,
  isHeader?: boolean,
  actNoIsAutoCalculated?: boolean
): boolean {
  return _.isBoolean(col.canDefault)
    ? col.canDefault
    : arguments.length === 1
    ? true
    : _.isFunction(col.canDefault) &&
      (col as any).canDefault(
        isHeader,
        actNoIsAutoCalculated
      ); /* TODO: figure out why col.canDefault(...) is failing on build server*/
}

type OwnProps = Readonly<{
  columns: ReadonlyArray<Readonly<ColumnInfo>>;
  dataSource: ReadonlyArray<Readonly<PlanLineItemState>>;
  factors: Readonly<FactorTemplateDataDto>;
  planId: number;
  jobNumber: string;
  system: string;
  unit: string;
  miscFieldColumn: string;
  miscFieldValue: string;
  updateRowData: (
    headerIndex: number,
    subrowIndex: number,
    updates: { [P in keyof PlanLineItemState]?: any }
  ) => void;
  addHeader: AddHeaderDispatch;
  addBlankBelow: AddBlankBelowDispatch;
  removeRow: RemoveRowDispatch; // ({ index, subrowIndex }) => void;
  selectedRows: ReadonlyArray<Readonly<SelectedRowDescriptor>>;
  abbreviations: ReadonlyArray<Readonly<AbbreviationGetDto>>;
  planData: Readonly<PlanLineItemState[]>;
  api: Api;
  columnDefaultValues: DefaultValue[];
  columnPreviousValues: PreviousValue[];
}>;

export interface ColumnInfoDto {
  columnWidth: number;
  isPinned: boolean;
  isHidden: boolean;
  order: number;
  columnAccessor: keyof PlanLineItemDto | keyof CalculatedFields;
}

interface DispatchProps {
  setColumnOrder: UpdateColumnOrderDispatch;
  togglePinnedColumn: TogglePinnedColumnDispatch;
  setColumnFilterType: SetColumnFilterTypeDispatch;
  moveRows: MoveRowsDispatch;
  selectRow: SelectRowDispatch;
  promoteHeader: PromoteHeaderDispatch;
  demoteHeader: DemoteHeaderDispatch;
  copyDownValue: CopyDownValueDispatch;
  setDefaultValueColumns: SetDefaultValueColumnsDispatch;
  setPreviousValueColumns: SetPreviousValueColumnsDispatch;
  updateColumnWidth: UpdateColumnWidthDispatch;
}

type Props = OwnProps & DispatchProps;

type SelectedRow = {
  selected: boolean;
};

const ColumnButtonBar = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const Resizer = styled.div<ResizingProps>`
  display: inline-block;
  background: ${props => (props.isResizing ? "red" : "gray")};
  width: 2px;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  transform: translateX(50%);
  z-index: 1;
  /* prevents from scrolling while dragging on touch devices */
  touch-action: none;

  .isResizing {
    background: red;
  }
`;

const StyledRoot = styled.div`
  &.grid-container {
    overflow-x: auto;
    flex: 1;
  }

  #the-grid {
    td {
      padding: 0.7em 0.78em;
    }

    th {
      padding: 8px 0.78em 0.4em;

      .header-details {
        height: 52px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        space: nowrap;
        overflow-x: hidden;
      }
    }

    .active {
      background: green;
    }
  }
`;

type CellProps = {
  pos: number;
  left: number;
  width: number;
  dragOver: boolean;
  pinned: boolean;
};

const DRAG_SCROLL_OFFSET_TOP = 100;

type HeaderProps = {
  columns: ColumnInfo[];
  onDragStart: React.EventHandler<any>;
  onDragOver: React.EventHandler<any>;
  onDragEnter: React.EventHandler<any>;
  onDrop: React.EventHandler<any>;
  setColumns: (colinfo: ColumnInfo[]) => void;
  dragOver: string;
};

type ResizingProps = {
  isResizing: boolean;
};

const indexParser = (index: string): [number, number] => {
  const split = index.split(".");
  return [Number(split[0]), _.isUndefined(split[1]) ? null : Number(split[1])];
};

const styles = {
  color: {
    white: { color: "#fff" },
  },
  columnButtonBar: {
    button: { float: "right", background: "#00563f" } as React.CSSProperties,
  },
  menuItem: {
    color: "#21ba45",
    borderBottom: "1px solid green",
  },
  table: {
    header: { top: "1px" },
    outer: {
      overflowX: "auto",
      position: "relative",
      flex: "1",
      flexDirection: "column",
    } as React.CSSProperties,
  },
  icon: {
    white: { color: "#fff", margin: "0px" },
    pinned: { transform: "rotate(45deg)", color: "#fff", margin: "0px" },
  },
};

const getSubRows = (row: any) => row.subRows;

type ContextMenuProps = {
  x: number;
  y: number;
  children?: React.ReactNode;
};

function ContextMenu(props: ContextMenuProps) {
  let { x, y } = props;

  const menuRef = React.useRef<HTMLElement>(null);
  const pointerRef = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const clientRect = menuRef.current.getBoundingClientRect();

    const yOffset =
      clientRect.bottom >= window.innerHeight
        ? window.innerHeight - clientRect.bottom - 30
        : -30;

    const xOffset =
      clientRect.right >= window.innerWidth
        ? window.innerWidth - clientRect.right
        : 0;

    menuRef.current.style.left = `${x + xOffset}px`;
    menuRef.current.style.top = `${y + yOffset}px`;
    pointerRef.current.style.left = `${x + xOffset}px`;
  });

  const menuStyle = React.useMemo<CSSProperties>(
    () => ({
      position: "absolute",
      left: x,
      top: y - 30,
      zIndex: 120,
      background: "#3a3a3a",
      borderRadius: "5%",
      boxShadow: "3px 3px 8px 1px rgba(0,0,0,0.62)",
      listStyleType: "none",
      whiteSpace: "nowrap",
    }),
    [x, y]
  );

  const pointerStyle = React.useMemo<CSSProperties>(
    () => ({
      position: "absolute",
      left: x,
      top: y,
      transform: "translateX(-50%) translateY(-50%) rotate(45deg)",
      margin: "0.5px 0 0 0",
      width: "10px",
      height: "10px",
      border: "none",
      backgroundColor: "rgb(58, 58, 58)",
      borderLeft: "1px solid #d4d4d5",
      borderBottom: "1px solid #d4d4d5",
      zIndex: 102,
    }),
    [x, y]
  );

  return (
    <>
      <Ref innerRef={menuRef}>
        <Menu vertical className="menu" style={menuStyle}>
          {props.children}
        </Menu>
      </Ref>
      <div ref={pointerRef} style={pointerStyle}></div>
    </>
  );
}
const TableCommands = {
  DeleteRow: "DeleteRow",
  AddRow: "AddRow",
  AddSubRow: "AddSubRow",
  PromoteHeader: "PromoteHeader",
  DemoteHeader: "DemoteHeader",
  CopyDownValue: "CopyDownValue",
};

type TableCommandArgs = {
  id: any;
  sequence: string;
  isHeader?: boolean;
  selectedRow?: PlanLineItemDto;
  column?: Column;
  value?: any;
  orderInExport?: number;
  previousValues?: [];
  defaultValues?: [];
};

function Grid(props: Props) {
  //STATES/PROPS
  const {
    columns: columnInfos,
    dataSource,
    selectedRows,
    moveRows,
    addHeader,
    addBlankBelow,
    removeRow,
    selectRow,
    setDefaultValueColumns,
    setPreviousValueColumns,
    promoteHeader,
    demoteHeader,
    copyDownValue,
    planData,
    columnDefaultValues,
    columnPreviousValues,
  } = props;

  //SERVICES
  const _moveRowsP = React.useCallback(
    (dragId: string, hoverId: string) => {
      let [dragIndex, dragSubrow] = dragId.split(".").map(Number);
      let [hoverIndex, hoverSubrow] = hoverId.split(".").map(Number);

      dragSubrow = _.isNil(dragSubrow) ? 0 : dragSubrow;
      hoverSubrow = _.isNil(hoverSubrow)
        ? 0
        : hoverId < dragId
        ? hoverSubrow + 1
        : hoverSubrow;

      moveRows(
        [
          {
            index: dragIndex,
            subrowIndex: dragSubrow,
          },
        ],
        {
          index: hoverIndex,
          subrowIndex: hoverSubrow,
        }
      );
    },
    [moveRows]
  );

  const _addNewRow = React.useCallback(
    (
      id: string,
      isHeader: boolean,
      defaultValues: [],
      previousValues: [],
      sequence?: string,
      selectedRow?: PlanLineItemDto
    ) => {
      const [parentIndex, subrowIndex] = !_.isUndefined(id)
        ? id.split(".")
        : [undefined, undefined];

      const nextSequenceResult = sequence ?? nextSequence();
      const newLineObject = generateNewLine(
        isHeader,
        nextSequenceResult,
        defaultValues,
        previousValues
      );

      newLineObject.isHeader
        ? addHeader({ row: newLineObject })
        : addBlankBelow({
            row: newLineObject,
            parentIndex: Number(parentIndex),
            subrowIndex: _.isNil(subrowIndex)
              ? _.lastIndexOf(
                  planData[parentIndex].subRows,
                  _.last(planData[parentIndex].subRows)
                )
              : Number(subrowIndex),
            selectedRow,
          });
    },
    [addHeader, addBlankBelow, planData]
  );

  const _promoteHeader = React.useCallback(
    (rowId: string) => {
      const [index, subrowIndex] = indexParser(rowId);
      promoteHeader({ index, subrowIndex });
    },
    [promoteHeader]
  );

  const _demoteHeader = React.useCallback(
    (index: string) => {
      demoteHeader({ index: Number(index) });
    },
    [demoteHeader]
  );

  const _removeRow = React.useCallback(
    (index: string) => {
      if (index.includes(".")) {
        const [parent, child] = indexParser(index);
        const foundRow = dataSource[Number(parent)];

        if (!foundRow) {
          throw new Error("Parent row not found");
        }

        removeRow({ index: Number(parent), subrowIndex: Number(child) });
      } else {
        removeRow({ index: Number(index), subrowIndex: undefined });
      }
    },
    [dataSource, removeRow]
  );

  const _copyDownValue = React.useCallback(
    (value: any, column: Column, orderInExport: number) => {
      copyDownValue(value, column.id, orderInExport);
    },
    [copyDownValue]
  );

  const _handleTableCommand = React.useCallback(
    (
      command: string,
      payload: {
        id: any;
        sequence: string;
        isHeader?: boolean;
        selectedRow?: PlanLineItemDto;
        column?: Column;
        value?: any;
        orderInExport?: number;
        previousValues?: [];
        defaultValues?: [];
      }
    ) =>
      ({
        DeleteRow: () => {
          const { id } = payload;
          _removeRow(id);
        },
        AddRow: () => {
          const { id, isHeader, defaultValues, previousValues } = payload;
          _addNewRow(id, isHeader, defaultValues, previousValues);
        },
        AddSubRow: () => {
          const {
            id,
            isHeader,
            sequence,
            selectedRow,
            defaultValues,
            previousValues,
          } = payload;
          _addNewRow(
            id,
            isHeader,
            defaultValues,
            previousValues,
            sequence,
            selectedRow
          );
        },
        PromoteHeader: () => {
          const { id } = payload;
          _promoteHeader(id);
        },
        DemoteHeader: () => {
          const { id } = payload;
          _demoteHeader(id);
        },
        CopyDownValue: () => {
          const { value, column, orderInExport } = payload;
          _copyDownValue(value, column, orderInExport);
        },
      }[command]),
    [_addNewRow, _removeRow, _demoteHeader, _promoteHeader, _copyDownValue]
  );

  const columns = React.useMemo(
    () => [actionColumn, ...mapColumns(columnInfos)],
    [columnInfos]
  );

  const instance = useTable(
    {
      columns,
      data: dataSource,
      filterTypes,
      autoResetExpanded: false,
      updateData: props.updateRowData,
      autoResetFilters: false,
      setColumnFilterType: props.setColumnFilterType,
      selectRow,
      useControlledState: state => {
        return React.useMemo(() => ({ ...state, selectedRows }), [
          state,
          selectedRows,
        ]);
      },
      stateReducer: (newState, action, prevState) => {
        switch (action.type) {
          case actions.columnDoneResizing:
            const updates = [];
            Object.keys(newState.columnResizing.columnWidths).forEach(key => {
              updates.push([
                key as any,
                newState.columnResizing.columnWidths[key],
              ]);

              delete newState.columnResizing.columnWidths[key];
            });
            updates.forEach(([key, width]) => {
              if (!_.isNaN(width) && _.isNumber(width)) {
                props.updateColumnWidth(key, Math.max(width, MIN_COLUMN_WIDTH));
              }
            });
        }

        return newState;
      },

      //HACK: May not be necessary in the future
      //See: https://github.com/tannerlinsley/react-table/issues/2064#issuecomment-623199018
      // also: https://github.com/tannerlinsley/react-table/issues/2309
      getSubRows,
      setDefaultValueColumns: setDefaultValueColumns,
      setPreviousValueColumns: setPreviousValueColumns,
      defaultValues: columnDefaultValues,
      previousValues: columnPreviousValues,
    },
    //useBlockLayout,
    useResizeColumns,
    useFilters,
    useExpanded,
    useRowSelection,
    useCellSelect,
    useContextMenu,
    useDefaultValue,
    usePreviousValue,
    useRowDescriptor
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: {
      contextMenuState: { pageX, pageY, isVisible, row, column },
      showDefaultValueModal,
      editingColumn,
      defaultValues,
      copyPreviousValue,
      selectedCell,
    },
    closeDefaultModal,
    moveToCell,
    toggleRowExpanded,
    selectRows,
  } = instance;

  const [setKey, removeKey, setMouse, removeMouse] = makeKeyListener(window);

  useEffect(() => {
    const prevHeaderRowId = (id: string) => {
      return (Number(id.split(".")[0]) - 1).toString();
    };

    setMouse(kbd("Shift-<click>"), () => {
      selectRows();
    });

    setKey(kbd("<up>"), () => {
      if (selectedCell && selectedCell.rowIndex > 0) {
        if (selectedCell.row.original.isHeader)
          toggleRowExpanded(prevHeaderRowId(selectedCell.row.id), true);

        moveToCell(selectedCell.rowIndex - 1, selectedCell.columnIndex);
      }
    });

    setKey(kbd("<down>"), () => {
      if (selectedCell) {
        if (selectedCell.row.original.isHeader)
          toggleRowExpanded(selectedCell.row.id, true);

        if (selectedCell.rowIndex === instance.filteredFlatRows.length - 1) {
          _addNewRow(
            selectedCell.row.id,
            false,
            defaultValues,
            copyPreviousValue,
            selectedCell.row.original.sequence
          );
        }
        moveToCell(selectedCell.rowIndex + 1, selectedCell.columnIndex);
      }
    });

    setKey(kbd("Shift-<down>"), () => {
      if (selectedCell) {
        _addNewRow(
          selectedCell.row.original.isHeader
            ? `${selectedCell.row.id}.-1`
            : selectedCell.row.id,
          false,
          defaultValues,
          copyPreviousValue,
          selectedCell.row.original.sequence,
          selectedCell.row.original
        );
        moveToCell(selectedCell.rowIndex + 1, selectedCell.columnIndex);
      }
    });

    setKey(kbd("Shift-<up>"), () => {
      if (selectedCell && selectedCell.rowIndex >= 1) {
        if (selectedCell.row.original.isHeader)
          toggleRowExpanded(prevHeaderRowId(selectedCell.row.id), true);

        const prevRow = instance.filteredFlatRows[selectedCell.rowIndex - 1];
        _addNewRow(
          prevRow.original.isHeader ? `${prevRow.id}.-1` : prevRow.id,
          false,
          defaultValues,
          copyPreviousValue,
          prevRow.original.sequence,
          prevRow.original
        );
        moveToCell(selectedCell.rowIndex, selectedCell.columnIndex);
      }
    });

    return () => {
      removeKey(kbd("<up>"));
      removeKey(kbd("<down>"));
      removeKey(kbd("Shift-<up>"));
      removeKey(kbd("Shift-<down>"));
      removeMouse(kbd("Shift-<click>"));
    };
  }, [_addNewRow, moveToCell, selectedCell, defaultValues, copyPreviousValue]);

  const buildMenuItems = React.useCallback(
    (row: Row, column: Column) => {
      const { id, canDelete, canDemote } = row;
      return (
        <>
          {!!row.original.isHeader ? (
            <>
              <Menu.Item style={styles.menuItem}>
                <Popup
                  content={"The first header may not be demoted"}
                  disabled={canDemote}
                  trigger={
                    <span>
                      <Button
                        icon="level down"
                        disabled={!canDemote}
                        onClick={_handleTableCommand(
                          TableCommands.DemoteHeader,
                          {
                            id: id,
                            sequence: row.original.sequence,
                          }
                        )}
                        content={"Demote to Task"}
                      />
                    </span>
                  }
                  basic
                />
              </Menu.Item>
            </>
          ) : (
            <>
              <Menu.Item style={styles.menuItem}>
                <Button
                  content="Promote to Header"
                  icon="level up"
                  onClick={_handleTableCommand(TableCommands.PromoteHeader, {
                    id: id,
                    sequence: row.original.sequence,
                  })}
                />
              </Menu.Item>
            </>
          )}

          <Menu.Item style={styles.menuItem}>
            <Button
              content="Add New Header"
              icon="add"
              primary
              onClick={_handleTableCommand(TableCommands.AddRow, {
                id: id,
                isHeader: true,
                sequence: row.original.sequence,
                defaultValues: defaultValues,
                previousValues: copyPreviousValue,
              })}
            />
          </Menu.Item>
          <Menu.Item style={styles.menuItem}>
            <Button
              content="Add New Task"
              icon="add"
              primary
              onClick={_handleTableCommand(TableCommands.AddSubRow, {
                id: id,
                isHeader: false,
                sequence: row.original.sequence,
                selectedRow: row.original.isHeader ? undefined : row.original,
                defaultValues: defaultValues,
                previousValues: copyPreviousValue,
              })}
            />
          </Menu.Item>
          <Menu.Item style={styles.menuItem}>
            <Popup
              trigger={
                <span>
                  <Button
                    negative
                    icon="trash alternate outline"
                    disabled={!canDelete}
                    content={`Delete ${
                      row.original.isHeader ? "Header" : "Task"
                    }`}
                    onClick={_handleTableCommand(TableCommands.DeleteRow, {
                      id: id,

                      sequence: row.original.sequence,
                    })}
                  />
                </span>
              }
              content={"The first header must not have tasks"}
              disabled={canDelete}
            />
          </Menu.Item>

          {!row.original.isHeader &&
          [
            "drawingNumber",
            "equipmentNumber",
            "miscellaneousField1",
            "miscellaneousField2",
            "miscellaneousField3",
            "miscellaneousField4",
            "miscellaneousField5",
            "miscellaneousField6",
          ].includes(column.id) ? (
            <>
              <Menu.Item style={styles.menuItem}>
                <Button
                  color="green"
                  onClick={_handleTableCommand(TableCommands.CopyDownValue, {
                    id: id,
                    sequence: row.original.sequence,
                    value: row.original[column.id],
                    column: column,
                    orderInExport: row.original.orderInExport,
                  })}
                  basic
                >
                  Copy Down Value
                </Button>
              </Menu.Item>
            </>
          ) : null}
        </>
      );
    },
    [_handleTableCommand, copyPreviousValue, defaultValues]
  );

  const scrollAreaEl = React.useRef(null);

  const { onBeginDrag, onEndDrag } = useDragScroll(scrollAreaEl.current);

  const totalWidth = headerGroups[0].headers.reduce(
    (accum, curr) => accum + curr.width,
    0
  );

  const tableStyle = React.useMemo(
    () => ({
      width: totalWidth,
    }),
    [totalWidth]
  );

  const cols = headerGroups[0].headers.map(column => (
    <col key={`col_${column.id}`} style={{ width: column.width }} />
  ));

  return (
    <StyledRoot className="grid-container" ref={scrollAreaEl}>
      {isVisible ? (
        <ContextMenu x={pageX} y={pageY}>
          {buildMenuItems(row, column)}
        </ContextMenu>
      ) : null}
      <div>
        <Table id="the-grid" style={tableStyle} striped {...getTableProps()}>
          <colgroup>{cols}</colgroup>
          <Table.Header style={styles.table.header}>
            <TableHeader
              headerGroups={headerGroups}
              setColumnOrder={props.setColumnOrder}
              togglePinnedColumn={props.togglePinnedColumn}
              onBeginDrag={onBeginDrag}
              onEndDrag={onEndDrag}
            />
          </Table.Header>
          <Table.Body {...getTableBodyProps()}>
            <TableBody
              rows={rows}
              moveRow={_moveRowsP}
              prepareRow={prepareRow}
              actNoIsAutoCalculated={props.factors.actNoIsAutoCalculated}
              miscFieldColumn={props.miscFieldColumn}
              onBeginDrag={onBeginDrag}
              onEndDrag={onEndDrag}
            />
          </Table.Body>
        </Table>
      </div>
      <DefaultValueModal
        open={showDefaultValueModal}
        onClose={closeDefaultModal}
        column={editingColumn}
        api={props.api}
        planId={props.planId}
      ></DefaultValueModal>
      <ReactTooltip id="control" />
    </StyledRoot>
  );
}

const TableBody = props => {
  const {
    rows,
    moveRow,
    prepareRow,
    actNoIsAutoCalculated,
    miscFieldColumn,
  } = props;

  return rows.map((row, index) => {
    prepareRow(row);
    return (
      <DndRow
        key={row.original.clientId}
        index={index}
        row={row}
        isSelected={row.isSelected}
        isExpanded={row.isExpanded}
        moveRow={moveRow}
        actNoIsAutoCalculated={actNoIsAutoCalculated}
        miscFieldColumn={miscFieldColumn}
        onBeginDrag={props.onBeginDrag}
        onEndDrag={props.onEndDrag}
        isActive={row.isActive}
      />
    );
  });
};

function DndColumnHeader(props) {
  const { column, index, setColumnOrder, togglePinnedColumn, canDrag } = props;
  const { pinned, left, width, dragOver } = column;

  const dragDropRef = React.useRef<HTMLElement>(null);

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: "Column", index, id: column.id, sortOrder: column.order },

    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),

    begin: monitor =>
      props.onBeginDrag({ offsetLeft: props.pinEdge, disableVertical: true }),

    end: (draggedItem, monitor) => {
      props.onEndDrag();
      const result = monitor.getDropResult();
      const dropSuccess = !!result;

      if (dropSuccess) {
        setColumnOrder({ accessor: column.id, order: result.order });
      }
    },

    canDrag: monitor => canDrag,
  });

  const [{ hovered }, drop] = useDrop({
    accept: "Column",
    collect: monitor => ({
      hovered: monitor.isOver(),
      highlighted: monitor.canDrop(),
    }),
    drop: (item, monitor) => {
      return column;
    },
  });

  preview(drop(dragDropRef));
  drag(dragDropRef);

  const { style, ...headerProps } = column.getHeaderProps();

  const cellStyle = React.useMemo(() => {
    return {
      borderLeft: dragOver && "5px solid red",
      top: "0px",
      left: pinned && left + "px",
      zIndex: pinned ? 101 : 100,
    } as React.CSSProperties;
  }, [dragOver, width, pinned, left]);

  return (
    <Ref innerRef={dragDropRef}>
      <Table.HeaderCell
        className={className({
          "item-hover": hovered,
          pinned: pinned,
          sticky: true,
        })}
        {...headerProps}
        style={cellStyle}
      >
        <div>
          {column.id === "expander" ? (
            <span>{column.render("Header")}</span>
          ) : (
            <>
              <ColumnButtonBar>
                <Button.Group size="mini" primary>
                  {canDefault(column) ? (
                    <Button
                      icon="setting"
                      className={
                        column.defaultValue || column.copyPrevValueEnabled
                          ? "active"
                          : ""
                      }
                      onClick={column.showDefaultModal}
                    />
                  ) : null}

                  <Button
                    icon
                    onClick={() =>
                      togglePinnedColumn(
                        column.id,
                        dragDropRef.current
                          ? dragDropRef.current.offsetWidth
                          : column.width
                      )
                    }
                  >
                    <Icon
                      name="pin"
                      rotated={pinned ? "clockwise" : undefined}
                    />
                  </Button>
                </Button.Group>
              </ColumnButtonBar>

              <Resizer
                isResizing={column.isResizing}
                {...column.getResizerProps()}
              />

              <div className="header-details">
                <span>{column.render("Header")}</span>

                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </div>
            </>
          )}
        </div>
      </Table.HeaderCell>
    </Ref>
  );
}

function TableHeader(props) {
  const { headerGroups, setColumnOrder, togglePinnedColumn } = props;

  return headerGroups.map((headerGroup: any) => {
    const pinEdge =
      headerGroup.headers.find(x => !x.pinned)?.left ||
      headerGroup.headers[0].width;

    const canDrag = !headerGroup.headers.some(col => col.isResizing);

    return (
      <Table.Row {...headerGroup.getHeaderGroupProps()}>
        {headerGroup.headers.map((column, index) => (
          <DndColumnHeader
            key={column.id}
            column={column}
            index={index}
            setColumnOrder={setColumnOrder}
            togglePinnedColumn={togglePinnedColumn}
            onBeginDrag={props.onBeginDrag}
            onEndDrag={props.onEndDrag}
            pinEdge={pinEdge}
            canDrag={canDrag}
          />
        ))}
      </Table.Row>
    );
  });
}

const getRowDragStyle = (isDragging: boolean): React.CSSProperties => ({
  opacity: isDragging ? 0 : 1,
});

const getCellDragStyle = (isOver: boolean): React.CSSProperties => ({
  borderBottom: isOver ? "2px solid red" : "",
});

type HoverItem = {
  type: string;
  index: number;
  id: string;
};

const Td = styled.td<any>`
  background-color: ${props => (props.error ? "#e74c3c" : "inherit")};
  border-left: ${props => props.dragOver && "5px solid red"};
  top: 0px;
  left: ${props => props.pinned && `${props.left}px`};
  z-index: ${props => props.pinned && 99};
`;

const DndRow = React.memo<any>(props => {
  const {
    row,
    index,
    moveRow,
    actNoIsAutoCalculated,
    miscFieldColumn,
    isSelected,
    isExpanded,
    isActive,
  } = props;

  const dropRef = React.useRef(null);
  const dragRef = React.useRef(null);
  const [{ isOver }, drop] = useDrop({
    accept: "Row",
    collect: monitor => ({
      isOver: monitor.isOver(),
    }),
    drop(item: HoverItem, monitor) {
      if (item.id !== row.id) {
        moveRow(item.id, row.id);
      }
    },
  });

  const [{ isDragging }, drag, preview] = useDrag({
    item: { type: "Row", id: row.id, key: row.original.clientId },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: monitor => {
      const result = !row.original.isHeader;
      return result;
    },
    begin: monitor =>
      props.onBeginDrag({
        offsetTop: DRAG_SCROLL_OFFSET_TOP,
        disableHorizontal: true,
      }),
    end: (item, monitor) => props.onEndDrag(),
  });

  preview(drop(dropRef));
  drag(dragRef);

  return (
    <Ref innerRef={dropRef}>
      <Table.Row
        {...row.getRowProps(row.getContextMenuRowProps())}
        active={isSelected || isSelected}
        className={className({
          "header-row": row.original.isHeader,
          "task-row": !row.original.isHeader,
        })}
        style={getRowDragStyle(isDragging)}
        key={undefined}
      >
        {row.cells.map((cell, cellIndex) => {
          const cellProps = cell.getCellProps();
          const { dragOver, width, pinned, left, id: columnId } = cell.column;
          const error =
            row.original.errors.hasOwnProperty(columnId) &&
            row.original.errors[columnId].type !== "warning";
          return cellIndex === 0 ? (
            <Td
              {...cellProps}
              ref={dragRef}
              key={`${row.original.clientId}_${cell.column.id}`}
              className={pinned ? "pinned" : ""}
              left={left}
              dragOver={dragOver}
              pinned={pinned}
              style={getCellDragStyle(isOver)}
              error={error}
            >
              {cell.render("Cell", {
                editable: false,
                errors: row.original.errors,
                isSelected: cell.isSelected,
              })}
            </Td>
          ) : (
            <Td
              {...cellProps}
              key={`${row.original.clientId}_${cell.column.id}`}
              className={cell.column.pinned ? "pinned" : ""}
              left={left}
              dragOver={dragOver}
              pinned={pinned}
              style={getCellDragStyle(isOver)}
              error={error}
            >
              {cell.render("Cell", {
                editable: !cell.column.readonly(
                  row.original.isHeader,
                  miscFieldColumn,
                  actNoIsAutoCalculated
                ),
                errors: row.original.errors,
                isSelected: cell.isSelected,
              })}
            </Td>
          );
        })}
      </Table.Row>
    </Ref>
  );
});

const mapDispatchToProps = (dispatch): DispatchProps => {
  return {
    setColumnOrder: updateColumnOrder(dispatch),
    togglePinnedColumn: togglePinnedColumn(dispatch),
    setColumnFilterType: setColumnFilterType(dispatch),
    moveRows: moveRows(dispatch),
    selectRow: selectRow(dispatch),
    setDefaultValueColumns: setDefaultValueColumns(dispatch),
    setPreviousValueColumns: setPreviousValueColumns(dispatch),
    promoteHeader: promoteHeader(dispatch),
    demoteHeader: demoteHeader(dispatch),
    copyDownValue: copyDownValue(dispatch),
    updateColumnWidth: updateColumnWidth(dispatch),
  };
};

export default connect(null, mapDispatchToProps)(Grid);

import { SiteDetailsGetDto } from "../../models";
import { SiteAdminActions, GET_SITE_DETAILS } from "./actions";

type InitialState = {
  siteDetails: SiteDetailsGetDto;
};

const initialState: InitialState = {
  siteDetails: null,
};

const siteAdminReducer = (
  state = initialState,
  action: SiteAdminActions
): InitialState => {
  switch (action.type) {
    case GET_SITE_DETAILS:
      return {
        ...state,
        siteDetails: action.siteDetails,
      };
    default:
      return state;
  }
};

export default siteAdminReducer;
